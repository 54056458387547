import React  from "react";
import { getLocalState } from "../../utils/localStorage";
// @ts-ignore

import * as navigations from "../../utils/navigation";
import {Navigate, Outlet,} from "react-router-dom";

// @ts-ignore
export function PrivateRoute() {
    const currentUser = getLocalState("_currentUser");
    return currentUser ? <Outlet/> : <Navigate to={navigations.LOGIN} />
}
