import * as constants from "./constants";

export const  clientsListRequest =(payload)=> {
    return { type: constants.CLIENTS_LIST_REQUESTING,payload}
}

export const clientsListSuccess=(payload)=>{
    return { type:constants.CLIENTS_LIST_SUCCESS, payload }
}

export const clientsListFailed=(error)=>{
    return { type:constants.CLIENTS_LIST_FAILED, error }
}

export const  clientsAddRequest =(payload)=> {
    return { type: constants.CLIENTS_ADD_REQUESTING, payload}
}

export const clientsAddSuccess=(payload)=>{
    return { type:constants.CLIENTS_ADD_SUCCESS, payload }
}

export const clientsAddFailed=(error)=>{
    return { type:constants.CLIENTS_ADD_FAILED, error }
}


export const  clientsEditRequest =(payload)=> {
    return { type: constants.CLIENTS_EDIT_REQUESTING, payload}
}

export const clientsEditSuccess=(payload)=>{
    return { type:constants.CLIENTS_EDIT_SUCCESS, payload }
}

export const clientsEditFailed=(error)=>{
    return { type:constants.CLIENTS_EDIT_FAILED, error }
}

export const clientsSetData=(payload)=>{
    return { type:constants.CLIENTS_SET_DATA, payload }
}

//Client par pays

export const  clientsPaysListRequest =(payload)=> {
    return { type: constants.CLIENTS_PAYS_LIST_REQUESTING, payload}
}
export const clientsPaysListSuccess=(payload)=>{
    return { type:constants.CLIENTS_PAYS_LIST_SUCCESS, payload }
}
export const clientsPaysListFailed=(error)=>{
    return { type:constants.CLIENTS_PAYS_LIST_FAILED, error }
}