export const SARL = [
{
  name: "denomination",
  question: "A quel nom avez-vous pensé pour votre entreprise ?",
  description: "",
  type: "simple", //"mutiple",
  options: [
    // {value: "", price: ""}
  ],
},
{
  name: "nombre-associes",
  question: "Combien d’associés êtes-vous ?",
  description: "",
  type: "simple", //"mutiple",
  options: [
    // {value: "", price: ""}
  ],
},
{
  name: "banque",
  question: "Dans quelle banque le compte sera-t-il ouvert ?",
  description: "",
  type: "simple", //"mutiple",
  options: [
    // {value: "", price: ""}
  ],
},
{
    name: "localisation-siege",
    question: "Où se situera le siège de votre entreprise ?",
    description:
        "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      { value: "Je domicilie à Legafrik (351 313 Franc guinéen/mois)", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
{
    name: "gestion-domiciliation",
    question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
    description: "Choisissez une formule",
    type: "mutiple", //"mutiple",
    options: [
      {
        value: "06 MOIS à 1 682 856 Franc guinéen (280 476 Franc guinéen/MOIS)",
        price: 280_476,
      },
      {
        value: "12 MOIS à 3 365 712 Franc guinéen (280 476 Franc guinéen/MOIS)",
        price: 280_476,
      },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
{
  name: "capital-social",
  question: "Quel est le montant de votre capital social ?",
  description:
    "SARL Capital minimun 1 405 255 Franc guinéen et pas de capital minimum pour la SAS Guinée",
  type: "mutiple", //"mutiple",
  options: [
    { value: "1 405 255 Franc guinéen", price: "" },
    { value: "14 052 553 Franc guinéen", price: "" },
    { value: "1 405 255 365 Franc guinéen", price: "" },
    { value: "Autres", price: "" },
  ],
}
];
