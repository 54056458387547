import * as constants from "./constants";

export const initialState = {
    status_demands_loading: false,
    status_demands_list_loading: false,
    status_demands_update_loading: false,
    data: [],
    status_demands:null,
    error_lists:null
}

const statusDemandsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.STATUS_DEMANDS_LIST_REQUESTING:
            return {
                ...state,
                status_demands_list_loading: true,
            };

        case constants.STATUS_DEMANDS_LIST_SUCCESS:

            return {
                ...state,
                status_demands_list_loading: false,
                data: action.payload
            };

        case constants.STATUS_DEMANDS_LIST_FAILED:

            return {
                ...state,
                status_demands_list_loading: false,
                error_lists:action.error
            };

        default:
            return state;
    }
}
export default statusDemandsReducer
