export default function authHeader() {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        return {
            'authorization': token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
            "Accept": "application/json",
        };
    } else {
        return {
            "Content-Type": "application/json",
            "Accept": "application/json",
        };
    }
}

export  function authHeaderOffline() {
    const dataUser = JSON.parse(window.localStorage.getItem("dataUser"));
    const token =dataUser?.token;
    if (token) {
        return {
            authorization: token ? `Bearer ${token}` : "",
            "Content-Type": "application/json",
            "Accept": "application/json",
        };
    } else {
        return {};
    }
}


export   function authHeaderPDF() {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        return {
            authorization: token ? `Bearer ${token}` : "",
            'Content-Type': 'application/pdf',
            "Accept": "application/pdf",
        };
    } else {
        return {};
    }
}