export const SASU = [
  {
    name: "denomination",
    question: "A quel nom avez-vous pensé pour votre entreprise ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "banque",
    question: "Dans quelle banque le compte sera-t-il ouvert ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "localisation-siege",
    question: "Où se situera le siège de votre entreprise ?",
    description:
        "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "A mon domicile", price: "" },
      { value: "Je domicilie à Legafrik (25 000 FCFA/mois)", price: "" },
      { value: "Je loue un local commercial", price: "" },
      { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
    name: "gestion-domiciliation",
    question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
    description: "Choisissez une formule",
    type: "mutiple", //"mutiple",
    options: [
      { value: "6 mois à 120 000 FCFA (20 000F/MOIS)", price: 120_000 },
      { value: "12 mois à 240 000 FCFA (20 000F/MOIS)", price: 240_000 },
      { value: "Je ne suis pas intéressé", price: "" },
    ],
  },
  {
    name: "capital-social",
    question: "Quel est le montant de votre capital social ?",
    description:
        "SARL Capital minimun 100.000 FCFA et pas de capital minimum pour la SAS au Togo",
    type: "mutiple", //"mutiple",
    options: [
      { value: "100 000 FCFA", price: "" },
      { value: "1 000 000 FCFA", price: "" },
      { value: "100 000 000 FCFA", price: "" },
      { value: "Autres", price: "" },
    ],
  }
];