import * as Actions from "./constants";

export const initialState = {
    data: null,
    isOpenStripePayment:false,
    isOpenConfirmedAdminAdd:false,
    isOpenConfirmedCreatePayment:false,
    isOpenDetailPayment:false,
    isOpenConfirmedAdminEdit:false,
    isOpenAdminEdit:false,
    isOpenConfirmedArticleAdd:false,
    isOpenCategoriesAdd:false,
    isNavbarOpen:true,
}

const modalReducer = (state = initialState, action) => {
    switch (action.type) {

        case  Actions.SHOW_STRIPE_PAYMENT_MODAL:
            return {
                ...state,
                isOpenStripePayment:true,
                data:action.payload,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:false,
                isOpenConfirmedAdminEdit:false,
                isOpenConfirmedCreatePayment:false,
                isOpenDetailPayment:false,
                isOpenAdminEdit:false,
                isOpenConfirmedArticleAdd:false,
                isOpenCategoriesAdd:false,
            };

        case  Actions.SHOW_CONFIRMED_ADMINS_ADD_MODAL:
            return {
                ...state,
                isOpenStripePayment:false,
                data:action.payload,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:true,
                isOpenConfirmedAdminEdit:false,
                isOpenConfirmedCreatePayment:false,
                isOpenDetailPayment:false,
                isOpenAdminEdit:false,
                isOpenConfirmedArticleAdd:false,
                isOpenCategoriesAdd:false,
            };

        case  Actions.SHOW_CONFIRMED_ADMINS_EDIT_MODAL:
            return {
                ...state,
                isOpenStripePayment:false,
                data:action.payload,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:false,
                isOpenConfirmedAdminEdit:true,
                isOpenConfirmedCreatePayment:false,
                isOpenDetailPayment:false,
                isOpenAdminEdit:false,
                isOpenConfirmedArticleAdd:false,
                isOpenCategoriesAdd:false,
            };

        case  Actions.SHOW_ADMINS_DETAIL_MODAL:
            return {
                ...state,
                isOpenStripePayment:false,
                data:action.payload,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:false,
                isOpenConfirmedAdminEdit:false,
                isOpenConfirmedCreatePayment:false,
                isOpenDetailPayment:false,
                isOpenAdminEdit:true,
                isOpenConfirmedArticleAdd:false,
                isOpenCategoriesAdd:false,
            };

        case  Actions.SHOW_CONFIRMED_CREATE_PAYMENT_MODAL:
            return {
                ...state,
                isOpenStripePayment:false,
                data:action.payload,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:false,
                isOpenConfirmedAdminEdit:false,
                isOpenConfirmedCreatePayment:true,
                isOpenDetailPayment:false,
                isOpenAdminEdit:false,
                isOpenConfirmedArticleAdd:false,
                isOpenCategoriesAdd:false,
            };

        case  Actions.SHOW_DETAIL_PAYMENT_MODAL:
            return {
                ...state,
                isOpenStripePayment:false,
                data:action.payload,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:false,
                isOpenConfirmedAdminEdit:false,
                isOpenConfirmedCreatePayment:false,
                isOpenDetailPayment:true,
                isOpenAdminEdit:false,
                isOpenConfirmedArticleAdd:false,
                isOpenCategoriesAdd:false,
            };

        case  Actions.SHOW_CONFIRMED_ARTICLES_ADD_MODAL:
            return {
                ...state,
                isOpenStripePayment:false,
                data:action.payload,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:false,
                isOpenConfirmedAdminEdit:false,
                isOpenConfirmedCreatePayment:false,
                isOpenDetailPayment:false,
                isOpenAdminEdit:false,
                isOpenConfirmedArticleAdd:true,
                isOpenCategoriesAdd:false,
            };

        case  Actions.SHOW_CATEGORIES_ADD_MODAL:
            return {
                ...state,
                isOpenStripePayment:false,
                isNavbarOpen:false,
                isOpenConfirmedAdminAdd:false,
                isOpenConfirmedAdminEdit:false,
                isOpenConfirmedCreatePayment:false,
                isOpenDetailPayment:false,
                isOpenAdminEdit:false,
                isOpenConfirmedArticleAdd:false,
                isOpenCategoriesAdd:true,
            };

        case Actions.HIDE_MODAL:
            return initialState;

        default:
            return state;
    }
};

export default modalReducer;
