import { useState } from "react";
import { Components } from "../..";
import { Utils } from "../../../utils";

export function RecapStepContrat(props) {
    const {isJsonParseable, parseJson} = Utils.String;
    const {getCurrencyFromOffre, getAssocieName} = Utils.Demande;
   const {editionLoading}=props
    const [modalTitle, setModalTitle] = useState("");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [valueName, setValueName] = useState('');
    const [valueArrayIndex, setValueArrayIndex] = useState('');

    const contratObject = {...parseJson(props.questionnaireObject?.contrat)};

    const handleEditClick = (e, name, index='') => {
        e.preventDefault();
        setIsEditModalOpen(true);
        setValueName(name);
        setValueArrayIndex(index);
    }
    const handleStepSubmit = (e) => {
        e.preventDefault();
        props.handleSubmit(e, "", "");
      };

    return (
        <div className={`grid grid-cols-1 col-span-12 ${props.step !== props.index ? "hidden" : ""}`}>
            <div className="flex-content-column col-span-12">
                <Components.RecaptItem title={"Informations relatives à l'employeur"}
                name={'responsable-emploi'} value={contratObject['responsable-emploi']} hasEditIcon2={true}
                handleEditClick={handleEditClick} />

               <Components.RecaptItem title={"Informations relatives à l'employé"}
                                       name={'employe'} value={contratObject['employe']} hasEditIcon2={true}
                                       handleEditClick={handleEditClick} />

                <Components.RecaptItem title={"Informations relatives aux modalités du contrat"}
                                       name={'info-emploi'} value={contratObject['info-emploi']} hasEditIcon2={true}
                                       handleEditClick={handleEditClick} />

            </div>
            <div className="intro-y col-span-12 flex items-center justify-center
            sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300" 
                onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button disabled={editionLoading} className="button w-24 justify-center block bg-theme-1
                text-white ml-2" onClick={handleStepSubmit}>
                     {editionLoading?"En cours...":"Suivant"}
                </button>
            </div>

            {isEditModalOpen ?
                <Components.Modal handleModalClose={() => setIsEditModalOpen(false)} 
                handleModalValidate={() => setIsEditModalOpen(false)} 
                title={modalTitle ?? "Modification du questionnaire"} >
                    <Components.RecapEditForm handleSubmit={props.handleNextClick}
                    value={props.questionnaireObject[valueName]} valueName={valueName} 
                    valueArrayIndex={valueArrayIndex} setIsEditModalOpen={setIsEditModalOpen}
                    activites={''} sigle={''} currency={getCurrencyFromOffre('')}/>
                </Components.Modal>
            : null}

        </div>
    )
}