import { Data } from "../data";

const getDemandeTotalAmount = demande => {
    const { ChampsDemande } = Data;
    const chamDemandeObject = JSON.parse(demande.champs_demande);
    const LIBELLE = demande.type_demande.libelle;
    const CODE_PAYS = demande.pays.code.toUpperCase();

    let champsDemandeData = [];
    let montantTotal = 0;

    if (LIBELLE.includes('SARL')) champsDemandeData = [...ChampsDemande[CODE_PAYS].SARL];
    if (LIBELLE.includes('SARLU')) champsDemandeData = [...ChampsDemande[CODE_PAYS].SARLU];
    if (LIBELLE.includes('SAS')) champsDemandeData = [...ChampsDemande[CODE_PAYS].SAS];
    if (LIBELLE.includes('SASU')) champsDemandeData = [...ChampsDemande[CODE_PAYS].SASU];
    if (LIBELLE.includes('ONG')) champsDemandeData = [...ChampsDemande[CODE_PAYS].ONG];
    if (LIBELLE.includes('Association')) 
        champsDemandeData = [...ChampsDemande[CODE_PAYS].ASSOCIATION];
    if (LIBELLE.includes('Fondation')) 
        champsDemandeData = [...ChampsDemande[CODE_PAYS].FONDATION];
    if (LIBELLE.includes('Domiciliation')) 
        champsDemandeData = [...ChampsDemande[CODE_PAYS].DOMICILIATION];
    if (LIBELLE.includes('Marque')) 
        champsDemandeData = [...ChampsDemande[CODE_PAYS].DEPOT_MARQUE];

    champsDemandeData.forEach(data => {
        if (data.name in chamDemandeObject === false && data.options.length < 1) return;
        data.options.forEach(option => {
            if (option.price === "" || !option.price) return;
            if (option.value === chamDemandeObject[data.name])
                montantTotal += option.price
        })
    });

    return montantTotal;
}

const getAssocieName = associe => {
    switch (associe.type) {
        case "particulier":
            return `${associe?.nom??""} ${associe?.prenoms??""}`;
        case "moi-meme":
            return `${associe?.nom??""} ${associe?.prenoms??""}`;
        case "societe":
            return associe['denomination'] ?? "";
        default:
            return null
    }
}

const getCurrencyFromOffre = offre => {
  //  return new RegExp(/\(.*? (\D*?)\)/, 'gi').exec(offre)[1] ?? "FCFA";
    const match = new RegExp(/\(.*? (\D*?)\)/, 'gi').exec(offre);
    return match ? match[1] : "FCFA";
}

const getCurrency = country => {
    return country.monnaie;
}

export const Demande = {
    getDemandeTotalAmount,
    getAssocieName,
    getCurrencyFromOffre,
    getCurrency
}