export const TYPE_DEMANDS_LIST_REQUESTING = 'type-demands/TYPE_DEMANDS_LIST_REQUESTING';
export const TYPE_DEMANDS_LIST_SUCCESS = 'type-demands/TYPE_DEMANDS_LIST_SUCCESS';
export const TYPE_DEMANDS_LIST_FAILED = 'type-demands/TYPE_DEMANDS_LIST_FAILED';

export const TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_REQUESTING = 'type-demands/TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_REQUESTING';
export const TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_SUCCESS = 'type-demands/TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_SUCCESS';
export const TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_FAILED = 'type-demands/TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_FAILED';

export const TYPE_DEMANDS_PAYS_LIST_REQUESTING = 'type-demands/TYPE_DEMANDS_PAYS_LIST_REQUESTING';
export const TYPE_DEMANDS_PAYS_LIST_SUCCESS = 'type-demands/TYPE_DEMANDS_PAYS_LIST_SUCCESS';
export const TYPE_DEMANDS_PAYS_LIST_FAILED = 'type-demands/TYPE_DEMANDS_PAYS_LIST_FAILED';