import * as constants from "./constants";

export const initialState = {
    clients_loading: false,
    clients_list_loading: false,
    clients_activate_loading: false,
    clients_update_loading: false,
    data: [],
    client:null,
    client_error:null,
    error_lists:null,
    clients_edit_loading:false,
    clients_edit:null,
    client_edit_error:null,
    client_set_data:null,
    //Client par pays
    clients_pays_list_loading: false,
    clients_pays_list: [],
    clients_pays_list_error:null,
}

const clientsReducer = (state = initialState, action)=> {
    switch (action.type) {
        // Lister les clients
        case constants.CLIENTS_LIST_REQUESTING:
            return {
                ...state,
                clients_list_loading: true,
            };

        case constants.CLIENTS_LIST_SUCCESS:

            return {
                ...state,
                clients_list_loading: false,
                data: action.payload
            };

        case constants.CLIENTS_LIST_FAILED:
            return {
                ...state,
                clients_list_loading: false,
                error_lists:action.error
            };

        //     Ajout d'un Client
        case constants.CLIENTS_ADD_REQUESTING:
            return {
                ...state,
                clients_loading: true,
            };

        case constants.CLIENTS_ADD_SUCCESS:
            return {
                ...state,
                clients_loading: false,
                client: action.payload
            };

        case constants.CLIENTS_ADD_FAILED:

            return {
                ...state,
                clients_loading: false,
                client_error:action.error
            };

        //     Editer un client
        case constants.CLIENTS_EDIT_REQUESTING:
            return {
                ...state,
                clients_edit_loading: true,
            };

        case constants.CLIENTS_EDIT_SUCCESS:

            return {
                ...state,
                clients_edit_loading: false,
                clients_edit: action.payload
            };

        case constants.CLIENTS_EDIT_FAILED:

            return {
                ...state,
                clients_edit_loading: false,
                client_edit_error:action.error
            };

        case constants.CLIENTS_SET_DATA:
            return {
                ...state,
                client_set_data: action.payload,
            };


        // Lister les clients par pays
        case constants.CLIENTS_PAYS_LIST_REQUESTING:
            return {
                ...state,
                clients_pays_list_loading: true,
            };

        case constants.CLIENTS_PAYS_LIST_SUCCESS:

            return {
                ...state,
                clients_pays_list_loading: false,
                clients_pays_list: action.payload
            };

        case constants.CLIENTS_PAYS_LIST_FAILED:
            return {
                ...state,
                clients_pays_list_loading: false,
                clients_pays_list_error:action.error
            };

        default:
            return state;
    }
}
export default clientsReducer
