import * as constants from "./constants";

export const initialState = {
    isLoading: false,
    errors: null,
    isAuthenticated: false,
    admin: null,
    token:null
}

const authReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.LOGIN_REQUESTING:
            return {
                ...state,
                isLoading: true,
            };
        case constants.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                admin: action.payload?.admin,
                token: action.payload?.token,
                errors: null
            };

        case constants.LOGIN_FAILED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                errors:action.error,
                admin: null,
                token:null
            };

        default:
            return state;
    }
}
export default authReducer
