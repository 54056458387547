import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listTypePiecesSaga(loginPayload) {
     const url ='admin/type-pieces'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.typePiecesListSuccess(response?.data?.type_pieces));
    } catch (error) {
        yield put(constants.typePiecesListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* piecesByTypeDemandsSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/type-demandes/${payload.type_demande_id}/type-pieces`
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.piecesByTypeDemandsSuccess(response?.data?.type_pieces));
    } catch (error) {
        yield put(constants.piecesByTypeDemandsFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

export default function* typePiecesSaga() {
    yield takeEvery(Actions.TYPE_PIECES_LIST_REQUESTING, listTypePiecesSaga);
    yield takeEvery(Actions.PIECES_BY_TYPE_DEMANDS_REQUESTING, piecesByTypeDemandsSaga);
}
