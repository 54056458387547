import { Api } from "./Api";

const ENPOINTS = {
  Demande: "demandes",
  Exist: "exist",
};

const getAll = (signal) => {
  return Api.get(ENPOINTS.Demande, signal);
};

const getById = (id, signal) => {
  return Api.get(`user/${ENPOINTS.Demande}/${id}`, signal);
};

const getDemandePaiements = (id, signal) => {
  return Api.get(`user/${ENPOINTS.Demande}/${id}/paiements`, signal);
};

const getPieces = (id, signal) => {
  return Api.get(`user/${ENPOINTS.Demande}/${id}/pieces`, signal);
};

const getStatutEtatTraitementById = (demandeId, etapeTraitementId, signal) => {
  return Api.get(
    `user/${ENPOINTS.Demande}/${demandeId}/etape-traitements/${etapeTraitementId}/status-etat-traitement`,
    signal
  );
};

const getDocuments = (id, signal, etape_traitement_id = null) => {
  const queryString = etape_traitement_id
    ? `etape_traitement_id=${etape_traitement_id}`
    : "";
  return Api.get(
    `user/${ENPOINTS.Demande}/${id}/documents?${queryString}`,
    signal
  );
};

const create = (payload, signal) => {
  return Api.post(`user/${ENPOINTS.Demande}`, payload, signal);
};
const creates = (token,payload, signal) => {
  return Api.posts(`user/${ENPOINTS.Demande}`,token, payload, signal);
};

const verificationUser = (payload, signal) => {
  return Api.post(`user/${ENPOINTS.Exist}`, payload, signal);
};
const verificationUsers = (payload, signal) => {
  return Api.posts(`user/${ENPOINTS.Exist}`, payload, signal);
};

const end = (id, signal) => {
  return Api.post(`user/${ENPOINTS.Demande}/${id}/end`, "", signal);
};

const update = (id, payload, signal) => {
  return Api.put(`user/${ENPOINTS.Demande}/${id}`, payload, signal);
};

const updateQuestionnaire = (id, payload, signal) => {
  return Api.put(
    `user/${ENPOINTS.Demande}/${id}/champs-questionnaire`,
    payload,
    signal
  );
};

const updateChampsEtapeTraitement = (id, payload, signal) => {
  return Api.put(
    `user/${ENPOINTS.Demande}/${id}/champs-etape-traitements`,
    payload,
    signal
  );
};

const updateEtapeTraitement = (id, payload, signal) => {
  return Api.put(
    `user/${ENPOINTS.Demande}/${id}/etape-traitement`,
    payload,
    signal
  );
};

const destroy = (id, signal) => {
  return Api.erase(`${ENPOINTS.Demande}/${id}`, signal);
};

export const DemandeService = {
  getAll,
  getDemandePaiements,
  getStatutEtatTraitementById,
  getDocuments,
  getPieces,
  getById,
  create,
  creates,
  end,
  update,
  updateQuestionnaire,
  updateEtapeTraitement,
  updateChampsEtapeTraitement,
  destroy,
  verificationUser,
  verificationUsers
};
