import * as constants from "./constants";
export const  statusEtatTraitementsRequest =(payload,id)=> {
    return { type: constants.STATUS_ETAT_TRAITEMENTS_REQUESTING,payload,id}
}
export const statusEtatTraitementsSuccess=(payload)=>{
    return { type:constants.STATUS_ETAT_TRAITEMENTS_SUCCESS, payload }
}
export const statusEtatTraitementsFailed=(error)=>{
    return { type:constants.STATUS_ETAT_TRAITEMENTS_FAILED, error }
}
//Detail de status etat traitements
export const  statusEtatTraitementsEditRequest =(payload,id)=> {
    return { type: constants.STATUS_ETAT_TRAITEMENTS_EDIT_REQUESTING,payload,id}
}
export const statusEtatTraitementsEditSuccess=(payload)=>{
    return { type:constants.STATUS_ETAT_TRAITEMENTS_EDIT_SUCCESS, payload }
}
export const statusEtatTraitementsEditFailed=(error)=>{
    return { type:constants.STATUS_ETAT_TRAITEMENTS_EDIT_FAILED, error }
}

export const statusEtatTraitementsSetData=(payload)=>{
    return { type:constants.STATUS_ETAT_TRAITEMENTS_SET_DATA, payload }
}
