//Organizations list
export const ORGANIZATIONS_AGENCE_LIST_REQUESTING = 'organizations/ORGANIZATIONS_AGENCE_LIST_REQUESTING';
export const ORGANIZATIONS_AGENCE_LIST_SUCCESS = 'organizations/ORGANIZATIONS_AGENCE_LIST_SUCCESS';
export const ORGANIZATIONS_AGENCE_LIST_FAILED = 'organizations/ORGANIZATIONS_AGENCE_LIST_FAILED';


//Organizations list of sub organization
export const ORGANIZATIONS_SUB_ORGANISATION_LIST_REQUESTING = 'organizations/ORGANIZATIONS_SUB_ORGANISATION_LIST_REQUESTING';
export const ORGANIZATIONS_SUB_ORGANISATION_LIST_SUCCESS = 'organizations/ORGANIZATIONS_SUB_ORGANISATION_LIST_SUCCESS';
export const ORGANIZATIONS_SUB_ORGANISATION_LIST_FAILED = 'organizations/ORGANIZATIONS_SUB_ORGANISATION_LIST_FAILED';

//Organizations role list
export const ORGANIZATIONS_ROLE_LIST_REQUESTING = 'organizations/ORGANIZATIONS_ROLE_LIST_REQUESTING';
export const ORGANIZATIONS_ROLE_LIST_SUCCESS = 'organizations/ORGANIZATIONS_ROLE_LIST_SUCCESS';
export const ORGANIZATIONS_ROLE_LIST_FAILED = 'organizations/ORGANIZATIONS_ROLE_LIST_FAILED';



// create sub organization
export const ORGANIZATIONS_SUB_ORGANISATION_CREATE_REQUESTING = 'organizations/ORGANIZATIONS_SUB_ORGANISATION_CREATE_REQUESTING';
export const ORGANIZATIONS_SUB_ORGANISATION_CREATE_SUCCESS = 'organizations/ORGANIZATIONS_SUB_ORGANISATION_CREATE_SUCCESS';
export const ORGANIZATIONS_SUB_ORGANISATION_CREATE_FAILED = 'organizations/ORGANIZATIONS_SUB_ORGANISATION_CREATE_FAILED';


//Organizations create agence
export const AGENCES_CREATE_REQUESTING = 'organizations/AGENCES_CREATE_REQUESTING';
export const AGENCES_CREATE_SUCCESS = 'organizations/AGENCES_CREATE_SUCCESS';
export const AGENCES_CREATE_FAILED = 'organizations/AGENCES_CREATE_FAILED';

//Organizations detail
export const ORGANIZATIONS_DETAIL_REQUESTING = 'organizations/ORGANIZATIONS_DETAIL_REQUESTING';
export const ORGANIZATIONS_DETAIL_SUCCESS = 'organizations/ORGANIZATIONS_DETAIL_SUCCESS';
export const ORGANIZATIONS_DETAIL_FAILED = 'organizations/ORGANIZATIONS_DETAIL_FAILED';

//Organizations user list
export const ORGANIZATIONS_USER_LIST_REQUESTING = 'organizations/ORGANIZATIONS_USER_LIST_REQUESTING';
export const ORGANIZATIONS_USER_LIST_SUCCESS = 'organizations/ORGANIZATIONS_USER_LIST_SUCCESS';
export const ORGANIZATIONS_USER_LIST_FAILED = 'organizations/ORGANIZATIONS_USER_LIST_FAILED';



//Organizations agence list
export const AGENCES_USER_LIST_REQUESTING = 'organizations/AGENCES_USER_LIST_REQUESTING';
export const AGENCES_USER_LIST_SUCCESS = 'organizations/AGENCES_USER_LIST_SUCCESS';
export const AGENCES_USER_LIST_FAILED = 'organizations/AGENCES_USER_LIST_FAILED';

//Organizations create user
export const ORGANIZATIONS_USER_CREATE_REQUESTING = 'organizations/ORGANIZATIONS_USER_CREATE_REQUESTING';
export const ORGANIZATIONS_USER_CREATE_SUCCESS = 'organizations/ORGANIZATIONS_USER_CREATE_SUCCESS';
export const ORGANIZATIONS_USER_CREATE_FAILED = 'organizations/ORGANIZATIONS_USER_CREATE_FAILED';

//Organizations edit user
export const ORGANIZATIONS_USER_EDIT_REQUESTING = 'organizations/ORGANIZATIONS_USER_EDIT_REQUESTING';
export const ORGANIZATIONS_USER_EDIT_SUCCESS = 'organizations/ORGANIZATIONS_USER_EDIT_SUCCESS';
export const ORGANIZATIONS_USER_EDIT_FAILED = 'organizations/ORGANIZATIONS_USER_EDIT_FAILED';

//Organizations commission create
export const ORGANIZATIONS_COMMISSION_CREATE_REQUESTING = 'organizations/ORGANIZATIONS_COMMISSION_CREATE_REQUESTING';
export const ORGANIZATIONS_COMMISSION_CREATE_SUCCESS = 'organizations/ORGANIZATIONS_COMMISSION_CREATE_SUCCESS';
export const ORGANIZATIONS_COMMISSION_CREATE_FAILED = 'organizations/ORGANIZATIONS_COMMISSION_CREATE_FAILED';

//Organizations commission withdrawal
export const ORGANIZATIONS_COMMISSION_WITHDRAWAL_REQUESTING = 'organizations/ORGANIZATIONS_COMMISSION_WITHDRAWAL_REQUESTING';
export const ORGANIZATIONS_COMMISSION_WITHDRAWAL_SUCCESS = 'organizations/ORGANIZATIONS_COMMISSION_WITHDRAWAL_SUCCESS';
export const ORGANIZATIONS_COMMISSION_WITHDRAWAL_FAILED = 'organizations/ORGANIZATIONS_COMMISSION_WITHDRAWAL_FAILED';

//Commission list
export const COMMISSIONS_LIST_REQUESTING = 'organizations/COMMISSIONS_LIST_REQUESTING';
export const COMMISSIONS_LIST_SUCCESS = 'organizations/COMMISSIONS_LIST_SUCCESS';
export const COMMISSIONS_LIST_FAILED = 'organizations/COMMISSIONS_LIST_FAILED';

//Organizations commission statistics
export const COMMISSIONS_STATISTICS_REQUESTING = 'organizations/COMMISSIONS_STATISTICS_REQUESTING';
export const COMMISSIONS_STATISTICS_SUCCESS = 'organizations/COMMISSIONS_STATISTICS_SUCCESS';
export const COMMISSIONS_STATISTICS_FAILED = 'organizations/COMMISSIONS_STATISTICS_FAILED';

//Sales list
export const SALES_LIST_REQUESTING = 'organizations/SALES_LIST_REQUESTING';
export const SALES_LIST_SUCCESS = 'organizations/SALES_LIST_SUCCESS';
export const SALES_LIST_FAILED = 'organizations/SALES_LIST_FAILED';

//Log list
export const LOGS_LIST_REQUESTING = 'organizations/LOGS_LIST_REQUESTING';
export const LOGS_LIST_SUCCESS = 'organizations/LOGS_LIST_SUCCESS';
export const LOGS_LIST_FAILED = 'organizations/LOGS_LIST_FAILED';
