import * as Icons from "react-feather";

export function InfoBox(props) {
//   console.log("InfoBox", props);
  return (
    <div className="intro-y box lg:mt-5">
      {props.title ? (
        <div
          className="flex items-center justify-between px-5 py-5 sm:py-3 border-b 
                border-gray-200 dark:border-dark-5"
        >
          <h2 className="font-medium text-base mr-auto">{props.title}</h2>
          {props.hasEditIcon ? (
            <Icons.Edit onClick={props.handleEditClick} />
          ) : null}
        </div>
      ) : null}
      <div className="p-5">{props.children}</div>
    </div>
  );
}
