import * as constants from "./constants";

export const  etapeTraitementsListRequest =()=> {
    return { type: constants.ETAPE_TRAITEMENTS_LIST_REQUESTING}
}
export const etapeTraitementsListSuccess=(payload)=>{
    return { type:constants.ETAPE_TRAITEMENTS_LIST_SUCCESS, payload }
}
export const etapeTraitementsListFailed=(error)=>{
    return { type:constants.ETAPE_TRAITEMENTS_LIST_FAILED, error }
}


export const  etapeTraitementsEtatListRequest =(payload)=> {
    return { type: constants.ETAPE_TRAITEMENTS_ETAT_LIST_REQUESTING,payload}
}
export const etapeTraitementsEtatListSuccess=(payload)=>{
    return { type:constants.ETAPE_TRAITEMENTS_ETAT_LIST_SUCCESS, payload }
}
export const etapeTraitementsEtatListFailed=(error)=>{
    return { type:constants.ETAPE_TRAITEMENTS_ETAT_LIST_FAILED, error }
}

//Detail etape de traitements
export const  etapeTraitementsDetailRequest =(payload)=> {
    return { type: constants.ETAPE_TRAITEMENTS_DETAIL_REQUESTING,payload}
}
export const etapeTraitementsDetailSuccess=(payload)=>{
    return { type:constants.ETAPE_TRAITEMENTS_DETAIL_SUCCESS, payload }
}
export const etapeTraitementsDetailFailed=(error)=>{
    return { type:constants.ETAPE_TRAITEMENTS_DETAIL_FAILED, error }
}
