import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { Components } from "..";

function CheckoutForm(props) {
    const CURRENCY = (props.currency !== "FCFA") ? props.currency : "XOF";
    const { stripe, elements, usePaiement, handlePaymentSubmit } = props;

    const handleSubmit = async e => {
        e.preventDefault();
        usePaiement.setIsDisabled(true);

        if (!stripe || !elements || usePaiement.isDisabled) return;

        try {
            const card = elements.getElement(CardElement);
            const result = await stripe.createToken(card);

        if (result.error) throw new Error(result.error.message);
        
        const payment_data = {
            source: result.token.id,
            amount: usePaiement.montant,
            currency: CURRENCY,
            description: 'This is test payment'
        }

        handlePaymentSubmit(e, payment_data);
            
        } catch (error) {
            usePaiement.setIsDisabled(false);
            if ('messages' in error)
                return; //toast
        }
    }

    return (
        <div>
            <h3 className="text-gray-600 font-light mb-3" style={{fontSize: '1.4rem'}}>
                {usePaiement.montant} {CURRENCY}
            </h3>
            <form onSubmit={handleSubmit}>
                <Components.Stripe.CardSection />
                <button className="btn-pay" disabled={usePaiement.isDisabled}>
                    {usePaiement.isDisabled ? "Paiement en cours..." : "Payer maintenant"}
                </button>
            </form>
        </div>
    );
}


export function InjectedCheckoutForm(props) {
    return (
        <ElementsConsumer>
            {({ stripe, elements }) => (
                <CheckoutForm stripe={stripe} elements={elements} 
                handlePaymentSubmit={props.handlePaymentSubmit}
                usePaiement={props.usePaiement} currency={props.currency}/>
            )}
        </ElementsConsumer>
    );
}
