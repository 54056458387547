import * as constants from "./constants";

export const  statusDemandsListRequest =()=> {
    return { type: constants.STATUS_DEMANDS_LIST_REQUESTING}
}

export const statusDemandsListSuccess=(payload)=>{
    return { type:constants.STATUS_DEMANDS_LIST_SUCCESS, payload }
}

export const statusDemandsListFailed=(error)=>{
    return { type:constants.STATUS_DEMANDS_LIST_FAILED, error }
}
