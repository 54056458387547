import * as constants from "./constants";

export const initialState = {

    //Organization agence list
    organizations_agence_list_loading: false,
    organizations_agence_list: [],
    organizations_agence_list_error:null,


    //Organization suborganisation list
    organizations_sub_organisation_list_loading: false,
    organizations_sub_organisation_list: [],
    organizations_sub_organisation_list_error:null,



    //Organization suborganisation create
    organizations_sub_organisation_create_loading: false,
    organizations_sub_organisation_create: null,
    organizations_sub_organisation_create_error:null,


    //Organization role list
    organizations_role_list_loading: false,
    organizations_role_list: [],
    organizations_role_list_error:null,

    //Organization user list
    organizations_user_list_loading: false,
    organizations_user_list: [],
    organizations_user_list_error:null,

    //Agences user list
    agences_user_list_loading: false,
    agences_user_list: [],
    agences_user_list_error:null,

    //Agence create
    agences_create_loading: false,
    agences_create: null,
    agences_create_error:null,

    //Organization agence list
    organizations_detail_loading: false,
    organizations_detail: null,
    organizations_detail_error:null,

    //Organizations create
    organizations_user_create_loading: false,
    organizations_user_create: null,
    organizations_user_create_error:null,

    //Organizations edit
    organizations_user_edit_loading: false,
    organizations_user_edit: null,
    organizations_user_edit_error:null,

    //Organizations commission create
    organizations_commission_create_loading: false,
    organizations_commission_create: null,
    organizations_commission_create_error:null,

    //Organizations commission create
    organizations_commission_withdrawal_loading: false,
    organizations_commission_withdrawal: null,
    organizations_commission_withdrawal_error:null,

    //Commissions list
    commissions_list_loading: false,
    commissions_list: [],
    commissions_list_error:null,

    //Sales list
    sales_list_loading: false,
    sales_list: [],
    sales_list_error:null,

    //Logs list
    logs_list_loading: false,
    logs_list: [],
    logs_list_error:null,

    //Commission statistics
    commissions_statistics_loading: false,
    commissions_statistics: null,
    commissions_statistics_error:null,
}

const organizationsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.ORGANIZATIONS_AGENCE_LIST_REQUESTING:
            return {
                ...state,
                organizations_agence_list_loading: true,
            };
        case constants.ORGANIZATIONS_AGENCE_LIST_SUCCESS:
            return {
                ...state,
                organizations_agence_list_loading: false,
                organizations_agence_list: action.payload,
                organizations_agence_list_error:null
            };
        case constants.ORGANIZATIONS_AGENCE_LIST_FAILED:
            return {
                ...state,
                organizations_agence_list_loading: false,
                organizations_agence_list_error:action.error,
                organizations_agence_list:[]
            };

         // Organization suborganisation
        case constants.ORGANIZATIONS_SUB_ORGANISATION_LIST_REQUESTING:
            return {
                ...state,
                organizations_sub_organisation_list_loading: true,
            };
        case constants.ORGANIZATIONS_SUB_ORGANISATION_LIST_SUCCESS:
            return {
                ...state,
                organizations_sub_organisation_list_loading: false,
                organizations_sub_organisation_list: action.payload,
                organizations_sub_organisation_list_error:null
            };
        case constants.ORGANIZATIONS_SUB_ORGANISATION_LIST_FAILED:
            return {
                ...state,
                organizations_sub_organisation_list_loading: false,
                organizations_sub_organisation_list_error:action.error,
                organizations_sub_organisation_list:[]
            };



                //  Create sub organisation
        case constants.ORGANIZATIONS_SUB_ORGANISATION_CREATE_REQUESTING:
            return {
                ...state,
                organizations_sub_organisation_create_loading: true,
                
            };
        case constants.ORGANIZATIONS_SUB_ORGANISATION_CREATE_SUCCESS:
            return {
                ...state,
                organizations_sub_organisation_create_loading: false,
                organizations_sub_organisation_create: action.payload,
                organizations_sub_organisation_create_error:null

            };
        case constants.ORGANIZATIONS_SUB_ORGANISATION_CREATE_FAILED:
            return {
                ...state,
                organizations_sub_organisation_create_loading: false,
                organizations_sub_organisation_create: null,
                organizations_sub_organisation_create_error:action.error

            };    

        // Organization Sub Organisation
        case constants.ORGANIZATIONS_ROLE_LIST_REQUESTING:
            return {
                ...state,
                organizations_role_list_loading: true,
            };

        case constants.ORGANIZATIONS_ROLE_LIST_SUCCESS:
            return {
                ...state,
                organizations_role_list_loading: false,
                organizations_role_list: action.payload,
                organizations_role_list_error:null
            };

        case constants.ORGANIZATIONS_ROLE_LIST_FAILED:
            return {
                ...state,
                organizations_role_list_loading: false,
                organizations_role_list_error:action.error,
                organizations_role_list:[]
            };

        // Organizations user
        case constants.ORGANIZATIONS_USER_LIST_REQUESTING:
            return {
                ...state,
                organizations_user_list_loading: true,
            };
        case constants.ORGANIZATIONS_USER_LIST_SUCCESS:

            return {
                ...state,
                organizations_user_list_loading: false,
                organizations_user_list: action.payload,
                organizations_user_list_error:null

            };
        case constants.ORGANIZATIONS_USER_LIST_FAILED:

            return {
                ...state,
                organizations_user_list_loading: false,
                organizations_user_list_error:action.error,
                organizations_user_list: []
            };

        // Agences user list
        case constants.AGENCES_USER_LIST_REQUESTING:
            return {
                ...state,
                agences_user_list_loading: true,
            };
        case constants.AGENCES_USER_LIST_SUCCESS:
            return {
                ...state,
                agences_user_list_loading: false,
                agences_user_list: action.payload,
                agences_user_list_error:null
            };
        case constants.AGENCES_USER_LIST_FAILED:
            return {
                ...state,
                agences_user_list_loading: false,
                agences_user_list_error:action.error,
                agences_user_list: []
            };

        // Detail de l'organization
        case constants.ORGANIZATIONS_DETAIL_REQUESTING:
            return {
                ...state,
                organizations_detail_loading: true
            };
        case constants.ORGANIZATIONS_DETAIL_SUCCESS:

            return {
                ...state,
                organizations_detail_loading: false,
                organizations_detail: action.payload,
                organizations_detail_error:null
            };
        case constants.ORGANIZATIONS_DETAIL_FAILED:
            return {
                ...state,
                organizations_detail_loading: false,
                organizations_detail: null,
                organizations_detail_error:action.error
            };

        //  Creation d'une agence
        case constants.AGENCES_CREATE_REQUESTING:
            return {
                ...state,
                agences_create_loading: true,
            };
        case constants.AGENCES_CREATE_SUCCESS:

            return {
                ...state,
                agences_create_loading: false,
                agences_create: action.payload,
                agences_create_error:null
            };
        case constants.AGENCES_CREATE_FAILED:
            return {
                ...state,
                agences_create_loading: false,
                agences_create: null,
                agences_create_error:action.error
            };


        //  Creation d'une agence
        case constants.ORGANIZATIONS_USER_CREATE_REQUESTING:
            return {
                ...state,
                organizations_user_create_loading: true,
            };
        case constants.ORGANIZATIONS_USER_CREATE_SUCCESS:
            return {
                ...state,
                organizations_user_create_loading: false,
                organizations_user_create: action.payload,
                organizations_user_create_error:null
            };
        case constants.ORGANIZATIONS_USER_CREATE_FAILED:
            return {
                ...state,
                organizations_user_create_loading: false,
                organizations_user_create: null,
                organizations_user_create_error:action.error
            };


        //  editer un utilisateur
        case constants.ORGANIZATIONS_USER_EDIT_REQUESTING:
            return {
                ...state,
                organizations_user_edit_loading: true,
            };
        case constants.ORGANIZATIONS_USER_EDIT_SUCCESS:
            return {
                ...state,
                organizations_user_edit_loading: false,
                organizations_user_edit: action.payload,
                organizations_user_edit_error:null
            };
        case constants.ORGANIZATIONS_USER_EDIT_FAILED:
            return {
                ...state,
                organizations_user_edit_loading: false,
                organizations_user_edit: null,
                organizations_user_edit_error:action.error
            };

        // Creation d'une commission
        case constants.ORGANIZATIONS_COMMISSION_CREATE_REQUESTING:
            return {
                ...state,
                organizations_commission_create_loading: true,
            };

        case constants.ORGANIZATIONS_COMMISSION_CREATE_SUCCESS:
            return {
                ...state,
                organizations_commission_create_loading: false,
                organizations_commission_create: action.payload,
                organizations_commission_create_error:null
            };

        case constants.ORGANIZATIONS_COMMISSION_CREATE_FAILED:
            return {
                ...state,
                organizations_commission_create_loading: false,
                organizations_commission_create_create: null,
                organizations_commission_create_error:action.error
            };

        // Organization commission withdrawal
        case constants.ORGANIZATIONS_COMMISSION_WITHDRAWAL_REQUESTING:
            return {
                ...state,
                organizations_commission_withdrawal_loading: true,
            };

        case constants.ORGANIZATIONS_COMMISSION_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                organizations_commission_withdrawal_loading: false,
                organizations_commission_withdrawal: action.payload,
                organizations_commission_withdrawal_error:null
            };

        case constants.ORGANIZATIONS_COMMISSION_WITHDRAWAL_FAILED:
            return {
                ...state,
                organizations_commission_withdrawal_loading: false,
                organizations_commission_withdrawal: null,
                organizations_commission_withdrawal_error:action.error
            };


        // Commission list
        case constants.COMMISSIONS_LIST_REQUESTING:
            return {
                ...state,
                commissions_list_loading: true,
            };
        case constants.COMMISSIONS_LIST_SUCCESS:

            return {
                ...state,
                commissions_list_loading: false,
                commissions_list: action.payload,
                commissions_list_error:null
            };
        case constants.COMMISSIONS_LIST_FAILED:

            return {
                ...state,
                commissions_list_loading: false,
                commissions_list_error:action.error,
                commissions_list: []
            };

        // Sales list
        case constants.SALES_LIST_REQUESTING:
            return {
                ...state,
                sales_list_loading: true,
            };
        case constants.SALES_LIST_SUCCESS:

            return {
                ...state,
                sales_list_loading: false,
                sales_list: action.payload,
                sales_list_error:null
            };
        case constants.SALES_LIST_FAILED:

            return {
                ...state,
                sales_list_loading: false,
                sales_list_error:action.error,
                sales_list: []
            };

        // Logs list
        case constants.LOGS_LIST_REQUESTING:
            return {
                ...state,
                logs_list_loading: true,
            };
        case constants.LOGS_LIST_SUCCESS:

            return {
                ...state,
                logs_list_loading: false,
                logs_list: action.payload,
                logs_list_error:null
            };
        case constants.LOGS_LIST_FAILED:

            return {
                ...state,
                logs_list_loading: false,
                logs_list_error:action.error,
                logs_list: []
            };


        // Commission statistics
        case constants.COMMISSIONS_STATISTICS_REQUESTING:
            return {
                ...state,
                commissions_statistics_loading: true,
            };
        case constants.COMMISSIONS_STATISTICS_SUCCESS:

            return {
                ...state,
                commissions_statistics_loading: false,
                commissions_statistics: action.payload,
                commissions_statistics_error:null
            };
        case constants.COMMISSIONS_STATISTICS_FAILED:
            return {
                ...state,
                commissions_statistics_loading: false,
                commissions_statistics: null,
                commissions_statistics_error:action.error
            };

        default:
            return state;
    }
}
export default organizationsReducer
