import { SigleStep } from "../SigleStep";
import { SigleStepOng } from "../SigleStepOng";
import { AdresseOneStep } from "../AdresseOneStep";
import { AdresseTwoStep } from "../AdresseTwoStep";
import { Associes } from "../Associes";
import { AssociesUni } from "../AssociesUni";
import { AssocieTypes } from "../AssocieTypes";
import { CapitalStep } from "../CapitalStep";
import { ActiviteStep } from "../ActiviteStep";
import { GerantStep } from "../GerantStep";
import { PresidentStep } from "../PresidentStep_ci_sn_bf_bn";
import { PresidentOng } from "../PresidentOng";
import { DirecteurStep } from "../DirecteurSteps_ci_sn_bf_bn";
import { SecretaireGeneral } from "../SecretaireGeneral";
import { Bureau } from "../Bureau";
import { Tresorier } from "../Tresorier";
import { Commissaire } from "../Commissaire";
import { CommissaireAdjoint } from "../CommissaireAdjoint";
import { Dure } from "../Dure";
import { Montant } from "../Montant";
import { RecapStep } from "../RecapStep";
import { RecapStepOng } from "../RecapStepOng";
import { RecapStepSas } from "../RecapStepSas";
import { RecapStepSarlV2 } from "./sarl/RecapStepSarlV2";
import { RecapStepSasV2 } from "./sarl/RecapStepSasV2";
import { RecapStepSci } from "./sci/RecapStepSci";
import { SigleStepRecouvre } from "./recouvrement/SigleStepRecouvre";
import { AdresseOneStepRecouvre } from "./recouvrement/AdresseOneStepRecouvre";
import { AdresseTwoStepRecouvre } from "./recouvrement/AdresseTwoStepRecouvre";
import { FacturesRecouvre } from "./recouvrement/FacturesRecouvre";
import { RecapStepRecouvre } from "./recouvrement/RecapStepRecouvre";
import { CommentRecouvre } from "./recouvrement/CommentRecouvre";
import { AdresseOneStepStatutaire } from "./statutaire/AdresseOneStepStatutaire";
import { AdresseTwoStepStatutaire } from "./statutaire/AdresseTwoStepStatutaire";
import { GerantStepStatutaire } from "./statutaire/GerantStepStatutaire";
import { PresidentStepStatutaire } from "./statutaire/PresidentStepStatutaire";
import { RecapStepStatutaire } from "./statutaire/RecapStepStatutaire";
import { Contrat } from "../Contrat";
import { RecapStepContrat } from "../RecapStepContrat";
import { CartsStep } from "../CartsStep";
import { PaymentsStep } from "../PaymentsStep";

export const CI = {
  SARL: {
    SigleStep,
    AdresseOneStep,
    AdresseTwoStep,    
    Associes,
    CapitalStep,
    ActiviteStep,
    GerantStep,
    RecapStepSarl: RecapStep,
    RecapStepSarlV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  INDIVIDUELLE: {
    SigleStep,
    AdresseOneStep,
    AdresseTwoStep,
    Associes,
    CapitalStep,
    ActiviteStep,
    // GerantStep,
    RecapStepSarl: RecapStep,
    RecapStepSarlV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  SARLU: {
    SigleStep,
    AdresseOneStepSarlUni: AdresseOneStep,
    AdresseTwoStepSarlUni: AdresseTwoStep,
    AssociesSarlUni: AssociesUni,
    AssocieTypesSarlUni: AssocieTypes,
    CapitalStep,
    ActiviteStepSarlUni: ActiviteStep,
    GerantStep,
    RecapStepUni: RecapStep,
    RecapStepSarlV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  SASU: {
    SigleStep,
    AdresseOneStepSasUni: AdresseOneStep,
    AdresseTwoStepSasUni: AdresseTwoStep,
    AssociesSasUni: AssociesUni,
    AssocieTypesSasUni: AssocieTypes,
    ActiviteStepSasUni: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSasV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  SAS: {
    SigleStep,
    AdresseOneStepSas: AdresseOneStep,
    AdresseTwoStepSas: AdresseTwoStep,
    Associes,
    ActiviteStepSas: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSasV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  commerciale: {
    SigleStep,
    AdresseOneStepSas: AdresseOneStep,
    AdresseTwoStepSas: AdresseTwoStep,
    Associes,
    ActiviteStepSas: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSci,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  civile: {
    SigleStep,
    AdresseOneStepSas: AdresseOneStep,
    AdresseTwoStepSas: AdresseTwoStep,
    Associes,
    ActiviteStepSas: ActiviteStep,
    CapitalStep,
    PresidentStep,
    DirecteurStep,
    RecapStep: RecapStepSas,
    RecapStepSci,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  statutaire: {
    AdresseOneStepSas: AdresseOneStepStatutaire,
    AdresseTwoStepSas: AdresseTwoStepStatutaire,
    Associes: Associes,
    GerantStep: GerantStepStatutaire,
    PresidentStep: PresidentStepStatutaire,
    RecapStepSasV2: RecapStepStatutaire,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  Association: {
    SigleStepAssociation: SigleStepOng,
    AdresseOneStepAssociation: AdresseOneStep,
    AdresseTwoStepAssociation: AdresseTwoStep,
    PresidentAssociation: PresidentOng,
    SecretaireGeneralAssociation: SecretaireGeneral,
    ActiviteStepAssociation: ActiviteStep,
    TresorierAssociation: Tresorier,
    BureauAssociation: Bureau,
    CommissaireAssociation: Commissaire,
    CommissaireAssociationAdjoint: CommissaireAdjoint,
    DureAssociation: Dure,
    MontantAssociation: Montant,
    RecapStepAssopciation: RecapStepOng,
    RecapStepSarlV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  Fondation: {
    SigleStepFondation: SigleStepOng,
    ActiviteStepFondation: ActiviteStep,
    AdresseOneStepFondation: AdresseOneStep,
    AdresseTwoStepFondation: AdresseTwoStep,
    PresidentFondation: PresidentOng,
    SecretaireGeneralFondation: SecretaireGeneral,
    TresorierFondation: Tresorier,
    BureauFondation: Bureau,
    CommissaireFondation: Commissaire,
    CommissaireFondationAdjoint: CommissaireAdjoint,
    DureFondation: Dure,
    MontantFondation: Montant,
    RecapStepFondation: RecapStepOng,
    RecapStepSarlV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  ONG: {
    SigleStepOng: SigleStepOng,
    ActiviteStepOng: ActiviteStep,
    AdresseOneStepOng: AdresseOneStep,
    AdresseTwoStepOng: AdresseTwoStep,
    PresidentOng,
    SecretaireGeneralOng: SecretaireGeneral,
    TresorierOng: Tresorier,
    BureauOng: Bureau,
    CommissaireOng: Commissaire,
    CommissaireOngAjoint: CommissaireAdjoint,
    DureOng: Dure,
    MontantOng: Montant,
    RecapStepOng,
    RecapStepSarlV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  Recouvrement: {
    SigleStepRecouvre: SigleStepRecouvre,
    AdresseOneStepRecouvre: AdresseOneStepRecouvre,
    AdresseTwoStepRecouvre: AdresseTwoStepRecouvre,
    FacturesRecouvre: FacturesRecouvre,
    CommentRecouvre: CommentRecouvre,
    RecapStepRecouvre: RecapStepRecouvre,
    RecapStepSarlV2,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  CDD: {
    Contrat: Contrat,
    RecapStep: RecapStepContrat,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
  CDI: {
    Contrat: Contrat,
    RecapStep: RecapStepContrat,
    CartStep: CartsStep,
    PaymentStep: PaymentsStep,
  },
};
