import { useState } from "react";
import Alert from 'react-bootstrap/Alert';
import * as Icons from 'react-feather';
import moment from "moment/moment";
import PhoneInput from "react-phone-input-2";
export function DirecteurStepMa(props) {
    const formContainer = "directeurs-form-container";
    const [directeur, setDirecteur] = useState(props?.directeur);
    const [directeurType, setDirecteurType] = useState(Object.keys(props?.directeur).length === 0?'non':'oui');


    const handleInputChange = ( name, value) => {
        const directeursCopy = {...directeur};       
        directeursCopy[name] = value;
        directeursCopy['type'] = 'particulier';

        setDirecteur(directeursCopy);
    }
    const handleTypeDirecteurChange = ( value) => {
        
        setDirecteur({});

        const formContainerElem = window.document.querySelector(
            `#${formContainer}-0`)
        Array.from(formContainerElem.querySelectorAll('.field'))
        .forEach(field => field.value = "");

        setDirecteurType(value);
    }
    const handleStepSubmit = e => {
        e.preventDefault();


        if(directeur["date-naissance"]==="" || directeur['date-naissance']===undefined){
            directeur['date-naissance']=moment().format('YYYY-MM-DD');
        }

        if (!directeur) return alert("Vous devez remplir le formulaire !");

        props.handleNextClick(e, 'directeurs', JSON.stringify([directeur]));
    }

    // useEffect(() => {
    //     if (!nombreGerant) return;
    //     // setGerants(Array(parseInt(nombreGerant)).fill({}))
    // }, [nombreGerant])

    
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <h4 className="intro-y col-span-12 text-lg leading-none">
            </h4>
          
                    <div className="intro-y col-span-12"  id={`${formContainer}-0`}>
                    <div className="intro-y col-span-12  ">
                
                <strong>  Voulez-vous nommer un Directeur Général ?
</strong>  
            
        </div>
        <div className="intro-y col-span-12  ">
          
            {directeurType === "oui" ? 
                <Alert  variant='danger'>
                    <Alert.Heading><Icons.Info className="w-4 h-4 " /></Alert.Heading>
                    Vous pouvez nommer <Alert.Link href="#"> un Directeur Général </Alert.Link> . 
                    Attention dans une SAS, l’associé unique  ne peut cumuler les fonctions de 
                    Président et de Directeur Général
                </Alert>
            : null}

            <small> Indiquez les informations relatives au Directeur </small>
        </div>
                    
                        <div className="flex flex-col sm:flex-row mt-4 mb-2">
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                                <input type="radio" className="input border mr-2" 
                                id={`horizontal-radio-oui-0`} 
                                name={`directeur-0`} value="oui"
                                checked={directeurType==='oui'}
                                onChange={e => handleTypeDirecteurChange("oui")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`horizontal-radio-oui-0`}>
                                    Oui
                                </label> 
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                                <input type="radio" className="input border mr-2" 
                                id={`horizontal-non-0`} 
                                name={`directeur-0`} value="non"
                                checked={directeurType==='non'}
                                onChange={e => handleTypeDirecteurChange("non")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`horizontal-non-0`}>
                                    Non
                                </label> 
                            </div>
                        </div>
                        
                        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${(directeurType !== undefined && 
                        directeurType === 'non') ? 'hidden'  :'' }`}>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Civilite</div>
                                    <select type="text" value={directeur['civilite']} className="input w-full border flex-1 field"
                                    onChange={e => handleInputChange('civilite', e.target.value)}>
                                        <option value="monsieur">Monsieur</option>
                                        <option value="madame">Madame</option>
                                        <option value="mademoiselle">Mademoiselle</option>
                                    </select>
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Nationalite</div>
                                <input type="text" value={directeur['nationalite']} className="input w-full border flex-1 field"
                                placeholder="Nationalite" onChange={e => handleInputChange(
                                    'nationalite', e.target.value)}/>
                            </div>
                            <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="my-2">Adresse</div>
                                <input type="text" value={directeur['adresse']} className="input w-full border flex-1 field"
                                placeholder="Adresse" onChange={e => handleInputChange(
                                    'adresse', e.target.value)}/>
                            </div>
                             <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Nom</div>
                                <input type="text" value={directeur['nom']} className="input w-full border flex-1 field"
                                placeholder="Nom" onChange={e => handleInputChange(
                                     'nom', e.target.value)}/>
                            </div>
                             <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Prenom</div>
                                <input type="text" value={directeur['prenoms']} className="input w-full border flex-1 field"
                                placeholder="Prenom" onChange={e => handleInputChange(
                                     'prenoms', e.target.value)}/>
                            </div>
                             <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Numéro de téléphone</div>
                                 <PhoneInput
                                     country={"ci"}
                                     // value={this.e.target}
                                     value={directeur['numero-telephone']}
                                     onChange={(e) =>
                                         handleInputChange( 'numero-telephone', e)
                                     }
                                     autoFormat={false}
                                     inputStyle={{
                                         padding: "21px1 20px 21px 52px",
                                         width: "100%",
                                         boxShadow: "0px 3px 5px #00000007",
                                         borderColor: "rgba(226, 232, 240, 1)",
                                     }}
                                     placeholder="Numéro de téléphone"
                                 />

{/*                                <input type="text" value={directeur['numero-telephone']} className="input w-full border flex-1 field"
                                placeholder="Numéro de téléphone" onChange={e => handleInputChange(
                                     'numero-telephone', e.target.value)}/>*/}


                            </div>
                             <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Date de naissance</div>
                                <input type="date" value={moment(directeur['date-naissance']).format('YYYY-MM-DD')} className="input w-full border flex-1 field"
                                placeholder="Date de naissance" onChange={e => handleInputChange(
                                     'date-naissance', e.target.value)}/>
                            </div>
                             <div className="intro-y col-span-12 sm:col-span-6">
                                <div className="mb-2">Lieu de naissance</div>
                                <input type="text" value={directeur['lieu-naissance']} className="input w-full border flex-1 field"
                                placeholder="Lieu de naissance" onChange={e => handleInputChange(
                                     'lieu-naissance', e.target.value)}/>
                            </div>
                            <div className="col-span-12">Situation matrimoniale</div>
                            <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                                <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                                    <input type="radio" className="input border mr-2 field"
                                    id={`horizontal-radio-celibataire`}
                                    name={`directeur-situation-matrimoniale`} value="celibataire"
                                    checked={directeur['situation-matrimoniale']==='celibataire'}
                                    onChange={e => handleInputChange("situation-matrimoniale", "celibataire")}/>
                                    <label className="cursor-pointer select-none"
                                    htmlFor={`horizontal-radio-celibataire`}>
                                        Célibataire
                                    </label>
                                </div>
                                <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                                    <input type="radio" className="input border mr-2 field"
                                    id={`horizontal-radio-marie`}
                                    name={`directeur-situation-matrimoniale`} value="marie"
                                    checked={directeur['situation-matrimoniale']==='marie'}
                                    onChange={e => handleInputChange("situation-matrimoniale", "marie")}/>
                                    <label className="cursor-pointer select-none"
                                    htmlFor={`horizontal-radio-marie`}>
                                        Marié-e
                                    </label>
                                </div>
                            </div>
                            <div className={`intro-y col-span-12 ${(directeur['situation-matrimoniale'] !== undefined &&
                            directeur['situation-matrimoniale'] === 'marie') ? '' : 'hidden'}`}>
                                <div className="col-span-12  mb-4">Régime matrimonial</div>
                                <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                                    <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                                        <input type="radio" className="input border mr-2 field"
                                        id={`horizontal-radio-communaute-de-bien`}
                                        name={`directeur-regime-matrimonial`} value="Communaute de bien"
                                        checked={directeur['regime-matrimonial']==='Communaute de bien'}
                                        onChange={e => handleInputChange("regime-matrimonial", "Communaute de bien")}/>
                                        <label className="cursor-pointer select-none"
                                        htmlFor={`horizontal-radio-communaute-de-bien`}>
                                            Communauté de bien
                                        </label>
                                    </div>
                                    <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                                        <input type="radio" className="input border mr-2 field"
                                        id={`horizontal-radio-separation-de-bien`}
                                        name={`directeur-regime-matrimonial`} value="Séparation de bien"
                                        checked={directeur['regime-matrimonial']==='Séparation de bien'}
                                        onChange={e => handleInputChange("regime-matrimonial", "Séparation de bien")}/>
                                        <label className="cursor-pointer select-none"
                                        htmlFor={`horizontal-radio-separation-de-bien`}>
                                            Séparation de bien
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
        
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}