export const ARTICLES_LIST_REQUESTING = 'articles/ARTICLES_LIST_REQUESTING';
export const ARTICLES_LIST_SUCCESS = 'articles/ARTICLES_LIST_SUCCESS';
export const ARTICLES_LIST_FAILED = 'articles/ARTICLES_LIST_FAILED';
export const ARTICLES_REQUESTING = 'articles/ARTICLES_REQUESTING';
export const ARTICLES_SUCCESS = 'articles/ARTICLES_SUCCESS';
export const ARTICLES_FAILED = 'articles/ARTICLES_FAILED';
export const ARTICLES_DETAIL_REQUESTING = 'articles/ARTICLES_DETAIL_REQUESTING';
export const ARTICLES_DETAIL_SUCCESS = 'articles/ARTICLES_DETAIL_SUCCESS';
export const ARTICLES_DETAIL_FAILED = 'articles/ARTICLES_DETAIL_FAILED';
export const ARTICLES_EDIT_REQUESTING = 'articles/ARTICLES_EDIT_REQUESTING';
export const ARTICLES_EDIT_SUCCESS = 'articles/ARTICLES_EDIT_SUCCESS';
export const ARTICLES_EDIT_FAILED = 'articles/ARTICLES_EDIT_FAILED';