import * as constants from "./constants";

export const initialState = {
    type_documents_list_loading: false,
    data: [],
    error_type_documents_lists:null,
    //By type de demands
    documents_by_type_demands_loading: false,
    documents_by_type_demands: [],
    error_documents_by_type_demands:null,
}

const typeDocumentsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.TYPE_DOCUMENTS_LIST_REQUESTING:
            return {
                ...state,
                type_documents_list_loading: true,
            };

        case constants.TYPE_DOCUMENTS_LIST_SUCCESS:

            return {
                ...state,
                type_documents_list_loading: false,
                data: action.payload
            };

        case constants.TYPE_DOCUMENTS_LIST_FAILED:

            return {
                ...state,
                type_documents_list_loading: false,
                error_type_documents_lists:action.error
            };

            //By type de come

        case constants.DOCUMENTS_BY_TYPE_DEMANDS_REQUESTING:
            return {
                ...state,
                documents_by_type_demands_loading: true,
            };

        case constants.DOCUMENTS_BY_TYPE_DEMANDS_SUCCESS:

            return {
                ...state,
                documents_by_type_demands_loading: false,
                documents_by_type_demands: action.payload
            };

        case constants.DOCUMENTS_BY_TYPE_DEMANDS_FAILED:

            return {
                ...state,
                documents_by_type_demands_loading: false,
                error_documents_by_type_demands:action.error
            };

        default:
            return state;
    }
}
export default typeDocumentsReducer
