import * as constants from "./constants";

export const  paysListRequest =()=> {
    return { type: constants.PAYS_LIST_REQUESTING}
}
export const paysListSuccess=(payload)=>{
    return { type:constants.PAYS_LIST_SUCCESS, payload }
}
export const paysListFailed=(error)=>{
    return { type:constants.PAYS_LIST_FAILED, error }
}
