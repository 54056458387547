export const DOMICILIATION = [
    {
        name: "numero-telephone",
        question: "Veuillez saisir votre numéro de téléphone",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre numéro de téléphone ", price: ""},
           
        ]
    },
    {
        name: "email",
        question: "Veuillez saisir votre adresse mail",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre adresse mail", price: ""},
           
        ]
    },
    {
        name: "nom",
        question: "Veuillez saisir votre nom",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre nom", price: ""},
           
        ]
    },
    {
        name: "prenom",
        question: "Veuillez saisir votre prénom(s)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre prénom(s)", price: ""},
           
        ]
    },
    {
        name: "denomination",
        question: "Quel est le nom de l’entreprise que vous domicilier ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Quelle est votre domaine d'activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "BTP", price: ""},
            {value: "Evènementiel", price: ""},
            {value: "Informatiques", price: ""},
            {value: "Finance", price: ""},
            {value: "Formation", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: "6 mois de domiciliation (180 000 FCFA)", price: 180_000},
            {value: "1 an de domiciliation (300 000 FCFA)", price: 300_000},
        ]
    }
]