export function GerantsEdit(props) {
  const handleChange = (e, name, value, index) => {
    e.preventDefault();
    const valueCopy = [...props.gerants];
    valueCopy[index][name] = value;
    props.setValue(valueCopy);
  };

  return (
    <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">
           Numéro de votre pièce d’identité  {" "}
          <small   >(les anciennes pièces ne sont pas prises en compte )</small>
        </div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder=" Numéro de votre pièce d’identité"
          value={props.gerants[props.index]["numero-piece-identite"] ?? ""}
          onChange={(e) =>
            handleChange(
              e,
              "numero-piece-identite",
              e.target.value,
              props.index
            )
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Type de la piece d'identité</div>
        <select
          type="text"
          className="input w-full border flex-1 field"
          value={props.gerants[props.index]["type-piece-identite"] ?? ""}
          onChange={(e) =>
            handleChange(e, "type-piece-identite", e.target.value, props.index)
          }
        >
          <option value="CNI">CNI</option>
          <option value="Passport">Passport</option>
          <option value="Attestation d'identité">Attestation d'identité</option>
          <option value="Titre de séjour">Titre de séjour</option>
          <option value="Carte de résident">Carte de résident</option>
          <option value="Autres">Autres</option>
        </select>
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Pays de délivrance de la pièce</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Pays de délivrance de la pièce"
          value={props.gerants[props.index]["pays-piece-identite"] ?? ""}
          onChange={(e) =>
            handleChange(e, "pays-piece-identite", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Date d’établissement de la pièce</div>
        <input
          type="date"
          className="input w-full border flex-1 field"
          placeholder="Date d’établissement de la pièce"
          value={props.gerants[props.index]["date-piece-identite"] ?? ""}
          onChange={(e) =>
            handleChange(e, "date-piece-identite", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Date de validité de la pièce</div>
        <input
          type="date"
          className="input w-full border flex-1 field"
          placeholder="Date de validité de la pièce"
          value={
            props.gerants[props.index]["date-fin-validite-piece-identite"] ?? ""
          }
          onChange={(e) =>
            handleChange(
              e,
              "date-fin-validite-piece-identite",
              e.target.value,
              props.index
            )
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Civilité</div>
        <select
          type="text"
          className="input w-full border flex-1 field"
          value={props.gerants[props.index]["civilite"] ?? ""}
          onChange={(e) =>
            handleChange(e, "civilite", e.target.value, props.index)
          }
        >
          <option value="monsieur">Monsieur</option>
          <option value="madame">Madame</option>
          <option value="mademoiselle">Mademoiselle</option>
        </select>
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Nationalite</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          value={props.gerants[props.index]["nationalite"] ?? ""}
          placeholder="Nationalite"
          onChange={(e) =>
            handleChange(e, "nationalite", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="my-2">Adresse</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          value={props.gerants[props.index]["adresse"] ?? ""}
          placeholder="Adresse"
          onChange={(e) =>
            handleChange(e, "adresse", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom"
          value={props.gerants[props.index]["nom"] ?? ""}
          onChange={(e) => handleChange(e, "nom", e.target.value, props.index)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Prénom(s)</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Prénom(s)"
          value={props.gerants[props.index]["prenoms"] ?? ""}
          onChange={(e) =>
            handleChange(e, "prenoms", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro de téléphone</div>
        <input
          type="tel"
          className="input w-full border flex-1 field"
          placeholder="Numéro de téléphone"
          value={props.gerants[props.index]["numero-telephone"] ?? ""}
          onChange={(e) =>
            handleChange(e, "numero-telephone", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Date de naissance</div>
        <input
          type="date"
          className="input w-full border flex-1 field"
          placeholder="Date de naissance"
          value={props.gerants[props.index]["date-naissance"] ?? ""}
          onChange={(e) =>
            handleChange(e, "date-naissance", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Pays de naissance</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Pays de naissance"
          value={props.gerants[props.index]["pays-naissance"] ?? ""}
          onChange={(e) =>
            handleChange(e, "pays-naissance", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Lieu de naissance</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Ville de naissance"
          value={props.gerants[props.index]["lieu-naissance"] ?? ""}
          onChange={(e) =>
            handleChange(e, "lieu-naissance", e.target.value, props.index)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom & Prénom(s) du père</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom & Prénom(s) du père"
          value={props.gerants[props.index]["nom-pere"] ?? ""}
          onChange={(e) =>
            handleChange(e, "nom-pere", e.target.value, props.index)
          }
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nom & Prénom(s) de la mère</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Nom & Prénom(s) de la mère"
          value={props.gerants[props.index]["nom-mere"] ?? ""}
          onChange={(e) =>
            handleChange(e, "nom-mere", e.target.value, props.index)
          }
        />
      </div>

      <div className="intro-y col-span-12">
            <div className="mb-5">Situation matrimoniale</div>
            <div className="flex items-center  text-gray-700 dark:text-gray-500">
              <input
                type="radio"
                className="input border mr-2 field"
                id={`gerant-horizontal-radio-celibataire-gerant-edit-${props.index}`}
                name={`associe-situation-matrimoniale-gerant`}
                value={"celibataire"}
                checked={props.gerants[props.index]["situation-matrimoniale"] === "celibataire" ??""}
                onChange={(e) =>
                  handleChange(
                    e,
                    "situation-matrimoniale",
                    e.target.value,
                    props.index
                  )
                }
              />
              <label
                className="cursor-pointer select-none  mr-3"
                htmlFor={`gerant-horizontal-radio-celibataire-gerant-edit-${props.index}`}
              >
                Célibataire
              </label>
              <input
                type="radio"
                className="input border mr-2 field"
                id={`gerant-horizontal-radio-marie-gerant-edit-${props.index}`}
                name={`associe-situation-matrimoniale`}
                value={"marie"}
                checked={props.gerants[props.index]["situation-matrimoniale"] === "marie" ??""}
                onChange={(e) =>
                  handleChange(
                    e,
                    "situation-matrimoniale",
                    e.target.value,
                    props.index
                  )
                }
              />
              <label
                className="cursor-pointer select-none"
                htmlFor={`gerant-horizontal-radio-marie-gerant-edit-${props.index}`}
              >
                Marié-e
              </label>
            </div>

          </div>
          {/* if situation matrimonial */}
          <div className={`intro-y col-span-12 ${
              props.gerants[props.index]["situation-matrimoniale"] !== undefined &&
              props.gerants[props.index]["situation-matrimoniale"] === "marie"
                  ? ""
                  : "hidden"
          }`}>
            <div className="col-span-12  mb-4">Régime matrimonial</div>
            <div className="flex flex-col sm:flex-row mb-2 col-span-12">
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                <input
                  type="radio"
                  className="input border mr-2 field"
                  id={`gerant-horizontal-radio-communaute-de-bien-gerant-edit-${props.index}`}
                  name={`associe-regime-matrimonial`}
                  value= {"Communauté de bien"}
                  checked={props.gerants[props.index]["regime-matrimonial"] === "Communauté de bien"}
                  onChange={(e) =>
                    handleChange(
                      e,
                      "regime-matrimonial",
                      e.target.value,
                      props.index
                    )
                  }
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`gerant-horizontal-radio-communaute-de-bien-gerant-edit-${props.index}`}
                >
                  Communauté de bien
                </label>
              </div>
              <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                <input
                  type="radio"
                  className="input border mr-2 field"
                  id={`gerant-horizontal-radio-separation-de-bien-gerant-edit-${props.index}`}
                  name={`associe-regime-matrimonial`}
                  value={"Séparation de bien"}
                  checked={props.gerants[props.index]["regime-matrimonial"] === "Séparation de bien"}
                  onChange={(e) =>
                    handleChange(
                      e,
                      "regime-matrimonial",
                      e.target.value,
                      props.index
                    )
                  }
                />
                <label
                  className="cursor-pointer select-none"
                  htmlFor={`gerant-horizontal-radio-separation-de-bien-gerant-edit-${props.index}`}
                >
                  Séparation de bien
                </label>
              </div>
            </div>
          </div>

    </div>
  );
}
