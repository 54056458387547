export function ProgressBar(props) {
    return (
        <div className="col-span-12 sm:d-bloc md:d-bloc px-5">
            <div className="mb-1">
                <small>Progression ( {props.value <= props.max ? props.value
                    : props.max} / {props.max} )</small>
            </div>
            <progress className="rounded-full w-full"
                      value={props.value} max={props.max}/>
        </div>
    )
}