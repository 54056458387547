import { useNavigate } from "react-router-dom"

export function MailForm(props) {
    const navigate = useNavigate();

    return (
        <form name="loginForm" id="loginForm" onSubmit={props.handleSubmit}>
            <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                Mot de passe oublié
            </h2>
            <div className="intro-x mt-8">
                <input type="email" className="intro-x login__input input input--lg 
                border border-gray-300 block" placeholder="Entrez votre addresse mail" id="email" name="email"
                value={props.email} onChange={e => props.setEmail(e.target.value)}
                disabled={props.isDisabled} required/>
            </div>
            <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                <button className="button button--lg w-full xl:w-32 text-white 
                bg-theme-1 xl:mr-3 align-top" type="submit" onClick={props.handleSubmit}
                disabled={props.isDisabled}>
                    {props.isDisabled ? "Chargement": "Valider"}
                </button>
                <button className="button button--lg w-full xl:w-40 text-gray-700 
                border border-gray-300 dark:border-dark-5 dark:text-gray-300 mt-3 xl:mt-0 align-top"
                disabled={props.isDisabled} onClick={() => navigate('/connexion')}>
                    Connectez-vous
                </button>
            </div>
        </form>
    )
}