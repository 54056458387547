import * as constants from "./constants";
export const initialState = {
    payments_loading: false,
    payments_list_loading: false,
    payments_activate_loading: false,
    payments_update_loading: false,
    data: [],
    payment:null,
    payment_error:null,
    error_lists:null,
    payments_edit_loading:false,
    payments_edit:null,
    payment_edit_error:null,
    payment_set_data:null,
}

const paymentsReducer = (state = initialState, action)=> {
    switch (action.type) {
        // Lister les paiements
        case constants.PAYMENTS_LIST_REQUESTING:
            return {
                ...state,
                payments_list_loading: true,
            };

        case constants.PAYMENTS_LIST_SUCCESS:

            return {
                ...state,
                payments_list_loading: false,
                data: action.payload
            };

        case constants.PAYMENTS_LIST_FAILED:
            return {
                ...state,
                payments_list_loading: false,
                error_lists:action.error
            };

        //     Ajout d'un paymentistrateur
        case constants.PAYMENTS_ADD_REQUESTING:
            return {
                ...state,
                payments_loading: true,
            };

        case constants.PAYMENTS_ADD_SUCCESS:

            return {
                ...state,
                payments_loading: false,
                payment: action.payload
            };

        case constants.PAYMENTS_ADD_FAILED:

            return {
                ...state,
                payments_loading: false,
                payment_error:action.error
            };

        //     Editer un paiement
        case constants.PAYMENTS_EDIT_REQUESTING:
            return {
                ...state,
                payments_edit_loading: true,
            };

        case constants.PAYMENTS_EDIT_SUCCESS:

            return {
                ...state,
                payments_edit_loading: false,
                payments_edit: action.payload
            };

        case constants.PAYMENTS_EDIT_FAILED:

            return {
                ...state,
                payments_edit_loading: false,
                payment_edit_error:action.error
            };

        case constants.PAYMENTS_SET_DATA:
            return {
                ...state,
                payment_set_data: action.payload,
            };

        default:
            return state;
    }
}
export default paymentsReducer
