export const PAYMENTS_LIST_REQUESTING = 'payments/PAYMENTS_LIST_REQUESTING';
export const PAYMENTS_LIST_SUCCESS = 'payments/PAYMENTS_LIST_SUCCESS';
export const PAYMENTS_LIST_FAILED = 'payments/PAYMENTS_LIST_FAILED';

export const PAYMENTS_ADD_REQUESTING = 'payments/PAYMENTS_ADD_REQUESTING';
export const PAYMENTS_ADD_SUCCESS = 'payments/PAYMENTS_ADD_SUCCESS';
export const PAYMENTS_ADD_FAILED = 'payments/PAYMENTS_ADD_FAILED';

export const PAYMENTS_EDIT_REQUESTING = 'payments/PAYMENTS_EDIT_REQUESTING';
export const PAYMENTS_EDIT_SUCCESS = 'payments/PAYMENTS_EDIT_SUCCESS';
export const PAYMENTS_EDIT_FAILED = 'payments/PAYMENTS_EDIT_FAILED';
export const PAYMENTS_SET_DATA = 'payments/PAYMENTS_SET_DATA';

