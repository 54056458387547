export const PAYMENT_METHODS_LIST_REQUESTING = 'admin/PAYMENT_METHODS_LIST_REQUESTING';
export const PAYMENT_METHODS_LIST_SUCCESS = 'admin/PAYMENT_METHODS_LIST_SUCCESS';
export const PAYMENT_METHODS_LIST_FAILED = 'admin/PAYMENT_METHODS_LIST_FAILED';

export const PAYMENT_METHODS_ADD_REQUESTING = 'payment-methods/PAYMENT_METHODS_ADD_REQUESTING';
export const PAYMENT_METHODS_ADD_SUCCESS = 'payment-methods/PAYMENT_METHODS_ADD_SUCCESS';
export const PAYMENT_METHODS_ADD_FAILED = 'payment-methods/PAYMENT_METHODS_ADD_FAILED';

export const PAYMENT_METHODS_EDIT_REQUESTING = 'payment-methods/PAYMENT_METHODS_EDIT_REQUESTING';
export const PAYMENT_METHODS_EDIT_SUCCESS = 'payment-methods/PAYMENT_METHODS_EDIT_SUCCESS';
export const PAYMENT_METHODS_EDIT_FAILED = 'payment-methods/PAYMENT_METHODS_EDIT_FAILED';
export const PAYMENT_METHODS_SET_DATA = 'payment-methods/PAYMENT_METHODS_SET_DATA';

