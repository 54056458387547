export function AdresseEdit(props) {
  const handleChange = (name, value) => {
    const valueCopy = { ...props.adresse };
    valueCopy[name] = value;
    props.setValue(valueCopy);
  };

    return (
      <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">Ville</div>
          <input
              type="text"
              className="input w-full border flex-1"
              placeholder="Ville"
              value={props.adresse["ville"] ?? ""}
              onChange={(e) => handleChange("ville", e.target.value)}
          />
        </div>
        {props.currency === "MAD" ? (
            <>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Adresse</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Adresse"
                    value={props.adresse["adresse"] ?? ""}
                    onChange={(e) => handleChange("adresse", e.target.value)}
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Code postal</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Code postal"
                    value={props.adresse["code-postal"] ?? ""}
                    onChange={(e) => handleChange("code-postal", e.target.value)}
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Commune</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Commune"
                    value={props.adresse["commune"] ?? ""}
                    onChange={(e) => handleChange("commune", e.target.value)}
                />
              </div>
            </>
        ) : null}
        {props.currency === "FCFA" ? (
            <>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Commune</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Commune"
                    value={props.adresse["commune"] ?? ""}
                    onChange={(e) => handleChange("commune", e.target.value)}
                />
              </div>

              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Quartier</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Quartier"
                    value={props.adresse["quartier"] ?? ""}
                    onChange={(e) => handleChange("quartier", e.target.value)}
                />
              </div>

              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de boîte postale</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Numéro boite postale"
                    value={props.adresse["numero-boite-postale"] ?? ""}
                    onChange={(e) =>
                        handleChange("numero-boite-postale", e.target.value)
                    }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Civilité du proprietaire</div>
                <select
                    type="text"
                    className="input w-full border flex-1 field"
                    onChange={(e) =>
                        handleChange("civilite-proprietaire", e.target.value)
                    }
                >
                  <option value="monsieur">Monsieur</option>
                  <option value="madame">Madame</option>
                  <option value="mademoiselle">Mademoiselle</option>
                </select>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom du propriétaire du local</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Nom proprietaire"
                    value={props.adresse["nom-proprietaire"] ?? ""}
                    onChange={(e) => handleChange("nom-proprietaire", e.target.value)}
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Prénom(s) du propriétaire du local</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Prenom proprietaire"
                    value={props.adresse["prenoms-proprietaire"] ?? ""}
                    onChange={(e) =>
                        handleChange("prenoms-proprietaire", e.target.value)
                    }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Numéro de téléphone du propriétaire du local
                </div>
                <input
                    type="tel"
                    className="input w-full border flex-1"
                    placeholder="Numéro téléphone"
                    value={props.adresse["numero-telephone-proprietaire"] ?? ""}
                    onChange={(e) =>
                        handleChange("numero-telephone-proprietaire", e.target.value)
                    }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro d'îlot</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Numéro ilot"
                    value={props.adresse["ilot"] ?? ""}
                    onChange={(e) => handleChange("ilot", e.target.value)}
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Numéro de pièces d'identité du propriétaire du local
                </div>
                <input
                    type="tel"
                    className="input w-full border flex-1"
                    placeholder="Numéro de pièces d'identité"
                    value={props.adresse["numero-piece-proprietaire"] ?? ""}
                    onChange={(e) =>
                        handleChange("numero-piece-proprietaire", e.target.value)
                    }
                />
              </div>

              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de lot</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Numéro lot"
                    value={props.adresse["lot"] ?? ""}
                    onChange={(e) => handleChange("lot", e.target.value)}
                />
              </div>

              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de parcelle</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Numéro parcelle"
                    value={props.adresse["parcelle"] ?? ""}
                    onChange={(e) => handleChange("parcelle", e.target.value)}
                />
              </div>

              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de section</div>
                <input
                    type="text"
                    className="input w-full border flex-1"
                    placeholder="Numéro section"
                    value={props.adresse["section"] ?? ""}
                    onChange={(e) => handleChange("section", e.target.value)}
                />
              </div>
            </>
        ) : null}
      </div>
    );
}