import { useState } from "react";

export function TribunalSarlStep(props) {
    const formContainer = "tribunal-form-container";
    const [tribunal, setTribunal] = useState({});

    const handleInputChange = (name, value) => {
        const tribunalCopy = 
         {...tribunal};
              
        tribunalCopy[name] = value;

        setTribunal(tribunalCopy);
    }
   
    const handleStepSubmit = e => {
        e.preventDefault();

        props.handleNextClick(e, 'tribunal', JSON.stringify(tribunal));
    }

   
    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <h4 className="intro-y col-span-12 text-lg leading-none">
            </h4>
            
          
                    <div className="intro-y col-span-12" id={`${formContainer}`}>
                        <small>Indiquez les informations relatives au Tribunal</small>
                        <div className="mb-2">DANS QUEL TRIBUNAL LES STATUTS SERONT-ILS DÉPOSÉS ?</div>
                        <div className="flex flex-col sm:flex-row mt-4 mb-2">
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2"> 
                                <input type="radio" className="input border mr-2" 
                                id={`tribunal-oui`} 
                                name={`tribunal`} value="non" 
                                onChange={e => handleInputChange('nom-tribunal', "RABAT")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`tribunal-oui`}>
                                    RABAT
                                </label> 
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                                <input type="radio" className="input border mr-2" 
                                id={`tribunal-non`} 
                                name={`tribunal`} value="oui" 
                                onChange={e => handleInputChange('nom-tribunal', "Tribunal de première instance ère instance de SALE")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`tribunal-non`}>
                                    Tribunal de première instance ère instance de SALE
                                </label> 
                            </div>
                            <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0"> 
                                <input type="radio" className="input border mr-2" 
                                id={`tribunal-horizontal-non`} 
                                name={`tribunal`} value="oui" 
                                onChange={e => handleInputChange('nom-tribunal', "Tribunal de 1ère instance de TÉMARA")}/> 
                                <label className="cursor-pointer select-none" 
                                htmlFor={`tribunal-horizontal-non`}>
                                    Tribunal de 1ère instance de TÉMARA
                                </label> 
                            </div>
                        </div>
                       
                        
                    </div>
                
            
            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300" onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}