import { Link, NavLink, useLocation } from 'react-router-dom';
import logoImg from '../assets/images/logo-poste-ci.png';
import * as Icons from 'react-feather';
import { Utils } from '../utils';

export function MobileMenu(props){
    const {pathname} = useLocation();

    const activeClassName = 'menu--active';
    const dynamicClassName = ({ isActive }) => {;
        return isActive ? `menu ${activeClassName}` : 'menu';
    }

    return (
        <div className="mobile-menu md:hidden">
            <div className="mobile-menu-bar">
                <Link to="/" className="flex mr-auto">
                    <img alt="Midone Tailwind HTML Admin Template" className="w-6" src={logoImg} />
                </Link>
                <button className='btn btn-link rounded' id="mobile-menu-toggler"> 
                    <Icons.BarChart2 className='w-8 h-8 text-white transform -rotate-90' 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}/>
                 </button>
            </div>
            <ul className="border-t bg-theme-1 py-5 hidden" id="mobile-menu-dropdown">
                <li>
                    <Link to="/" className={`menu ${pathname === '/' ? activeClassName : ''}`} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.Home /> </div>
                        <div className="menu__title"> Accueil </div>
                    </Link>
                </li>
                <li>
                    <NavLink to="/commandes" className={dynamicClassName} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.ShoppingCart /> </div>
                        <div className="menu__title"> Commandes </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/documents" className={dynamicClassName} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.Folder /> </div>
                        <div className="menu__title"> Documents </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/paramettres" className={dynamicClassName} 
                    onClick={() => Utils.Dom.toggleElement('#mobile-menu-dropdown')}>
                        <div className="menu__icon"> <Icons.Settings /> </div>
                        <div className="menu__title"> Aides </div>
                    </NavLink>
                </li>
                
            </ul>
        </div>
    )
}