import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";

export function AdresseStep(props) {
  const [addresse, setAddresse] = useState(props?.adresse);
  const handleInputChange = (name, value) => {
    const addresseCopy = { ...addresse };
    addresseCopy[name] = value;

    setAddresse(addresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "adresse", JSON.stringify(addresse));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  ">
        <strong>Quelle est l’addresse de votre société ?</strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            {" "}
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          <Alert.Link href="#">L’addresse de votre société </Alert.Link> est
          tout simplement le siège social. C’est l’addresse qui figurera sur
          tous les documents officiels de votre société. Si vous n’avez pas
          encore de bureaux, vous pouvez domicilier l’entreprise chez l’un des
          associés.
        </Alert>

        <small>
          {" "}
          Indiquez les informations relatives à l'addresse géographique de la
          société{" "}
        </small>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Addresse du siège social</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Addresse du siège social"
          value={addresse['adresse']}
          onChange={(e) => handleInputChange("adresse", e.target.value)}
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Code postal</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Code postal"
          value={addresse['numero-boite-postale']}
          onChange={(e) => handleInputChange("numero-boite-postale", e.target.value)}
        />
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Ville</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Ville"
          value={addresse['ville']}
          onChange={(e) => handleInputChange("ville", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Commune</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Commune"
          value={addresse['commune']}
          onChange={(e) => handleInputChange("commune", e.target.value)}
        />
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
