export function DemandeStepOptionItem(props) {
    return (
        <div className="intro-y">
            <div className="box px-4 py-4 mb-3 flex items-center zoom-in ">
                <input type="radio" name={props.itemName ?? ""} 
                className="overlay opacity-0" onChange={e => props.handleChoiceChange()}/>
                <div className="ml-2 mr-auto">
                    <div className="font-medium">{props.itemValue ?? ""}</div>
                </div>
                <div className="radio-circle">
                    <div className="radio-circle__outer-circle">
                        <div className="radio-circle__inner-circle"></div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}