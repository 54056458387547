import { Fragment } from 'react';
import * as Icons from 'react-feather';

export function Table(props) {
    const ACTIONS = {
        EDIT: 'edit',
        READ: 'read',
        DELETE: 'delete',
        DOWNLOAD: 'download',
    };
    const {tableAttributes, tableData, tableActions, controllers} = props;
    const {handleEditClick, handleReadClick, handleDeleteClick} = controllers;

    const renderEditButton = data => (
        <button className="flex items-center mr-3" 
        onClick={e => handleEditClick(e, data)} key={1}> 
            <Icons.Edit className="w-4 h-4 mr-1"/> Modifier 
        </button>
        );
        
    const renderReadButton = data => (
        <Fragment key={Math.random()}>
            {data.canShow ? 
                <button className=" button flex items-center bg-theme-1 
                text-white px-4" onClick={e => handleReadClick(e, data)} key={2}>
                    <Icons.Eye className="h-4"/> Voir 
                </button>
            : null}
        </Fragment>
        );
    
        const renderDownloadButton = data => (
            <a className=" button flex items-center bg-theme-1 text-white px-4" 
            href={data.url} target='_blank' rel="noreferrer" key={3}>
                <Icons.Download className="h-4"/> Télécharger 
            </a>
        );

    const renderDeleteButton = data => (
        <button className="flex items-center text-theme-6" 
        onClick={e => handleDeleteClick(e, data)}key={3}> 
            <Icons.Trash2 className="w-4 h-4 mr-1"/> Supprimer 
        </button>
        );

    const renderTableHeads = () => {
        const tableHeads = Object.keys(tableAttributes)
        .map((key, index) => {
            const regEx = new RegExp('[-_]', 'gi')
            return (
                <th className={`${tableAttributes[key].thClassName ?? ""} 
                whitespace-no-wrap`}
                key={index}>
                    {key.replace(regEx, '').toUpperCase()}
                </th>
            )
        })

        if (tableActions.length > 0) {
            tableHeads.push(<th key={9999} className="text-center 
            whitespace-no-wrap">ACTIONS</th>);
        }

        return tableHeads;
    }

    const renderTableCell = (data, attribute, index) => {
        return (<td className={tableAttributes[attribute].tdClassName ?? ""} 
            key={index}>{data}
            </td>
        )
    }

    const renderTableActionCell = data => {
        return (
            <td className="table-report__action w-56" key={999}>
                {tableActions.map((action, index) => {
                    switch (action) {
                        case ACTIONS.EDIT:
                            return renderEditButton(data);
                        case ACTIONS.READ:
                            return renderReadButton(data);
                        case ACTIONS.DELETE:
                            return renderDeleteButton(data);
                        case ACTIONS.DOWNLOAD:
                            return renderDownloadButton(data);
                        default:
                            return null;
                    }
                })}
            </td>
        )
    }

    const renderTableRow = (rowData, index) => {
        const tableCells = Object.keys(rowData).map((key, i) => {
                if (key in tableAttributes === false) return null;

                return renderTableCell(rowData[key], key, i);
            });

        tableCells.push(renderTableActionCell(rowData))

        return (<tr key={index} className="intro-x">{tableCells}</tr>);
    }
        
    return (
        <table className="table table-report -mt-2 col-span-12">
            <thead><tr>{renderTableHeads()}</tr></thead>
            <tbody>
                {tableData.map((rowData, index) => renderTableRow(rowData, index))}
            </tbody>
        </table>

    )
}