import * as constants from "./constants";

export const  articlesListRequest =()=> {
    return { type: constants.ARTICLES_LIST_REQUESTING}
}
export const articlesListSuccess=(payload)=>{
    return { type:constants.ARTICLES_LIST_SUCCESS, payload }
}
export const articlesListFailed=(error)=>{
    return { type:constants.ARTICLES_LIST_FAILED, error }
}

//Add article
export const  articlesRequest =(payload)=> {
    return { type: constants.ARTICLES_REQUESTING,payload}
}
export const articlesSuccess=(payload)=>{
    return { type:constants.ARTICLES_SUCCESS, payload }
}
export const articlesFailed=(error)=>{
    return { type:constants.ARTICLES_FAILED, error }
}

//Edit articles
export const  articlesEditRequest =(payload)=> {
    return { type: constants.ARTICLES_EDIT_REQUESTING,payload }
}
export const articlesEditSuccess=(payload)=>{
    return { type:constants.ARTICLES_EDIT_SUCCESS, payload }
}
export const articlesEditFailed=(error)=>{
    return { type:constants.ARTICLES_EDIT_FAILED, error }
}

//Detail articles
export const  articlesDetailRequest =(payload)=> {
    return { type: constants.ARTICLES_DETAIL_REQUESTING,payload }
}
export const articlesDetailSuccess=(payload)=>{
    return { type:constants.ARTICLES_DETAIL_SUCCESS, payload }
}
export const articlesDetailFailed=(error)=>{
    return { type:constants.ARTICLES_DETAIL_FAILED, error }
}