export const SASU = [
    {
        name: "denomination",
        question: "A quel nom avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "nombre-associes",
        question: "Combien d’associés êtes-vous ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "banque",
        question: "Dans quelle banque le compte sera-t-il ouvert ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "localisation-siege",
        question: "Où se situera le siège de votre entreprise ?",
        description: "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options : [
            {value: "A mon domicile", price: ""},
            {value: "Je domicilie à Legafrik", price: ""},
            {value: "Je loue un local commercial", price: ""},
            {value: "Je n'ai pas de siège", price: ""}
        ]
    },
    {
        name: "gestion-domiciliation",
        question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
        description: "Choisissez une formule",
        type: "mutiple", //"mutiple",
        options : [
            {value: "6 mois à 190 USD", price: 190},
            {value: "12 mois à 280 USD", price: 280},
            {value: "Je ne suis pas intéressé", price: ""}
        ]
    },
    {
        name: "capital-social",
        question: "Quel est le montant de votre capital social ?",
        description: "Pas de capital minimum exigé pour une SARL ou une SAS en République démocratique du Congo",
        type: "mutiple", //"mutiple",
        options : [
            {value: "1500 USD", price: ""},
            {value: "5000 USD", price: ""},
            {value: "10000 USD", price: ""},
            {value: "20000 USD", price: ""},
            {value: "Autres", price: ""},
        ]
    }
]