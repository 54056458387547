export const CLIENTS_LIST_REQUESTING = 'clients/CLIENTS_LIST_REQUESTING';
export const CLIENTS_LIST_SUCCESS = 'clients/CLIENTS_LIST_SUCCESS';
export const CLIENTS_LIST_FAILED = 'clients/CLIENTS_LIST_FAILED';
export const CLIENTS_ADD_REQUESTING = 'clients/CLIENTS_ADD_REQUESTING';
export const CLIENTS_ADD_SUCCESS = 'clients/CLIENTS_ADD_SUCCESS';
export const CLIENTS_ADD_FAILED = 'clients/CLIENTS_ADD_FAILED';
export const CLIENTS_EDIT_REQUESTING = 'clients/CLIENTS_EDIT_REQUESTING';
export const CLIENTS_EDIT_SUCCESS = 'clients/CLIENTS_EDIT_SUCCESS';
export const CLIENTS_EDIT_FAILED = 'clients/CLIENTS_EDIT_FAILED';
export const CLIENTS_SET_DATA = 'clients/CLIENTS_SET_DATA';
//clients par pays
export const CLIENTS_PAYS_LIST_REQUESTING = 'clients/CLIENTS_PAYS_LIST_REQUESTING';
export const CLIENTS_PAYS_LIST_SUCCESS = 'clients/CLIENTS_PAYS_LIST_SUCCESS';
export const CLIENTS_PAYS_LIST_FAILED = 'clients/CLIENTS_PAYS_LIST_FAILED';

