import * as constants from "./constants";

//Organizations agence list
export const  organizationsAgenceListRequest =(payload)=> {
    // console.log(JSON.stringify('payloadxxx: ',payload))

    return { type: constants.ORGANIZATIONS_AGENCE_LIST_REQUESTING,payload}
}

export const organizationsAgenceListSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_AGENCE_LIST_SUCCESS, payload }
}

export const organizationsAgenceListFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_AGENCE_LIST_FAILED, error }
}

//Organization list suborganisation
export const  organizationsSubOrganisationListRequest =()=> {
    return { type: constants.ORGANIZATIONS_SUB_ORGANISATION_LIST_REQUESTING}
}

export const organizationsSubOrganisationListSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_SUB_ORGANISATION_LIST_SUCCESS, payload }
}

export const organizationsSubOrganisationListFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_SUB_ORGANISATION_LIST_FAILED, error }
}



//Organizations create sub organisation
export const  organizationsSubOrganisationCreateRequest =(payload,setData,setOpenModal)=> {
    return { type: constants.ORGANIZATIONS_SUB_ORGANISATION_CREATE_REQUESTING,payload,setData,setOpenModal}
}

export const organizationsSubOrganisationCreateSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_SUB_ORGANISATION_CREATE_SUCCESS, payload }
}

export const organizationsSubOrganisationCreateFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_SUB_ORGANISATION_CREATE_FAILED, error }
}


//Organization list roles
export const  organizationsRoleListRequest =()=> {
    return { type: constants.ORGANIZATIONS_ROLE_LIST_REQUESTING}
}

export const organizationsRoleListSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_ROLE_LIST_SUCCESS, payload }
}

export const organizationsRoleListFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_ROLE_LIST_FAILED, error }
}

//Organizations create agence
export const  agencesCreateRequest =(payload,setData,setOpenModal,history)=> {
    return { type: constants.AGENCES_CREATE_REQUESTING,payload,setData,setOpenModal,history}
}

export const agencesCreateSuccess=(payload)=>{
    return { type:constants.AGENCES_CREATE_SUCCESS, payload }
}

export const agencesCreateFailed=(error)=>{
    return { type:constants.AGENCES_CREATE_FAILED, error }
}


//Organizations detail
export const  organizationsDetailRequest =(payload)=> {
    return { type: constants.ORGANIZATIONS_DETAIL_REQUESTING, payload}
}

export const organizationsDetailSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_DETAIL_SUCCESS, payload }
}

export const organizationsDetailFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_DETAIL_FAILED, error }
}

//Organizations user list
export const  organizationsUserListRequest =(payload)=> {
    return { type: constants.ORGANIZATIONS_USER_LIST_REQUESTING,payload}
}

export const organizationsUserListSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_USER_LIST_SUCCESS, payload }
}

export const organizationsUserListFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_USER_LIST_FAILED, error }
}

//Agence user list
export const  agencesUserListRequest =(payload)=> {
    return { type: constants.AGENCES_USER_LIST_REQUESTING,payload}
}

export const agencesUserListSuccess=(payload)=>{
    return { type:constants.AGENCES_USER_LIST_SUCCESS, payload }
}

export const agencesUserListFailed=(error)=>{
    return { type:constants.AGENCES_USER_LIST_FAILED, error }
}

//Organizations create user
export const  organizationsUserCreateRequest =(payload,setData,setOpenModal)=> {
    return { type: constants.ORGANIZATIONS_USER_CREATE_REQUESTING,payload,setData,setOpenModal}
}

export const organizationsUserCreateSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_USER_CREATE_SUCCESS, payload }
}

export const organizationsUserCreateFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_USER_CREATE_FAILED, error }
}


//Organizations edit user
export const  organizationsUserEditRequest =(id,payload,setData,setOpenModal)=> {
    return { type: constants.ORGANIZATIONS_USER_EDIT_REQUESTING,id,payload,setData,setOpenModal}
}

export const organizationsUserEditSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_USER_EDIT_SUCCESS, payload }
}

export const organizationsUserEditFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_USER_EDIT_FAILED, error }
}


//Organizations commission create
export const  organizationsCommissionCreateRequest =(payload,history)=> {
    return { type: constants.ORGANIZATIONS_COMMISSION_CREATE_REQUESTING,payload,history}
}

export const organizationsCommissionCreateSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_COMMISSION_CREATE_SUCCESS, payload }
}

export const organizationsCommissionCreateFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_COMMISSION_CREATE_FAILED, error }
}

//Organizations commission withdrawal
export const  organizationsCommissionWithdrawalRequest =(payload,history)=> {
    return { type: constants.ORGANIZATIONS_COMMISSION_WITHDRAWAL_REQUESTING,payload,history}
}

export const organizationsCommissionWithdrawalSuccess=(payload)=>{
    return { type:constants.ORGANIZATIONS_COMMISSION_WITHDRAWAL_SUCCESS, payload }
}

export const organizationsCommissionWithdrawalFailed=(error)=>{
    return { type:constants.ORGANIZATIONS_COMMISSION_WITHDRAWAL_FAILED, error }
}


//Organizations commission statistics
export const  commissionsStatisticsRequest =(payload)=> {
    return { type: constants.COMMISSIONS_STATISTICS_REQUESTING,payload}
}

export const commissionsStatisticsSuccess=(payload)=>{
    return { type:constants.COMMISSIONS_STATISTICS_SUCCESS, payload }
}

export const commissionsStatisticsFailed=(error)=>{
    return { type:constants.COMMISSIONS_STATISTICS_FAILED, error }
}

//Commission list
export const  commissionsListRequest =(payload)=> {
    return { type: constants.COMMISSIONS_LIST_REQUESTING,payload}
}

export const commissionsListSuccess=(payload)=>{
    return { type:constants.COMMISSIONS_LIST_SUCCESS, payload }
}

export const commissionsListFailed=(error)=>{
    return { type:constants.COMMISSIONS_LIST_FAILED, error }
}

//Sales list
export const  salesListRequest =(payload)=> {
    return { type: constants.SALES_LIST_REQUESTING,payload}
}

export const salesListSuccess=(payload)=>{
    return { type:constants.SALES_LIST_SUCCESS, payload }
}

export const salesListFailed=(error)=>{
    return { type:constants.SALES_LIST_FAILED, error }
}

//Logs list
export const  logsListRequest =(payload)=> {
    return { type: constants.LOGS_LIST_REQUESTING,payload}
}

export const logsListSuccess=(payload)=>{
    return { type:constants.LOGS_LIST_SUCCESS, payload }
}

export const logsListFailed=(error)=>{
    return { type:constants.LOGS_LIST_FAILED, error }
}