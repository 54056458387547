import * as constants from "./constants";
export const initialState = {
    articles_list_loading: false,
    data: [],
    articles_list_error:null,
    //Add articles
    articles:null,
    articles_loading: false,
    articles_error:null,
    //Edit articles
    edit_articles:null,
    edit_articles_loading: false,
    edit_articles_error:null,
    //Detail articles
    detail_articles:null,
    detail_articles_loading: false,
    detail_articles_error:null,
}

const articlesReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.ARTICLES_LIST_REQUESTING:
            return {
                ...state,
                articles_list_loading: true,
            };
        case constants.ARTICLES_LIST_SUCCESS:
            return {
                ...state,
                articles_list_loading: false,
                data: action.payload
            };
        case constants.ARTICLES_LIST_FAILED:
            return {
                ...state,
                articles_list_loading: false,
                articles_list_error:action.error
            };

        //Detail
        case constants.ARTICLES_DETAIL_REQUESTING:
            return {
                ...state,
                detail_articles_loading: true,
            };
        case constants.ARTICLES_DETAIL_SUCCESS:
            return {
                ...state,
                detail_articles_loading: false,
                detail_articles: action.payload
            };
        case constants.ARTICLES_DETAIL_FAILED:
            return {
                ...state,
                detail_articles_loading: false,
                detail_articles_error:action.error
            };

        //Add articles
        case constants.ARTICLES_REQUESTING:
            return {
                ...state,
                articles_loading: true,
            };
        case constants.ARTICLES_SUCCESS:
            return {
                ...state,
                articles_loading: false,
                articles: action.payload
            };
        case constants.ARTICLES_FAILED:
            return {
                ...state,
                articles_loading: false,
                articles_error:action.error
            };

        //Edit articles
        case constants.ARTICLES_EDIT_REQUESTING:
            return {
                ...state,
                edit_articles_loading: true,
            };
        case constants.ARTICLES_EDIT_SUCCESS:
            return {
                ...state,
                edit_articles_loading: false,
                edit_articles: action.payload
            };
        case constants.ARTICLES_EDIT_FAILED:
            return {
                ...state,
                edit_articles_loading: false,
                edit_articles_error:action.error
            };

        default:
            return state;
    }
}
export default articlesReducer
