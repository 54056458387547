import { useEffect, useState } from "react";
import { Utils } from "../../../utils";
import Alert from "react-bootstrap/Alert";

import * as Icons from "react-feather";

export function CapitalStep(props) {
  const capitalInt = props.capitalSocial
    ? parseInt(props.capitalSocial.toString().replaceAll(" ", ""))
    : 0;

  const [associes, setAssocies] = useState(JSON.parse(props?.associes));

  const [isCapital, setIsCapital] = useState(false);

  let pourcentApport = 0;
  let totalCapital = 0;
  const sendNewCapital = () => {
    setIsCapital(!isCapital);
  };

  const handleInputChange = (index, name, value) => {
    const associesCopy = associes.map((associe) => {
      return { ...associe };
    });
    associesCopy[index][name] = value.toString();

    setAssocies(associesCopy);
  };

  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "associes", JSON.stringify(associes));
  };

  useEffect(() => {
    setAssocies([...JSON.parse(props.associes)]);
  }, [props.associes]);
  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <strong className="intro-y col-span-12">
        Veuillez confirmer la répartition du capital, avant de continuer
      </strong>
      <div
        className="col-span-12 py-10 px-6"
        style={{ border: "1px solid lightgrey" }}
      >
        <div className="flex  sm:flex-row items-center ">
          <label className=" col-span-5 w-50  ">
            Vous avez renseigné comme Montant du capital :
          </label>

          {isCapital ? (
            <div className="col-span-3 ">
              {" "}
              <input
                type="number"
                className="input border ml-3  border flex-1"
                placeholder="Modifier le capital"
                onChange={(e) => handleInputChange("apport", e.target.value)}
              />
              <button
                className="button px-2 ml-3 mb-2 bg-theme-9 text-white"
                onClick={(e) => sendNewCapital()}
              >
                ok{" "}
              </button>
            </div>
          ) : (
            <>
              <strong> {props.capitalSocial}</strong>{" "}
              {/* <button
                className="button ml-3 px-2 mr-1 mb-2 bg-theme-9 "
                onClick={(e) => handleInputClick(isCapital)}
              >
                                            <Icons.Edit className="w-4 h-4 " />

              </button>{" "} */}
            </>
          )}
          <div></div>
        </div>
        <div className="intro-y col-span-12 overflow-auto lg:overflow-visible py-10">
          <table className="table table-report -mt-2">
            <thead>
              <tr>
                <th className="whitespace-no-wrap">Associé</th>
                <th className="whitespace-no-wrap text-center">
                  Argent apporté
                </th>
                <th className="whitespace-no-wrap text-center">% du capital</th>
              </tr>
            </thead>
            <tbody>
              {associes.map((associe, index) => {
                pourcentApport = (parseInt(associe.apport) * 100) / capitalInt;
                totalCapital += parseInt(associe.apport);
                return (
                  <tr className="intro-x" key={index}>
                    <td className="w-30">
                      {Utils.Demande.getAssocieName(associe)}
                    </td>
                    <td className="w-40">
                      <input
                        type="number"
                        className="input w-full border flex-1"
                        placeholder="Apport de l'associé"
                        value={associe["apport"] ?? ""}
                        onChange={(e) =>
                          handleInputChange(index, "apport", e.target.value)
                        }
                      />
                    </td>
                    <td className="w-30 text-center">
                      {Number.isNaN(pourcentApport)
                        ? "Non défini"
                        : pourcentApport}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div
            className={`text-danger  ${
              totalCapital !== capitalInt ? "" : "hidden"
            }`}
          >
            <Alert variant="danger">
              <Alert.Heading>
                {" "}
                <Icons.Info className="w-4 h-4 " />
              </Alert.Heading>
              <Alert.Link href="#"> Les apports </Alert.Link>
              ne sont pas identiques au capital initial . Veuillez{" "}
              <Alert.Link href="#">
                {" "}
                ajuster les valeurs des argents apportés ou le montant du
                capital{" "}
              </Alert.Link>{" "}
              pour avoir une cohérence
            </Alert>
          </div>
          <div
            className="intro-y col-span-12 flex items-center justify-center
                            sm:justify-end mt-10"
          >
            <button
              className="button w-24 justify-center block bg-gray-200 text-gray-600 
                            dark:bg-dark-1 dark:text-gray-300"
              onClick={props.handleQuestionnaireBackClick ?? null}
            >
              Revenir
            </button>
            <button
              className={`button w-24 justify-center block bg-theme-1 
            text-white ml-2  ${totalCapital !== capitalInt ? "hidden" : ""}`}
              onClick={handleStepSubmit}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
