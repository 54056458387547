import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* createStatusEtatTraitementsSaga(loginPayload) {
    const {payload,history,nextLink}=loginPayload
     const url ='admin/status-etat-traitements'
    try {
        const response = yield call(_adminsService.post,url,payload);
        yield put(constants.statusEtatTraitementsSuccess(response?.data?.etat_etape_traitements));
        yield call(history,nextLink);
    } catch (error) {
        yield put(constants.statusEtatTraitementsFailed(error));
        if(error?.data?.message==="status etat traitement exists" || error?.data?.message==="status etat traitement not found"){
            yield call(history,nextLink);
        }else{
            yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
        }
    }
}
function* editStatusEtatTraitementsSaga(loginPayload) {
    const {payload,id}=loginPayload

    const url =`admin/status-etat-traitements/${id}`

    try {
        const response = yield call(_adminsService.put,url,payload);
        yield put(constants.statusEtatTraitementsEditSuccess(response?.data?.etat_etape_traitements));
       // yield call(history,nextLink);
    } catch (error) {
        yield put(constants.statusEtatTraitementsEditFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}
export default function* statusEtatTraitementsSaga() {
    yield takeEvery(Actions.STATUS_ETAT_TRAITEMENTS_REQUESTING, createStatusEtatTraitementsSaga);
    yield takeEvery(Actions.STATUS_ETAT_TRAITEMENTS_EDIT_REQUESTING, editStatusEtatTraitementsSaga);
}
