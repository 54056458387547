import { MR } from ".";
import { Components } from "../../..";
import { Utils } from "../../../../utils";
import {variableExistsInJson} from "../../../../utils/utility";

export function QuestionnaireMaSteps(props) {
  const champs_demandes = props?.data?.useDemande?.champs_demande
    ? JSON.parse(props?.data?.useDemande?.champs_demande)
    : "{}";

  const { type_demande } = props?.data?.useDemande;
  const LIBELLE_TYPE_DEMANDE = type_demande?.libelle
    ? type_demande.libelle.split(" ")[
        type_demande.libelle.split(" ").length - 1
      ] : "SARL";
   const QuestionnaireFormsByPaysByService = MR ? MR[LIBELLE_TYPE_DEMANDE] : MR.SARL;

    const sigle =variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'sigle') ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).sigle :  {};

    const associes = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'associes')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).associes : [];

    const associeTypes = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'associeTypes')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).associeTypes : '[]';

    const gerants = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'gerants')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).gerants
        : [];

    const presidents =variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'presidents') ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).presidents :  [];

    const directeurs = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'directeurs')? JSON.parse(props?.data?.useDemande?.champs_questionnaire).directeurs :  [];

    const adresse = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'adresse')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).adresse :  {};

    const activites = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'activites')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).activites
        :  '';
    const mandat = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'mandat')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).mandat
        :  '{}';

  if (LIBELLE_TYPE_DEMANDE === "SARL") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
            <QuestionnaireFormsByPaysByService.AdresseStep
              index={2}
              step={props.data.step}
              handleNextClick={props.data.handleNextClick}
              handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
              adresse={adresse??{}}
            />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={associes??[]}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??""}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          gerants={gerants??[]}
        />

        <QuestionnaireFormsByPaysByService.MandatSarlStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          mandat={mandat??'{}'}
        />


        <QuestionnaireFormsByPaysByService.RecapStepSarlMaV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        /> 
      </div>
    );
  }
  else if (LIBELLE_TYPE_DEMANDE === "SARLU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleSarlUniStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
            <QuestionnaireFormsByPaysByService.AdresseSarlUniStep
              index={2}
              step={props.data.step}
              handleNextClick={props.data.handleNextClick}
              handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
              adresse={adresse??{}}
            />
        </Components.OptionalStep>

       
        <QuestionnaireFormsByPaysByService.AssocieTypesSarlUniStep
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={associeTypes??'[]'}
        />

        <QuestionnaireFormsByPaysByService.AssociesSarlUniStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
          associe={associes[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSarlUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??""}
        />

        <QuestionnaireFormsByPaysByService.GerantSarlUniStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          gerants={gerants??[]}
        />

        <QuestionnaireFormsByPaysByService.MandatSarlUniStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          mandat={mandat??'{}'}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlMaV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />

      </div>
    );
  }
  else if (LIBELLE_TYPE_DEMANDE === "SASU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleSasuStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
            <QuestionnaireFormsByPaysByService.AdresseSasuStep
              index={2}
              step={props.data.step}
              handleNextClick={props.data.handleNextClick}
              handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
              adresse={adresse??{}}
            />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssocieTypesSasuStep
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={associeTypes??'[]'}
        />

        <QuestionnaireFormsByPaysByService.AssociesSasuStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
          associe={associes[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSasUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??""}
        /> 

         <QuestionnaireFormsByPaysByService.PresidentSasuStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          president={presidents[0]??{}}
        /> 

        <QuestionnaireFormsByPaysByService.DirecteurSasuStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          directeur={directeurs[0]??{}}
        /> 

        <QuestionnaireFormsByPaysByService.RecapStepSasMaV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  }
  else if (LIBELLE_TYPE_DEMANDE === "SAS") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleSasStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
            <QuestionnaireFormsByPaysByService.AdresseSasStep
              index={2}
              step={props.data.step}
              handleNextClick={props.data.handleNextClick}
              handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
              adresse={adresse??{}}
            />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssociesSasStep
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={associes??[]}
        />

        <QuestionnaireFormsByPaysByService.CapitalSasStep
          index={4 }
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}/>

        <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??""}
        />     

        <QuestionnaireFormsByPaysByService.PresidentSasStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          president={presidents[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.DirecteurSasStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          directeur={directeurs[0]??{}}
        /> 
        
        <QuestionnaireFormsByPaysByService.RecapStepSasMaV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  }

  return <div>Pas de questionnaire</div>;
}
