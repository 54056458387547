import {put, call, takeEvery} from 'redux-saga/effects';
import AuthService from '../../api/services/auth';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {setLocalState} from "../../utils/localStorage";
import * as navigations from "../../utils/navigation";
import {toast} from "react-toastify";

const auth = new AuthService();

/**
 * @param loginPayload
 */
function* loginAuthSaga(loginPayload) {
     const {payload,history}=loginPayload
     const url ='admin/login'
    try {
        const response = yield call(auth.adminLogin,url, payload);
        yield put(constants.loginSuccess(response?.data));
        yield call(setLocalState, '_currentUser', response?.data?.admin);
        yield call(setLocalState, 'token', response?.data?.token);
        yield call(history,navigations.DASHBOARD);
        yield call(toast.success,"Connexion réussir avec succès")
    } catch (error) {
        yield put(constants.loginFailed(error));
        yield call(toast.error,"Votre identifiant ou mot de passe est incorrect.")
    }
}


export default function* authSaga() {
    yield takeEvery(Actions.LOGIN_REQUESTING, loginAuthSaga);
}
