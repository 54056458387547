import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import  * as ActionsModal from "../modal/actions";

const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* listPaymentMethodsSaga(loginPayload) {
     //const {payload}=loginPayload
     const url ='admin/moyen-paiements?is_private=1'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.paymentMethodsListSuccess(response?.data?.moyen_paiements));
    } catch (error) {
        yield put(constants.paymentMethodsListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* PaymentMethodsAddSaga(loginPayload) {
    const {payload}=loginPayload
    const url ='admin/moyen-paiements'

    try {
        const response = yield call(_adminsService.post,url,payload);
        yield put(constants.paymentMethodsAddSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success,"L'utilisateur a été ajouté avec succès")
    } catch (error) {
        yield put(constants.paymentMethodsAddFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* PaymentMethodsEditSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/moyen-paiements/${payload.id}`
    const send_data ={
        username: payload.username,
        email: payload?.email,
        role_id: payload?.role_id,
        profile_url: "",
    }

    try {
        const response = yield call(_adminsService.put,url,send_data);
        yield put(constants.paymentMethodsEditSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success,"L'utilisateur a été modifié avec succès")
    } catch (error) {
        yield put(constants.paymentMethodsEditFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

export default function * paymentMethodsSaga() {
    yield takeEvery(Actions.PAYMENT_METHODS_LIST_REQUESTING, listPaymentMethodsSaga);
    yield takeEvery(Actions.PAYMENT_METHODS_ADD_REQUESTING, PaymentMethodsAddSaga);
    yield takeEvery(Actions.PAYMENT_METHODS_EDIT_REQUESTING, PaymentMethodsEditSaga);
}
