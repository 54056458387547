import * as constants from "./constants";
export const initialState = {
    etape_traitements_lists_loading: false,
    data: [],
    error_etape_traitements_lists:null,
    //Etape de traitement etat
    etape_traitements_etat_lists_loading: false,
    etape_traitements_etat_lists: [],
    error_etape_traitements_etat_lists:null,
    //Etape de traitement detail
    etape_traitements_detail_loading: false,
    etape_traitements_detail: null,
    error_etape_traitements_detail:null
}
const etapeTraitementsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.ETAPE_TRAITEMENTS_LIST_REQUESTING:
            return {
                ...state,
                etape_traitements_lists_loading: true,
            };

        case constants.ETAPE_TRAITEMENTS_LIST_SUCCESS:

            return {
                ...state,
                etape_traitements_lists_loading: false,
                data: action.payload
            };

        case constants.ETAPE_TRAITEMENTS_LIST_FAILED:
            return {
                ...state,
                etape_traitements_lists_loading: false,
                error_ETAPE_TRAITEMENTS_lists:action.error
            };

            //Etape de traitement etat
        case constants.ETAPE_TRAITEMENTS_ETAT_LIST_REQUESTING:
            return {
                ...state,
                etape_traitements_etat_lists_loading: true,
            };

        case constants.ETAPE_TRAITEMENTS_ETAT_LIST_SUCCESS:

            return {
                ...state,
                etape_traitements_etat_lists_loading: false,
                etape_traitements_etat_lists: action.payload
            };

        case constants.ETAPE_TRAITEMENTS_ETAT_LIST_FAILED:
            return {
                ...state,
                etape_traitements_etat_lists_loading: false,
                error_etape_traitements_etat_lists:action.error
            };

        //Etape de traitement detail
        case constants.ETAPE_TRAITEMENTS_DETAIL_REQUESTING:
            return {
                ...state,
                etape_traitements_detail_loading: true,
            };

        case constants.ETAPE_TRAITEMENTS_DETAIL_SUCCESS:

            return {
                ...state,
                etape_traitements_detail_loading: false,
                etape_traitements_detail: action.payload
            };

        case constants.ETAPE_TRAITEMENTS_DETAIL_FAILED:
            return {
                ...state,
                etape_traitements_detail_loading: false,
                error_etape_traitements_detail:action.error
            };

        default:
            return state;
    }
}
export default etapeTraitementsReducer
