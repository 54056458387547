const styleDate = (dateString, style = "long") => {
  if (!dateString || dateString === "") return null;
  return new window.Date(dateString).toLocaleDateString("fr", {
    dateStyle: style,
  });
};

const getAllMonths = () => {
  const months = [];
  for (let i = 0; i < 12; i++) {
    const date = new window.Date(2022, i, 1);
    const monthName = date.toLocaleString("default", { month: "long" });
    let index = i + 1;
    let monthNumber = index < 10 ? parseInt("0" + index,10) : index;
    // if (index < 10) {
    //   const bt = "0" + index;
    //   console.log("bt", (bt).to);
    // }
    months.push({ monthNumber: monthNumber, monthName: monthName });
  }
  // console.log("monthNumber", months);
  return months;
};

const getYears = () => {
  let years = [];
  const currentYear = new window.Date().getFullYear();

  // Get the last year
  const lastYear = currentYear - 1;
  return (years = [...years, lastYear, currentYear]);
};
export const Date = {
  styleDate,
  getAllMonths,
  getYears,
};
