import {createStore,applyMiddleware,compose} from 'redux';
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSagas from "./sagas";
import {globalReducers} from "./reducers";

const rootReducer = globalReducers
const sagaMiddleware = createSagaMiddleware()
// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer,composeEnhancers(applyMiddleware(sagaMiddleware,logger)))
sagaMiddleware.run(rootSagas)
