export const ADMINS_LIST_REQUESTING = 'admin/ADMINS_LIST_REQUESTING';
export const ADMINS_LIST_SUCCESS = 'admin/ADMINS_LIST_SUCCESS';
export const ADMINS_LIST_FAILED = 'admin/ADMINS_LIST_FAILED';

export const ADMINS_ADD_REQUESTING = 'admin/ADMINS_ADD_REQUESTING';
export const ADMINS_ADD_SUCCESS = 'admin/ADMINS_ADD_SUCCESS';
export const ADMINS_ADD_FAILED = 'admin/ADMINS_ADD_FAILED';
export const ADMINS_EDIT_REQUESTING = 'admin/ADMINS_EDIT_REQUESTING';
export const ADMINS_EDIT_SUCCESS = 'admin/ADMINS_EDIT_SUCCESS';
export const ADMINS_EDIT_FAILED = 'admin/ADMINS_EDIT_FAILED';
export const ADMINS_SET_DATA = 'admin/ADMINS_SET_DATA';

export const COURIERS_LIST_REQUESTING = 'admins/COURIERS_LIST_REQUESTING';
export const COURIERS_LIST_SUCCESS = 'admins/COURIERS_LIST_SUCCESS';
export const COURIERS_LIST_FAILED = 'admins/COURIERS_LIST_FAILED';

export const ADMINS_PAYS_LIST_REQUESTING = 'admin/ADMINS_PAYS_LIST_REQUESTING';
export const ADMINS_PAYS_LIST_SUCCESS = 'admin/ADMINS_PAYS_LIST_SUCCESS';
export const ADMINS_PAYS_LIST_FAILED = 'admin/ADMINS_PAYS_LIST_FAILED';