import * as Icons from "react-feather";

export function Payment(props) {
  return (
    <>
      <div className="px-5 mt-10">
        <div className="font-medium text-center text-lg">Choix de Paiement</div>
      </div>
      <div className="intro-y box flex flex-col lg:flex-row mt-5">
        {props.moyen_paiements.map((moyen_paiement, index) => {
          if (!moyen_paiement.libelle.toLowerCase().includes("mobile"))
            return null;
          return (
            <div className="intro-y flex-1 px-5 py-16" key={index}>
              <Icons.CreditCard className="w-12 h-12 text-theme-1 dark:text-theme-10 mx-auto" />
              <div className="text-xl font-medium text-center mt-10">
                {moyen_paiement.libelle}
              </div>
              <div className="flex justify-center">
                <div className="relative text-3xl font-semibold mt-8 mx-auto">
                  {props.amount ?? ""}
                  <span
                    className="absolute text-base top-0 right-0 text-gray-500 
                                    -mr-4"
                    style={{ top: "-7px" }}
                  >
                    {props.currency ?? "FCFA"}
                  </span>
                </div>
              </div>
              <button
                disabled={props.isPayment}
                type="button"
                className="button button--lg block text-white bg-theme-1
                            rounded-full mx-auto mt-8"
                onClick={(e) => props.handlePaymentClick(e, moyen_paiement)}
              >
                {props.isPayment ? "Commande en cours..." : "Commander"}
              </button>
            </div>
          );
        })}
      </div>
      <div className="intro-y grid grid-cols-12 mt-10">
        <button
          className="button w-32 border border-gray-400 dark:border-dark-5
                        text-gray-600 dark:text-gray-300"
          onClick={props.handleBackClick}
        >
          Revenir
        </button>
      </div>
    </>
  );
}
