import * as constants from "./constants";

export const  etatEtapeTraitementsListRequest =()=> {
    return { type: constants.ETAT_ETAPE_TRAITEMENT_LIST_REQUESTING}
}

export const etatEtapeTraitementsListSuccess=(payload)=>{
    return { type:constants.ETAT_ETAPE_TRAITEMENT_LIST_SUCCESS, payload }
}

export const etatEtapeTraitementsListFailed=(error)=>{
    return { type:constants.ETAT_ETAPE_TRAITEMENT_LIST_FAILED, error }
}
