export function NewPasswordForm(props) {
    return (
        <form className='form' disabled={props.isDisabled ?? false}
        onSubmit={props.handleFormSubmit ?? null}>
            <div className='row'>
                <div className='col-span-12 intro-y mb-3'>
                    <div className='form-group'>
                        <label htmlFor='password'>Nouveau mot de passe</label>
                        <input className='input w-full border flex-1 bg-white' type='password'
                        id='password' name='password' placeholder='Nouveau mot de passe' 
                        value={props.password ?? ''} disabled={props.isDisabled} 
                        onChange={ e => props.setPassword(e.target.value) ?? null} required/>
                    </div>
                </div>
                <div className='col-span-12 intro-y mb-3'>
                    <div className='form-group'>
                        <label htmlFor='password_confirmation'>Confirmer le mot de passe</label>
                        <input className='input w-full border flex-1 bg-white' type='password'
                        id='password_confirmation' name='password_confirmation' placeholder='Confirmer le mot de passe' 
                        value={props.password_confirmation ?? ''} disabled={props.isDisabled} 
                        onChange={ e => props.setPassword_confirmation(e.target.value) ?? null} required/>
                    </div>
                </div>
				
                <div className='mt-4 flex justify-end intro-y'>
                    <button disabled={props.isDisabled ?? false} type='submit' 
                    className='button bg-theme-1 text-white px-5' onClick={props.handleFormSubmit}>
                        {props.isDisabled ? "Chargement..." : "Enregistrer"}
                    </button>
                </div>
            </div>
        </form>
    )
}