import * as constants from "./constants";

export const  typeDocumentsListRequest =()=> {
    return { type: constants.TYPE_DOCUMENTS_LIST_REQUESTING}
}

export const typeDocumentsListSuccess=(payload)=>{
    return { type:constants.TYPE_DOCUMENTS_LIST_SUCCESS, payload }
}

export const typeDocumentsListFailed=(error)=>{
    return { type:constants.TYPE_DOCUMENTS_LIST_FAILED, error }
}

//By type de demands
export const  documentsByTypeDemandsRequest =(payload)=> {
    return { type: constants.DOCUMENTS_BY_TYPE_DEMANDS_REQUESTING,payload}
}

export const documentsByTypeDemandsSuccess=(payload)=>{
    return { type:constants.DOCUMENTS_BY_TYPE_DEMANDS_SUCCESS, payload }
}

export const documentsByTypeDemandsFailed=(error)=>{
    return { type:constants.DOCUMENTS_BY_TYPE_DEMANDS_FAILED, error }
}
