import { useState } from "react";
import { Link } from "react-router-dom";
import { Components } from ".";
import Sarl_sarlu_sasu_sas from "./documents_a_fournir/ci/Sarl_sarlu_sasu_sas";
import Individuelle from "./documents_a_fournir/ci/Individuelle";
import {
  individuelle,
  sarl_sarlu,
  sas_sasu,
} from "./documents_a_fournir/Montant_doc";

export function AnalyticsCard(props) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  //   const [tarification, setTarification] = useState()

  const handleStateReturnChange = () => {
    setIsEditModalOpen(true);
  };
  return (
    <>
      <div className="col-span-12 sm:col-span-4 intro-y mb-5 sm:mb-0">
        <Link onClick={handleStateReturnChange}>
          <div className="report-box zoom-in">
            <div className="box p-5">
              <div className="md:text-2xl text-lg font-bold leading-8 mt-6">
                {props.name}
              </div>
              <div className="text-base text-gray-600 mt-4">
                {props.number ?? ""}
              </div>
            </div>
          </div>
        </Link>
      </div>
      {isEditModalOpen ? (
        <Components.Modal
          handleModalClose={() => setIsEditModalOpen(false)}
          handleModalValidate={() => setIsEditModalOpen(false)}
          title={props.name}
        >
          <div className="-intro-y">
            {props.name &&
            (props.name === "Création d'une SARL" ||
              props.name === "Création d'une SAS" ||
              props.name === "Création d'une SASU" ||
              props.name === "Création d'une SARLU") ? (
              <Sarl_sarlu_sasu_sas />
            ) : null}
            {props.name &&
            props.name === "Création d'une entreprise individuelle" ? (
              <Individuelle />
            ) : null}

            <div className="text-lg mt-4">
              <h2 className="border-b-2 font-bold text-theme-1  mb-3">
                Montant
              </h2>
              {props.name === "Création d'une SAS" ||
              props.name === "Création d'une SASU"
                ? sas_sasu.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-3 items-end font-medium"
                    >
                      <span className="col-span-2">{item.location} : </span>
                      <span className="col-span-1 text-center ">
                        {item.price}
                      </span>
                    </div>
                  ))
                : null}

              {props.name === "Création d'une SARL" ||
              props.name === "Création d'une SARLU"
                ? sarl_sarlu.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-3 items-end font-medium"
                    >
                      <span className="col-span-2">{item.location} : </span>
                      <span className="col-span-1 text-center ">
                        {item.price}
                      </span>
                    </div>
                  ))
                : null}

              {props.name === "Création d'une entreprise individuelle"
                ? individuelle.map((item, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-3 items-end font-medium"
                    >
                      <span className="col-span-2">{item.location} : </span>
                      <span className="col-span-1 text-center">
                        {item.price}
                      </span>
                    </div>
                  ))
                : null}
            </div>

            <div className="text-lg mt-4">
              <h2 className="border-b-2 font-bold text-theme-1  mb-3">
                Autres Informations
              </h2>
              <p>
                Vous devez être munir d'un téléphone avec whatsapp pour la
                signature des documents
              </p>
              <p>
                Veuillez nous transmettre les documents sous format numérique
              </p>
            </div>

            <div className="flex justify-end border-t-2 my-2 py-2">
              <Link
                to={props.linkTo ?? "/"}
                className="button  w-1/4 p-3 justify-center block bg-theme-1
                text-white ml-2"
              >
                Continuez
              </Link>
            </div>
          </div>
        </Components.Modal>
      ) : null}
    </>
  );
}
