import ApiService from "./api.service";


const apiUrl= new ApiService()
class AuthService {

    /**
     * Service de connexion au compte
     * @param todo
     * @returns {Promise<AxiosResponse<any>>}
     */
    adminLogin(url,data){
        return apiUrl._post(url,data).then(response=>{
            return Promise.resolve(response);
        }).catch(error=>{
            return Promise.reject(error.response);
        })
    }


}

export default AuthService
