import * as constants from "./constants";

export const initialState = {
    pays_list_loading: false,
    data: [],
    error_pays_lists:null
}

const paysReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.PAYS_LIST_REQUESTING:
            return {
                ...state,
                pays_list_loading: true,
            };

        case constants.PAYS_LIST_SUCCESS:

            return {
                ...state,
                pays_list_loading: false,
                data: action.payload
            };

        case constants.PAYS_LIST_FAILED:

            return {
                ...state,
                pays_list_loading: false,
                error_pays_lists:action.error
            };

        default:
            return state;
    }
}
export default paysReducer
