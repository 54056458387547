export const DEPOT_MARQUE = [
    {
        name: "numero-telephone",
        question: "Veuillez saisir votre numéro de téléphone",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre numéro de téléphone ", price: ""},
           
        ]
    },
    {
        name: "email",
        question: "Veuillez saisir votre adresse mail",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre adresse mail", price: ""},
           
        ]
    },
    {
        name: "nom",
        question: "Veuillez saisir votre nom",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre nom", price: ""},
           
        ]
    },
    {
        name: "prenom",
        question: "Veuillez saisir votre prénom(s)",
        description: "",
        type:  "simple",
        options : [
            // {value: "Veuillez saisir votre prénom(s)", price: ""},
           
        ]
    },
    {
        name: "protection",
        question: "Que souhaitez-vous protéger ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "nom-marque",
        question: "Quel nom envisagez-vous de déposer ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Dans quel domaine d'activité souhaitez-vous exploiter cette marque ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "BTP", price: ""},
            {value: "Evènementiel", price: ""},
            {value: "Informatiques", price: ""},
            {value: "Finance", price: ""},
            {value: "Formation", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "proprietaire",
        question: "Qui sera le propriétaire de votre marque ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Moi-même", price: ""},
            {value: "Mon entreprise", price: ""},
            {value: "Une autre personne", price: ""}
        ]
    },
    {
        name: "offre",
        question: "Choisisez l'offre dépôt de marque au Sénégal ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Dépôt de marque au Sénégal (500 000 FCFA)", price: 500_000},
        ]
    }
]