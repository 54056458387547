import { useEffect, useState } from "react";
import { Utils } from "../../../../../utils";

export function PresidentStepStatutaire(props) {
  const formContainer = "gerants-form-container";
  const associes = JSON.parse(props.associes);

  const [nombreGerant, setNombreGerant] = useState("");
  const [gerant, setGerant] = useState({});
  const [acheteurType, setAcheteurType] = useState("");
  const [capitalType, setCapitalType] = useState("");
  const [capitalChange, setCapitalChange] = useState("");

  const [gerantType, setGerantType] = useState("");

  const getAssocieName = (associe) => {
    switch (associe.type) {
      case "particulier":
        return `${associe?.nom} ${associe?.prenoms}`;
      case "societe":
        return associe["denomination"];
      default:
        return null;
    }
  };

 
  const handleInputChange = (name, value) => {
    const gerantsCopy = { ...gerant };
    gerantsCopy[name] = value;
    setGerant(gerantsCopy);
  };

  const handleTypeGerantChange = (value) => {
    // const gerantsCopy = gerants.map(gerant => {
    //     return {...gerant};
    // });
    // gerantsCopy[index] = {};
    // gerantsCopy[index]["fait-partie-des-associes"] = value;
    setGerant({});

    const formContainerElem = window.document.querySelector(
      `#${formContainer}-0`
    );
    Array.from(formContainerElem.querySelectorAll(".field")).forEach(
      (field) => (field.value = "")
    );

    setGerantType(value);
  };

  const handleTypeAcheteurChange = (value) => {
    setAcheteurType(value);
    if (value === "non") {
      acheteurType("");
    }
  };

  const handleAugmentationCapital = (value) => {
    setCapitalChange(value);
    if (value === "non") {
      setCapitalType("");
    }
  };

  const handleAugmentationCapitalType = (value) => {
    setCapitalType(value);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();

    const gerantsCopy = { ...gerant };
    setGerant(gerantsCopy);

    if (!gerant) return alert("Vous devez remplir le formulaire !");
    props.handleNextClick(e, "info-acheteur", JSON.stringify(gerantsCopy));
  };

  useEffect(() => {
    if (!nombreGerant) return;
    // setGerants(Array(parseInt(nombreGerant)).fill({}))
  }, [nombreGerant]);

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      {/* <small className="intro-y col-span-12">
        Indiquez les informations relatives aux président
      </small> */}
       <div className="intro-y col-span-12 sm:col-span-6">
        <div className="intro-y col-span-12 sm:col-span-12">
          Quelle sont les informations relatives à l’Identité du vendeur 
        </div>
        <div className="intro-y col-span-6 sm:col-span-6">
            <div className="my-2">
              Lequel des associés est il le président ?
            </div>
            <select
              type="text"
              className="input w-full border flex-1 field"
              onChange={(e) =>
                handleInputChange("fait-partie-des-associes", e.target.value)
              }
            >
              {associes.map((associe, index) => {
                return (
                  <option value={getAssocieName(associe)}>
                    {getAssocieName(associe)}
                  </option>
                );
              })}
            </select>
          </div> 
      </div>

      <div className="intro-y col-span-12" id={`${formContainer}-0`}>
        {/* <div className={`mb-2 `}>
          L’acheteur des parts sociales fait il partie des associés actuels ?
        </div>
      
        <div className="flex flex-col sm:flex-row mt-4 mb-2">
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-radio-oui-0`}
              name={`gerant-0`}
              value="non"
              onChange={(e) => handleTypeGerantChange("oui")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-radio-oui-0`}
            >
              Oui
            </label>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-non-0`}
              name={`gerant-0`}
              value="oui"
              onChange={(e) => handleTypeGerantChange("non")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-non-0`}
            >
              Non
            </label>
          </div>
        </div> */}
        <div
          className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          gerantType !== undefined && gerantType === "oui"
                            ? ""
                            : "hidden"
                        }`}
        >
          <div className="intro-y col-span-12 sm:col-span-6">
            <div className="my-2">
              Lequel des associés est il le président ?
            </div>
            <select
              type="text"
              className="input w-full border flex-1 field"
              onChange={(e) =>
                handleInputChange("fait-partie-des-associes", e.target.value)
              }
            >
              {associes.map((associe, index) => {
                return (
                  <option value={getAssocieName(associe)}>
                    {getAssocieName(associe)}
                  </option>
                );
              })}
            </select>
          </div>
          {/* <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Nom & prénom du père du président</div>
            <input
              type="text"
              className="input w-full border flex-1 field"
              placeholder="Nom"
              onChange={(e) => handleInputChange("nom-pere", e.target.value)}
            />
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">nom & prénom de la mère du président</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Prenom"
                onChange={(e) => handleInputChange("nom-mere", e.target.value)}
              />
            </div>
          </div> */}
        </div>
        {/* <div
          className={`intro-y col-span-12 
                        ${
                          gerantType !== undefined && gerantType === "non"
                            ? ""
                            : "hidden"
                        }`}
        >
          <hr />
          <div className="intro-y col-span-12 sm:col-span-12 text-lg my-4">
            Quelle est l’Identité de l’acheteur des parts sociales :
          </div>

          <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-particulier`}
                name={`associe`}
                value="particulier"
                onChange={(e) => handleTypeAcheteurChange("particulier")}
              />

              <div className="ml-2 mr-auto">
                <div className="font-medium">Un particulier</div>
              </div>

              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>
            </div>

            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-societe`}
                name={`associe`}
                value="societe"
                onChange={(e) => handleTypeAcheteurChange("societe")}
              />
              <div className="ml-2 mr-auto">
                <div className="font-medium">Une société</div>
              </div>

              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          acheteurType !== undefined &&
                          acheteurType === "particulier"
                            ? ""
                            : "hidden"
                        }`}
          >
            {acheteurType === "particulier" ? (
              <>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Civilité</div>
                  <select
                    type="text"
                    className="input w-full border flex-1 field"
                    onChange={(e) =>
                      handleInputChange("civilite-acheteur", e.target.value)
                    }
                  >
                    <option value="" className="text-gray-100">
                      Quelle est votre civilité ?
                    </option>
                    <option value="monsieur">Monsieur</option>
                    <option value="madame">Madame</option>
                    <option value="mademoiselle">Mademoiselle</option>
                  </select>
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Nom</div>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Nom"
                    onChange={(e) =>
                      handleInputChange("nom-acheteur", e.target.value)
                    }
                  />
                </div>
                <div className="intro-y col-span-12 sm:col-span-6">
                  <div className="mb-2">Prénom(s)</div>
                  <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Prénom(s)"
                    onChange={(e) =>
                      handleInputChange("prenoms-acheteur", e.target.value)
                    }
                  />
                </div>
              </>
            ) : null}
          </div>

          {acheteurType === "societe" ? (
            <div
              className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          acheteurType !== undefined &&
                          acheteurType === "societe"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Dénomination sociale</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Dénomination sociale"
                  onChange={(e) =>
                    handleInputChange("denomination-sociale", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2"> Forme sociale </div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Forme sociale"
                  onChange={(e) =>
                    handleInputChange("forme-sociale", e.target.value)
                  }
                />
              </div>
            </div>
          ) : null}
        </div> */}

        <div className="intro-y col-span-12 sm:col-span-12 my-5">
          Est-ce qu’un nouvel associé est autorisé à souscrire au moment de
          l’augmentation ?
        </div>
       
        <div className="flex flex-col sm:flex-row mt-4 mb-2 my-5">
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-radio-oui-0`}
              name={`gerant-0`}
              value="non"
              onChange={(e) => handleAugmentationCapital("oui")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-radio-oui-0`}
            >
              Oui
            </label>
          </div>
          <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
            <input
              type="radio"
              className="input border mr-2"
              id={`horizontal-non-0`}
              name={`gerant-0`}
              value="oui"
              onChange={(e) => handleAugmentationCapital("non")}
            />
            <label
              className="cursor-pointer select-none"
              htmlFor={`horizontal-non-0`}
            >
              Non
            </label>
          </div>
        </div>
        {capitalChange === "oui" ? (
          <div className="grid grid-cols-12 mx-auto max-w-md  items-center mt-4 mb-2">
            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-particulier`}
                name={`associe`}
                value="particulier"
                onChange={(e) => handleAugmentationCapitalType("particulier")}
              />

              <div className="ml-2 mr-auto">
                <div className="font-medium">Un particulier</div>
              </div>

              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>
            </div>

            <div className="box px-4 py-4 mb-3 col-span-8  items-center zoom-in ">
              <input
                type="radio"
                className="overlay opacity-0"
                id={`horizontal-radio-societe`}
                name={`associe`}
                value="societe"
                onChange={(e) => handleAugmentationCapitalType("societe")}
              />
              <div className="ml-2 mr-auto">
                <div className="font-medium">Une société</div>
              </div>

              <div className="radio-circle">
                <div className="radio-circle__outer-circle">
                  <div className="radio-circle__inner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {capitalType === "particulier" ? (
          <div
            className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          capitalType !== undefined &&
                          capitalType === "particulier"
                            ? ""
                            : "hidden"
                        }`}
          >
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Civilité du représentant légal</div>
              <select
                type="text"
                className="input w-full border flex-1 field"
                onChange={(e) =>
                  handleInputChange("civilite-acheteur", e.target.value)
                }
              >
                <option value="" className="text-gray-100">
                  Quelle est votre civilité ?
                </option>
                <option value="monsieur">Monsieur</option>
                <option value="madame">Madame</option>
                <option value="mademoiselle">Mademoiselle</option>
              </select>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nom du représentant légal</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nom"
                onChange={(e) => handleInputChange("nom", e.target.value)}
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Prénom(s) du représentant légal</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Prénom(s)"
                onChange={(e) => handleInputChange("prenoms", e.target.value)}
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Quel est le montant de l’apport du nouvel associé :
                </div>
                <input
                  type="number"
                  className="input w-full border flex-1 field"
                  placeholder="Apport du nouvel associé"
                  onChange={(e) =>
                    handleInputChange("apport-nouvel-associe", e.target.value)
                  }
                />
              </div>
          </div>
        ) : null}

        {capitalType === "societe" ? (
          <>
            <div
              className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
                        ${
                          capitalType !== undefined && capitalType === "societe"
                            ? ""
                            : "hidden"
                        }`}
            >
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Dénomination sociale</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Dénomination sociale"
                  onChange={(e) =>
                    handleInputChange("denomination-sociale", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2"> Forme sociale </div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Forme sociale"
                  onChange={(e) =>
                    handleInputChange("forme-sociale", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Civilité du représentant légal</div>
                <select
                  type="text"
                  className="input w-full border flex-1 field"
                  onChange={(e) =>
                    handleInputChange("civilite-acheteur", e.target.value)
                  }
                >
                  <option value="" className="text-gray-100">
                    Quelle est votre civilité ?
                  </option>
                  <option value="monsieur">Monsieur</option>
                  <option value="madame">Madame</option>
                  <option value="mademoiselle">Mademoiselle</option>
                </select>
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom du représentant légal</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nom"
                  onChange={(e) =>
                    handleInputChange("nom-acheteur", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Prénom(s) du représentant légal</div>
                <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Prénom(s)"
                  onChange={(e) =>
                    handleInputChange("prenoms-acheteur", e.target.value)
                  }
                />
              </div>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">
                  Quel est le montant de l’apport du nouvel associé :
                </div>
                <input
                  type="number"
                  className="input w-full border flex-1 field"
                  placeholder="Apport du nouvel associé"
                  onChange={(e) =>
                    handleInputChange("apport-nouvel-associe", e.target.value)
                  }
                />
              </div>
            </div>
          </>
        ) : null}
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
