import { put, call, takeEvery } from "redux-saga/effects";
import * as constants from "./actions";
import * as Actions from "./constants";
import { toast } from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import {AGENCES_LIST} from "../../utils/navigation";
const _adminsService = new AdminsService();

function* agencesUserListSaga(request) {
  const { payload } = request;
  let url = `organisation/agences/${payload}/users`;

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(
      constants.agencesUserListSuccess(response?.data?.organisation?.users)
    );
  } catch (error) {
    yield put(constants.agencesUserListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* organizationsAgenceListSaga(request) {
  const { payload } = request;

  let url = `organisation/list/agences/${payload?.organisation_id}?page=${payload?.page}`;

     if(payload?.name!==''){
         url+=`&name=${payload?.name}`
     }

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.organizationsAgenceListSuccess(response?.data));
  } catch (error) {
    yield put(constants.organizationsAgenceListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* organizationsSubOrganisationListSaga(request) {
  const { payload } = request;

  //let url = `organisation/list/agences/${payload?.organisation_id}?page=${payload?.page}`;
  let url =`organisation/list_sub_organisations`
/*  if(payload?.name!==''){
    url+=`&name=${payload?.name}`
  }*/

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.organizationsSubOrganisationListSuccess(response?.data));
  } catch (error) {
    yield put(constants.organizationsSubOrganisationListFailed(error));
    yield call(
        toast.error,
        "Un problème est survenu veuillez contacter le service client."
    );
  }
}



function* organizationsSubOrganisationCreateSaga(organizationsSubOrganisationCreatePayload) {
  const { payload, setData, setOpenModal } = organizationsSubOrganisationCreatePayload;
  const url = `organisation/create_sub_organisation`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(constants.organizationsSubOrganisationCreateSuccess(response?.data));
    yield call(toast.success, "La sous organisation a été enregistré avec succès");
    yield call(setOpenModal, false);

    yield call(setData, {
      name: "",
      description: "",
    });

  } catch (error) {
    yield put(constants.organizationsSubOrganisationCreateFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* organizationsRoleListSaga(request) {
  const { payload } = request;

  let url =`organisation/roles`

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.organizationsRoleListSuccess(response?.data));
  } catch (error) {
    yield put(constants.organizationsRoleListFailed(error));
    yield call(
        toast.error,
        "Un problème est survenu veuillez contacter le service client."
    );
  }
}


function* organizationsUserListSaga(request) {
  const { payload } = request;
  let url = `organisation/${payload?.organisation_id}/users?page=${payload?.page}`;

    if(payload?.username!==''){
        url+=`&username=${payload?.username}`
    }

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.organizationsUserListSuccess(response?.data));
  } catch (error) {
    yield put(constants.organizationsUserListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* organizationsDetailSaga(organizationPayload) {
  const { payload } = organizationPayload;
  const url = `organisation/${payload}`;

  try {
    const response = yield call(_adminsService.get, url);
    yield put(
      constants.organizationsDetailSuccess(response?.data?.organisation)
    );
  } catch (error) {
    yield put(constants.organizationsDetailFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* agencesCreateSaga(agencesCreatePayload) {
  const { payload, setData, setOpenModal ,history} = agencesCreatePayload;
  const url = `organisation/agence`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield call(toast.success, "L'agence a été ajouté avec succès");

    yield put(constants.agencesCreateSuccess(response?.data));

    yield call(setOpenModal, false);
    yield call(setData, {
      organisation_id: payload?.organisation_id,
      name: "",
      ville: "",
      adresse: "",
      adresse_detail: "",
      email: "",
      phone: "",
    });
    yield call(history,AGENCES_LIST);

  } catch (error) {
    yield put(constants.agencesCreateFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* organizationsUserCreateSaga(organizationsUserCreatePayload) {
  const { payload, setData, setOpenModal } = organizationsUserCreatePayload;
  const url = `organisation/user`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(constants.organizationsUserCreateSuccess(response?.data));
    yield call(toast.success, "L'utilisateur a été enregistré avec succès");
    yield call(setOpenModal, false);


    yield call(setData, {
      role_id: "",
      agence_id: "",
      username: "",
      password: "",
      user_email: "",
      user_phone: "",
    });


  } catch (error) {
    yield put(constants.organizationsUserCreateFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* organizationsUserEditSaga(organizationsUserEditPayload) {
  const { id,payload, setData, setOpenModal } = organizationsUserEditPayload;
  const url = `organisation/user/${id}`;
  try {
    const response = yield call(_adminsService.put, url, payload);
    yield put(constants.organizationsUserEditSuccess(response?.data));
    yield call(toast.success, "La modification de l'utilisateur a été effectuée avec succès");
    yield call(setOpenModal, false);
  } catch (error) {
    yield put(constants.organizationsUserEditFailed(error));
    yield call(toast.error, "Un problème est survenu veuillez contacter le service client.");
  }
}

function* organizationsCommissionCreateSaga(organizationsCommissionPayload) {
  const { payload } = organizationsCommissionPayload;
  const url = `user/demandes`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(constants.organizationsCommissionCreateSuccess(response?.data));
  } catch (error) {
    yield put(constants.organizationsCommissionCreateFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* organizationsCommissionWithdrawalSaga(
  organizationsCommissionWithdrawalPayload
) {
  const { payload } = organizationsCommissionWithdrawalPayload;
  const url = `user/demandes`;
  try {
    const response = yield call(_adminsService.post, url, payload);
    yield put(
      constants.organizationsCommissionWithdrawalSuccess(response?.data)
    );
  } catch (error) {
    yield put(constants.organizationsCommissionWithdrawalFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

//Commission list
function* commissionsListSaga(request) {
  let url = `admin/paiements/list_transactions`;
  //  let url =`admin/paiements/list_transactions?organisation_id=${payload?.organisation_id}`

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.commissionsListSuccess(response?.data));
  } catch (error) {
    yield put(constants.commissionsListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

function* commissionStatisticsSaga(commissionStatisticsPayload) {
  const { payload } = commissionStatisticsPayload;
  const url = `admin/paiements/total_transactions?organisation_id=${payload.organisation_id}`;

  try {
    const response = yield call(_adminsService.get, url, payload);
    // console.log("response", response);
    yield put(constants.commissionsStatisticsSuccess(response?.data));
  } catch (error) {
    yield put(constants.commissionsStatisticsFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

//Sales list
function* salesListSaga(request) {
  const { payload } = request;
  let url = `admin/paiements/get_demandes_with_completed_paiements?organisation_id=${payload?.organisation_id}`;

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.salesListSuccess(response?.data));
  } catch (error) {
    yield put(constants.salesListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

//Logs list
function* logsListSaga(request) {
  const { payload } = request;
  let url = `organisation/${payload}/users`;

  try {
    const response = yield call(_adminsService.geList, url);
    yield put(constants.logsListSuccess(response?.data));
  } catch (error) {
    yield put(constants.logsListFailed(error));
    yield call(
      toast.error,
      "Un problème est survenu veuillez contacter le service client."
    );
  }
}

export default function* organizationsSaga() {
  yield takeEvery(Actions.AGENCES_USER_LIST_REQUESTING, agencesUserListSaga);
  yield takeEvery(
    Actions.ORGANIZATIONS_USER_LIST_REQUESTING,
    organizationsUserListSaga
  );


  yield takeEvery(
    Actions.ORGANIZATIONS_AGENCE_LIST_REQUESTING,
    organizationsAgenceListSaga
  );


  yield takeEvery(
    Actions.ORGANIZATIONS_DETAIL_REQUESTING,
    organizationsDetailSaga
  );
  yield takeEvery(Actions.AGENCES_CREATE_REQUESTING, agencesCreateSaga);
  yield takeEvery(
    Actions.ORGANIZATIONS_USER_CREATE_REQUESTING,
    organizationsUserCreateSaga
  );
  yield takeEvery(
      Actions.ORGANIZATIONS_USER_EDIT_REQUESTING,
      organizationsUserEditSaga
  );
  yield takeEvery(
    Actions.ORGANIZATIONS_COMMISSION_CREATE_REQUESTING,
    organizationsCommissionCreateSaga
  );
  yield takeEvery(
    Actions.ORGANIZATIONS_COMMISSION_WITHDRAWAL_REQUESTING,
    organizationsCommissionWithdrawalSaga
  );

  yield takeEvery(
    Actions.COMMISSIONS_STATISTICS_REQUESTING,
    commissionStatisticsSaga
  );
  yield takeEvery(Actions.COMMISSIONS_LIST_REQUESTING, commissionsListSaga);
  yield takeEvery(Actions.ORGANIZATIONS_SUB_ORGANISATION_LIST_REQUESTING, organizationsSubOrganisationListSaga);
  yield takeEvery(Actions.ORGANIZATIONS_ROLE_LIST_REQUESTING, organizationsRoleListSaga);
  yield takeEvery(Actions.ORGANIZATIONS_SUB_ORGANISATION_CREATE_REQUESTING, organizationsSubOrganisationCreateSaga);

  yield takeEvery(Actions.SALES_LIST_REQUESTING, salesListSaga);
  yield takeEvery(Actions.LOGS_LIST_REQUESTING, logsListSaga);
}
