import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listStatusDemandsSaga(loginPayload) {
     //const {payload}=loginPayload
     const url ='admin/etat-demandes'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.statusDemandsListSuccess(response?.data?.etat_demandes));
    } catch (error) {
        yield put(constants.statusDemandsListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}


export default function* statusDemandsSaga() {
    yield takeEvery(Actions.STATUS_DEMANDS_LIST_REQUESTING, listStatusDemandsSaga);
}
