import * as constants from "./constants";

export const  etatDocumentsListRequest =()=> {
    return { type: constants.ETAT_DOCUMENTS_LIST_REQUESTING}
}
export const etatDocumentsListSuccess=(payload)=>{
    return { type:constants.ETAT_DOCUMENTS_LIST_SUCCESS, payload }
}
export const etatDocumentsListFailed=(error)=>{
    return { type:constants.ETAT_DOCUMENTS_LIST_FAILED, error }
}
