import * as constants from "./constants";

export const initialState = {
    roles_loading: false,
    roles_list_loading: false,
    roles_activate_loading: false,
    roles_update_loading: false,
    data: [],
    roles:null,
    error_lists:null
}

const rolesReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.ROLES_LIST_REQUESTING:
            return {
                ...state,
                roles_list_loading: true,
            };

        case constants.ROLES_LIST_SUCCESS:

            return {
                ...state,
                roles_list_loading: false,
                data: action.payload
            };

        case constants.ROLES_LIST_FAILED:

            return {
                ...state,
                roles_list_loading: false,
                error_lists:action.error
            };

        default:
            return state;
    }
}
export default rolesReducer
