import * as constants from "./constants";

export const initialState = {
    payment_methods_loading: false,
    payment_methods_list_loading: false,
    payment_methods_activate_loading: false,
    payment_methods_update_loading: false,
    data: [],
    payment_method:null,
    payment_method_error:null,
    error_lists:null,
    payment_methods_edit_loading:false,
    payment_methods_edit:null,
    payment_method_edit_error:null,
    payment_method_set_data:null,
}

const paymentMethodsReducer = (state = initialState, action)=> {
    switch (action.type) {
        // Lister les money de paiement
        case constants.PAYMENT_METHODS_LIST_REQUESTING:
            return {
                ...state,
                payment_methods_list_loading: true,
            };

        case constants.PAYMENT_METHODS_LIST_SUCCESS:

            return {
                ...state,
                payment_methods_list_loading: false,
                data: action.payload
            };

        case constants.PAYMENT_METHODS_LIST_FAILED:
            return {
                ...state,
                payment_methods_list_loading: false,
                error_lists:action.error
            };

        //     Ajout d'un administrateur
        case constants.PAYMENT_METHODS_ADD_REQUESTING:
            return {
                ...state,
                payment_methods_loading: true,
            };

        case constants.PAYMENT_METHODS_ADD_SUCCESS:

            return {
                ...state,
                payment_methods_loading: false,
                payment_method: action.payload
            };

        case constants.PAYMENT_METHODS_ADD_FAILED:

            return {
                ...state,
                payment_methods_loading: false,
                payment_method_error:action.error
            };

        //     Editer un administrateur
        case constants.PAYMENT_METHODS_EDIT_REQUESTING:
            return {
                ...state,
                payment_methods_edit_loading: true,
            };

        case constants.PAYMENT_METHODS_EDIT_SUCCESS:

            return {
                ...state,
                payment_methods_edit_loading: false,
                payment_methods_edit: action.payload
            };

        case constants.PAYMENT_METHODS_EDIT_FAILED:

            return {
                ...state,
                payment_methods_edit_loading: false,
                payment_method_edit_error:action.error
            };

        case constants.PAYMENT_METHODS_SET_DATA:
            return {
                ...state,
                payment_method_set_data: action.payload,
            };

        default:
            return state;
    }
}
export default paymentMethodsReducer
