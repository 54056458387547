import * as constants from "./constants";

export const  loginRequest =(payload,history)=> {
    return { type: constants.LOGIN_REQUESTING, payload ,history}
}

export const loginSuccess=(payload)=>{
    return { type:constants.LOGIN_SUCCESS, payload }
}

export const loginFailed=(error)=>{
    return { type:constants.LOGIN_FAILED, error }
}
