import * as constants from "./constants";

export const initialState = {
    status_etat_traitements_loading: false,
    status_etat_traitements:null,
    error_status_etat_traitements:null,

    //Edit etat de traitements
    status_etat_traitements_edit_loading: false,
    status_etat_traitements_edit:null,
    error_status_etat_traitements_edit:null,
    //pour avoir le detail
    status_etat_traitements_set_data:null,

}

const statusEtatTraitementsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.STATUS_ETAT_TRAITEMENTS_REQUESTING:
            return {
                ...state,
                status_etat_traitements_loading: true,
            };

        case constants.STATUS_ETAT_TRAITEMENTS_SUCCESS:

            return {
                ...state,
                status_etat_traitements_loading: false,
                status_etat_traitements: action.payload
            };

        case constants.STATUS_ETAT_TRAITEMENTS_FAILED:
            return {
                ...state,
                status_etat_traitements_loading: false,
                error_status_etat_traitements:action.error
            };

            //Edit the status traitements
        case constants.STATUS_ETAT_TRAITEMENTS_EDIT_REQUESTING:
            return {
                ...state,
                status_etat_traitements_edit_loading: true,
            };

        case constants.STATUS_ETAT_TRAITEMENTS_EDIT_SUCCESS:

            return {
                ...state,
                status_etat_traitements_edit_loading: false,
                status_etat_traitements_edit: action.payload
            };

        case constants.STATUS_ETAT_TRAITEMENTS_EDIT_FAILED:
            return {
                ...state,
                status_etat_traitements_edit_loading: false,
                error_status_etat_traitements_edit:action.error
            };

        case constants.STATUS_ETAT_TRAITEMENTS_SET_DATA:
            return {
                ...state,
                status_etat_traitements_set_data: action.payload
            };

        default:
            return state;
    }
}
export default statusEtatTraitementsReducer
