export const ETAPE_TRAITEMENTS_LIST_REQUESTING = 'etape-traitements/ETAPE_TRAITEMENTS_LIST_REQUESTING';
export const ETAPE_TRAITEMENTS_LIST_SUCCESS = 'etape-traitements/ETAPE_TRAITEMENTS_LIST_SUCCESS';
export const ETAPE_TRAITEMENTS_LIST_FAILED = 'etape-traitements/ETAPE_TRAITEMENTS_LIST_FAILED';

export const ETAPE_TRAITEMENTS_ETAT_LIST_REQUESTING = 'etape-traitements/ETAPE_TRAITEMENTS_ETAT_LIST_REQUESTING';
export const ETAPE_TRAITEMENTS_ETAT_LIST_SUCCESS = 'etape-traitements/ETAPE_TRAITEMENTS_ETAT_LIST_SUCCESS';
export const ETAPE_TRAITEMENTS_ETAT_LIST_FAILED = 'etape-traitements/ETAPE_TRAITEMENTS_LIST_FAILED';

export const ETAPE_TRAITEMENTS_DETAIL_REQUESTING = 'etape-traitements/ETAPE_TRAITEMENTS_DETAIL_REQUESTING';
export const ETAPE_TRAITEMENTS_DETAIL_SUCCESS = 'etape-traitements/ETAPE_TRAITEMENTS_DETAIL_SUCCESS';
export const ETAPE_TRAITEMENTS_DETAIL_FAILED = 'etape-traitements/ETAPE_TRAITEMENTS_DETAIL_FAILED';
