import * as constants from "./constants";

export const  rolesListRequest =()=> {
    return { type: constants.ROLES_LIST_REQUESTING}
}

export const rolesListSuccess=(payload)=>{
    return { type:constants.ROLES_LIST_SUCCESS, payload }
}

export const rolesListFailed=(error)=>{
    return { type:constants.ROLES_LIST_FAILED, error }
}
