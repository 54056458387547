import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import * as ActionsModal from "../modal/actions";
const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* listArticlesSaga(loginPayload) {
     //const {payload}=loginPayload
     const url ='admin/articles'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.articlesListSuccess(response?.data?.articles));
    } catch (error) {
        yield put(constants.articlesListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* addArticlesSaga(loginPayload) {
    const {payload}=loginPayload
    const url ='admin/articles'
    try {
        const response = yield call(_adminsService.post,url,payload);
        yield put(constants.articlesSuccess(response?.data?.articles));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success,"L'article a été ajouté avec succès")
    } catch (error) {
        yield put(constants.articlesFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* detailArticlesSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/articles/${payload}`
    try {
        const response = yield call(_adminsService.get,url);
        yield put(constants.articlesDetailSuccess(response?.data?.article));
    } catch (error) {
        yield put(constants.articlesDetailFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* editArticlesSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/articles/${payload.id}`
    const send_data={
        titre_article:payload?.titre_article,
        image_article:payload?.image_article,
        categorie_article_id:payload?.categorie_article_id,
        nom_auteur:payload?.nom_auteur,
        url_article:payload?.url_article,
        photo_auteur:payload?.photo_auteur,
        resume_article:payload?.resume_article,
        duree_article:payload?.duree_article,
    }

    try {
        const response = yield call(_adminsService.put,url,send_data);
        yield put(constants.articlesEditSuccess(response?.data?.articles));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
    } catch (error) {
        yield put(constants.articlesEditFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}
export default function* articlesSaga() {
    yield takeEvery(Actions.ARTICLES_LIST_REQUESTING, listArticlesSaga);
    yield takeEvery(Actions.ARTICLES_REQUESTING, addArticlesSaga);
    yield takeEvery(Actions.ARTICLES_EDIT_REQUESTING, editArticlesSaga);
    yield takeEvery(Actions.ARTICLES_DETAIL_REQUESTING, detailArticlesSaga);
}
