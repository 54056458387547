import { CM } from ".";
import { Components } from "../../..";
import { Utils } from "../../../../utils";
import {variableExistsInJson} from "../../../../utils/utility";

export function QuestionnaireCmSteps(props) {
  const champs_demandes = props?.data?.useDemande?.champs_demande
    ? JSON.parse(props?.data?.useDemande?.champs_demande)
    : "{}";
    const hasFormule =
    champs_demandes["localisation-siege"] !== undefined &&
    champs_demandes["localisation-siege"].includes("Je domicilie chez Legafrik"); 
  const { type_demande } = props?.data?.useDemande;

  // const codePays=props?.data?.useDemande?.pays?.code;
  // const libelle=props?.data?.useDemande?.type_demande.replace("Créer une ", "");
  // const QuestionnaireFormByPays=Components.QuestionnairesForms[codePays];

  const CODE_PAYS = props?.data?.useDemande.pays?.code ?? "";
  const LIBELLE_TYPE_DEMANDE = type_demande?.libelle
    ? type_demande.libelle.split(" ")[
        type_demande.libelle.split(" ").length - 1
      ]
    : "SARL";

  // const QuestionnaireFormByPays =
  //   Components.QuestionnairesForms[CODE_PAYS] ??
  //   Components.QuestionnairesForms.ND;
   const QuestionnaireFormsByPaysByService = CM
     ? CM[LIBELLE_TYPE_DEMANDE]
     : CM.SARL;


    const sigle =variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'sigle') ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).sigle :  {};

    const associes = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'associes')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).associes : [];

    const associeTypes = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'associeTypes')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).associeTypes : '[]';

    const gerants = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'gerants')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).gerants
        : [];

    const presidents =variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'presidents') ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).presidents :  [];

    const president =variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'president') ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).president :  {};

    const directeurs = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'directeurs')? JSON.parse(props?.data?.useDemande?.champs_questionnaire).directeurs :  [];
    const adresse = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'adresse')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).adresse :  {};

    const secretaire = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'secretaire')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).secretaire
        :  {};

    const tresorier = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'tresorier')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).tresorier
        :  {};

    const bureau = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'bureau')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).bureau :  {};

    const commissaire = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'commissaire')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).commissaire
        :  {};

    const dure = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'dure')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).dure
        :  {};

    const montant= variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'montant')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).montant
        :  {};

    const activites = variableExistsInJson(props?.data?.useDemande?.champs_questionnaire,'activites')
        ? JSON.parse(props?.data?.useDemande?.champs_questionnaire).activites
        :  '';
  

  if (LIBELLE_TYPE_DEMANDE === "SARL") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStep
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
            adresse={adresse??{}}
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={associes??[]}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStep
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??""}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          gerants={gerants??[]}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={7}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  }
  else if (LIBELLE_TYPE_DEMANDE === "SARLU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStepUni
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStepSarlUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
            adresse={adresse??{}}
          />
        </Components.OptionalStep>
       
        <QuestionnaireFormsByPaysByService.AssocieTypesSarlUni
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={associeTypes}
        />

        <QuestionnaireFormsByPaysByService.AssociesSarlUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
          associe={associes[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSarlUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??""}
        />

        <QuestionnaireFormsByPaysByService.GerantStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          gerants={gerants??[]}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSarlV2
          index={7}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  }
  else if (LIBELLE_TYPE_DEMANDE === "SASU") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStepSasUni
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
            adresse={adresse??{}}
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.AssocieTypesSasUni
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associeTypes={associeTypes??'[]'}
        />

        <QuestionnaireFormsByPaysByService.AssociesSasUni
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          capitalSocial={champs_demandes["capital-social"]}
          associeTypes={
            props.data.champs_questionnaireObject["associeTypes"] ?? "[]"
          }
          associe={associes[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSasUni
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??{}}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          president={presidents[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          directeur={directeurs[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  }
  else if (LIBELLE_TYPE_DEMANDE === "SAS") {
    return (
      <div className="grid grid-cols-12 mt-5">
        <QuestionnaireFormsByPaysByService.SigleStep
          index={1}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          nomSociete={champs_demandes["denomination"]}
          sigle={sigle??{}}
        />

        <Components.OptionalStep isOptional={(props.data.step === 2 && 
          champs_demandes['localisation-siege'].includes('Legafrik'))} callback={() => 
          props.data.handleNextClick(new Event('click'), 'adresse', '')}>
          <QuestionnaireFormsByPaysByService.AdresseStepSas
            index={2}
            step={props.data.step}
            handleNextClick={props.data.handleNextClick}
            handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
            adresse={adresse??{}}
          />
        </Components.OptionalStep>

        <QuestionnaireFormsByPaysByService.Associes
          index={3}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          nombreAssocies={champs_demandes["nombre-associes"]}
          associes={associes??[]}
        />

        <QuestionnaireFormsByPaysByService.CapitalStep
          index={4}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          capitalSocial={champs_demandes["capital-social"]}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
        />

        <QuestionnaireFormsByPaysByService.ActiviteStepSas
          index={5}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          activites={activites??""}
        />

        <QuestionnaireFormsByPaysByService.PresidentStep
          index={6}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          president={presidents[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.DirecteurStep
          index={7}
          step={props.data.step}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          associes={props.data.champs_questionnaireObject["associes"] ?? "[]"}
          directeur={directeurs[0]??{}}
        />

        <QuestionnaireFormsByPaysByService.RecapStepSasV2
          index={8}
          step={props.data.step} handleSubmit={props.data.handleSubmit}
          handleNextClick={props.data.handleNextClick}
          handleQuestionnaireBackClick={props.data.handleQuestionnaireBackClick}
          champsDemandeObject={Utils.String.parseJson(props.data.useDemande.champs_demande)}
          questionnaireObject={props.data.champs_questionnaireObject}
          editionLoading={props?.data.editionLoading}
        />
      </div>
    );
  }

  return <div>Pas de questionnaire</div>;
}
