import { useState } from "react"
import { Components } from ".";
import ApiService from "../api/services/api.service";
import {
    demandsDetailSuccess,
    demandsEditQuestionnaireFailed,
    demandsEditQuestionnaireSuccess
} from "../store/demands/actions";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
    correspondindFields,
    removeFields,
    stringifyValues, transformArrayNumberValueToStrings, transformSimpleNumberValueToStrings
} from "../utils/utility";
import {demandsSelector} from "../store/demands/selectors";
const apiUrl= new ApiService();
export function RecapEditFormQuestionnaire(props) {
    const [value, setValue] = useState(props.value);
    const [activites, setActivites] = useState(props.activites);
    const [sigle, setsigle] = useState(props.sigle);

    let { id } = useParams();
    const dispatch = useDispatch();
    const useDemands = demandsSelector(
        useSelector(state => state),
    );

    const handleSubmit = async e => {
        e.preventDefault();

        let send_data={};
        let questionnaire=props.questionnaire;

        const LIBELLE_TYPE_DEMANDE = useDemands?.demands_detail?.type_demande?.libelle
            ? useDemands?.demands_detail?.type_demande?.libelle.split(" ")[useDemands?.demands_detail?.type_demande?.libelle.split(" ").length - 1]
            : "SARL";

        const CODE_PAYS = useDemands?.demands_detail?.pays?.code ?? "";

        let fields=correspondindFields(LIBELLE_TYPE_DEMANDE,CODE_PAYS);
        let refactoring_questionnaire=removeFields(questionnaire,fields);
        let questionnairex= stringifyValues(refactoring_questionnaire);

        if (props.valueName === "infos") {
            send_data={
                ...questionnairex,
                activites:JSON.stringify(activites),
                sigle:JSON.stringify(sigle)
            };
        }else {
            send_data={
                ...questionnairex,
                activites:JSON.stringify(activites),
                sigle:JSON.stringify(sigle),
                [props.valueName]: Array.isArray(value)?JSON.stringify(transformArrayNumberValueToStrings(value)):JSON.stringify(transformSimpleNumberValueToStrings(value))
            };
        }


        const send={
            champs_questionnaire:JSON.stringify(send_data)
        };

       // console.log("champs_questionnaire",send)

        props.setIsEditModalOpen(true);

        try {
            await apiUrl._put(`admin/demandes/${id}/champs-questionnaire`,send).then(result => {
                dispatch(demandsEditQuestionnaireSuccess(result.data.demande))
                dispatch(demandsDetailSuccess(result.data.demande))
                props.setIsEditModalOpen(false);
                toast.success("La modifications du questionnaire a été modifié avec succès.")
            }).catch(error => {
                toast.error("Une erreur d'édition du questionnaire");
                props.setIsEditModalOpen(false);
                dispatch(demandsEditQuestionnaireFailed(error?.response?.data))
            });
        } catch (error) {
            props.setIsEditModalOpen(false);
            toast.error("Une erreur c'est produite lors de la verification");
        }


    }

    return (
        <>
            {props.valueName === 'associes' ?
                <Components.AssociesEdit index={props.valueArrayIndex}
                                         associes={value} setValue={setValue} />
                : null}
            {props.valueName === 'gerants' || props.valueName === 'presidents'
            || props.valueName === 'directeurs' ?
                <Components.GerantsEdit index={props.valueArrayIndex}
                                        gerants={value} setValue={setValue} />
                : null}
            {props.valueName === 'adresse' ?
                <Components.AdresseEdit adresse={value} setValue={setValue}
                                        currency={props.currency}/>
                : null}
            {props.valueName === 'infos' ?
                <Components.InfoEdit sigle={sigle} setSigle={setsigle}
                                     activites={activites} setActivites={setActivites}/>
                : null}
            <div className="intro-y col-span-12 flex items-center sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 text-gray-600
            dark:bg-dark-1 dark:text-gray-300" onClick={() => props.setIsEditModalOpen(false)}>
                    Annuler
                </button>
                <button className="button w-24 justify-center block bg-theme-1
            text-white ml-2" onClick={handleSubmit}>
                    Valider
                </button>
            </div>
        </>

    )
}