import * as constants from "./constants";
export const initialState = {
    etat_etape_traitements_lists_loading: false,
    data: [],
    error_etat_etape_traitement_lists:null
}
const etatEtapeTraitementsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.ETAT_ETAPE_TRAITEMENT_LIST_REQUESTING:
            return {
                ...state,
                etat_etape_traitements_lists_loading: true,
            };

        case constants.ETAT_ETAPE_TRAITEMENT_LIST_SUCCESS:

            return {
                ...state,
                etat_etape_traitements_lists_loading: false,
                data: action.payload
            };

        case constants.ETAT_ETAPE_TRAITEMENT_LIST_FAILED:
            return {
                ...state,
                etat_etape_traitements_lists_loading: false,
                error_etat_etape_traitement_lists:action.error
            };

        default:
            return state;
    }
}
export default etatEtapeTraitementsReducer
