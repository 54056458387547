import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import LegafrikRoutes from "./routes";
import { LightTheme, BaseProvider } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
function App() {
  const engine = new Styletron();
  return (
    <Provider store={store}>
      {/*
        <BrowserRouter>
          <Routes>
            <Route path='/connexion' element={<Views.LoginView />}/>
            <Route path='/inscription' element={<Views.RegisterView />}/>
            <Route path='*' element={<MainRoutes />}/>
          </Routes>

        </BrowserRouter>
*/}
      <BaseProvider theme={LightTheme}>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <BrowserRouter>
              <LegafrikRoutes />
            </BrowserRouter>
            <ToastContainer />
          </BaseProvider>
        </StyletronProvider>
      </BaseProvider>
    </Provider>
  );
}

export default App;
