import * as constants from "./constants";

export const  categoriesListRequest =()=> {
    return { type: constants.CATEGORIES_LIST_REQUESTING}
}
export const categoriesListSuccess=(payload)=>{
    return { type:constants.CATEGORIES_LIST_SUCCESS, payload }
}
export const categoriesListFailed=(error)=>{
    return { type:constants.CATEGORIES_LIST_FAILED, error }
}

//Add article
export const  categoriesRequest =(payload)=> {
    return { type: constants.CATEGORIES_REQUESTING,payload}
}
export const categoriesSuccess=(payload)=>{
    return { type:constants.CATEGORIES_SUCCESS, payload }
}
export const categoriesFailed=(error)=>{
    return { type:constants.CATEGORIES_FAILED, error }
}