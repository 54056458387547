import { SigleStep } from "../SigleStep";
import { AdresseOneStep } from "../AdresseOneStep";
import { Associes } from "../Associes";
import { AssociesUni } from "../AssociesUni";
import { AssocieTypes } from "../AssocieTypes";
import { CapitalStep } from "../CapitalStep";
import { ActiviteStep } from "../ActiviteStep";
import { GerantStep } from "../GerantStep";
import { PresidentStep } from "../PresidentStep";
import { DirecteurStep } from "../DirecteurStep";
import { RecapStep  } from "../RecapStep";
import { RecapStepSas } from '../RecapStepSas';
import { RecapStepSarlV2 } from "../ci/sarl/RecapStepSarlV2";
import { RecapStepSasV2 } from "../ci/sarl/RecapStepSasV2";

export const BF = {
    
        SARL : { SigleStep, AdresseStep: AdresseOneStep, Associes, CapitalStep, 
            ActiviteStep, GerantStep, RecapStepSarl: RecapStep, RecapStepSarlV2 },
        SARLU : { SigleStep, AdresseStepSarlUni: AdresseOneStep,  AssociesSarlUni: AssociesUni, 
            AssocieTypesSarlUni: AssocieTypes, CapitalStep, ActiviteStepSarlUni: ActiviteStep,
            GerantStep, RecapStepUni: RecapStep, RecapStepSarlV2
        },
        SASU : { SigleStep, AdresseStepSasUni: AdresseOneStep, AssociesSasUni: AssociesUni, 
            AssocieTypesSasUni: AssocieTypes, ActiviteStepSasUni: ActiviteStep, CapitalStep,
            PresidentStep, DirecteurStep, RecapStep:RecapStepSas, RecapStepSasV2
        },
        SAS : { SigleStep, AdresseStepSas: AdresseOneStep, Associes, ActiviteStepSas: ActiviteStep, 
            CapitalStep, PresidentStep ,DirecteurStep, RecapStep:RecapStepSas, RecapStepSasV2
        }
    
}