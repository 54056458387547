import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listTypeDemandsSaga(loginPayload) {
     const url ='admin/type-demandes'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.typeDemandsListSuccess(response?.data?.type_demandes));
    } catch (error) {
        yield put(constants.typeDemandsListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* TypeDemandsEtapeTraitementSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/type-demandes/${payload}/etape-traitements`
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.typeDemandsEtapeTraitementsListSuccess(response?.data?.etape_traitements));
    } catch (error) {
        yield put(constants.typeDemandsEtapeTraitementsListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* TypeDemandsPaysListSaga(loginPayload) {
   const {payload}=loginPayload
    const url =`pays/${payload.pays_id}/type-demandes`
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.typeDemandsPaysListSuccess(response?.data?.type_demandes));
    } catch (error) {
        yield put(constants.typeDemandsPaysListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}
export default function* typeDemandsSaga() {
    yield takeEvery(Actions.TYPE_DEMANDS_LIST_REQUESTING, listTypeDemandsSaga);
    yield takeEvery(Actions.TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_REQUESTING, TypeDemandsEtapeTraitementSaga);
    yield takeEvery(Actions.TYPE_DEMANDS_PAYS_LIST_REQUESTING, TypeDemandsPaysListSaga);
}
