import Moment from 'moment';



export function RecapStepSarl(props) {
   const demande = JSON.parse(
    props?.dataGlobal?.useDemande?.champs_demande
  );

  const associes = props?.dataGlobal?.champs_questionnaireObject?.associes
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.associes)
    : [];

    const sigle = props?.dataGlobal?.champs_questionnaireObject?.sigle
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.sigle)
    : null;


    const activites=props?.dataGlobal?.champs_questionnaireObject["activites"] ? props?.dataGlobal?.champs_questionnaireObject["activites"]: null;


  const president = props?.dataGlobal?.champs_questionnaireObject?.president
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.president)
    : null;

  const directeur = props?.dataGlobal?.champs_questionnaireObject?.directeur
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.directeur)
    : null;

             const mandat = props?.dataGlobal?.champs_questionnaireObject?.mandat
     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.mandat)
    : "{}";

  const adresse = props?.dataGlobal?.champs_questionnaireObject?.adresse
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse)
    : null;

  // const adresse2 = props?.dataGlobal?.champs_questionnaireObject?.adresse2
  // ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse2)
  // : "{}";

  const gerants = props?.dataGlobal?.champs_questionnaireObject?.gerants
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.gerants)
    : [];

  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(e, "", "");
  };

  
  

  const renderArray=(array)=>{
    if(props?.dataGlobal!=null){
        let itemData=array
        return itemData
    }
}

   const renderData = (items) => {
  if (
    props?.dataGlobal?.champs_questionnaireObject != null &&
    items != null
  ) {
    let itemData = items;
    
    const rendDatas = Object.keys(itemData).map((item_field, index) => {
      

      let attribute = `${
        item_field[0].toUpperCase() + String(item_field).substring(1)
      }`;
      attribute = attribute.replace(/_/g, " ");
      attribute = attribute.replace(/-/g, " ");
      if (
        attribute !== "Type" &&
        attribute !== "Fait partie des associes" &&
        itemData[item_field] != null
      ) {
         if (attribute === "Date naissance") {
          return (
            <div className="flex-item" key={item_field.toString()}>
              <span className="flex-item-text">Date de naissance:</span>
              <span className="flex-item-description">
                {Moment(itemData[item_field].toString().replace(/_/g, " ")).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
          );
        }
        else if (attribute === "Apport") {
          return (
            <div className="flex-item" key={item_field.toString()}>
              <span className="flex-item-text">Apport:</span>
              <span className="flex-item-description">
              {(typeof itemData[item_field] !== "object" ) ? 
                itemData[item_field].toString().replace(/_/g, " ") : itemData[item_field].toString()}MAD
              </span>
            </div>
          );
        }
        else if (attribute === "Capital sociale") {
          return (
            <div className="flex-item" key={item_field.toString()}>
              <span className="flex-item-text">Capital social:</span>
              <span className="flex-item-description">
              {(typeof itemData[item_field] !== "object" ) ? 
                itemData[item_field].toString().replace(/_/g, " ") : itemData[item_field].toString()}MAD
              </span>
            </div>
          );
        } else {
          return (
            <div className="flex-item" key={item_field.toString()}>
            <span className="flex-item-text"> {(item_field==="denomination")?"Nom de l'entreprise":
(item_field==="secteur-activite")?"Secteur d'activité":
(item_field==="nationalite")?"Nationalité":
(item_field==="denomination")?"Nom entreprise 1":
(item_field==="denomination")?"Nom entreprise 2":
(item_field==="denomination")?"Nom entreprise 3":
(item_field==="date-naissance")?"Date de naissance":(item_field==="date-reception-certificat")?"Date de réception du registre de commerce":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="date-reception-certificat")?"Date de réception du certificat":
(item_field==="gestion-facture-normalisees")?"Gestion de factures normalisées":
(item_field==="gestion-dfe")?"Gestion de DFE":
(item_field==="prenoms")?"Prénom(s)":
(item_field==="type-nationalite")?"Type de nationalité":
(item_field==="type-mandat")?"Type de mandat":
(item_field==="ville-naissance")?"Ville de naissance":
(item_field==="ville_habitation")?"Ville d'habitation":

(item_field==="prenoms")?"Prénom(s)":
(item_field==="nom-pere")?"Nom du père":
  (item_field==="nom-mere")?"Nom de la mère":
(item_field==="nombre-associes")?"Nombre d'associés":
(item_field==="entreprise-anterieur")?"Avez-vous une entreprise":
(item_field==="date-reception-registre-de-commerce")?"Date de réception du registre de commerce":
(item_field==="localisation-siege")?"Siège de l'entreprise":
(item_field==="capital-social")?"Capital social":
(item_field==="code-postale")?"Numéro de boîte postale":
(item_field==="nom-prenoms-proprietaire")?"Nom et prénom(s) du propriétaire":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="lot")?"Numéro de lot":
(item_field==="ilot")?"Numéro d'ilot":
(item_field==="section")?"Numéro de section":
(item_field==="parcelle")?"Numéro de parcelle":
(item_field==="numero-piece-identite")?"Numéro de la carte d’identité":
(item_field==="date-naissance")?"Date de naissance":
(item_field==="lieu-naissance")?"Lieu de naissance":
(item_field==="pays-naissance")?"Pays de naissance":
(item_field==="situation-matrimoniale")?"Situation matrimoniale":
(item_field==="regime-matrimonial")?"Régime matrimonial":
(item_field==="denomination")?"Nom de la société":
(item_field==="forme-sociale")?"Forme sociale":
(item_field==="capital-social")?"Capital social":
(item_field==="siege-sociale")?"Siège social":
(item_field==="numero-sociale")?"Numéro RCCM":
(item_field==="ville-immatriculation")?"Ville d'immatriculation":
(item_field==="nom-representant")?"Nom du représentant":
(item_field==="prenoms-representant")?"Prénom(s) du représentant":
(item_field==="associe-gerant")?"Associé gérant":
(item_field==="fait-partie-des-associes")?"Fait partie des associés":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="date-naissance")?"Date de naissance":
(item_field==="lieu-naissance")?"Lieu de naissance":
(item_field==="pays-naissance")?"Pays de naissance":
(item_field==="situation-matrimoniale")?"Situation matrimoniale":
(item_field==="regime-matrimonial")?"Régime matrimonial":
(item_field==="denomination")?"Nom de la société":
(item_field==="forme-sociale")?"Forme sociale":
(item_field==="capital-social")?"Capital social":
(item_field==="siege-sociale")?"Siège social":
(item_field==="numero-sociale")?"Numéro RCCM":
(item_field==="ville-immatriculation")?"Ville d'immatriculation":
(item_field==="nom-representant")?"Nom du représentant":
(item_field==="prenoms-representant")?"Prénom(s) du représentant":
(item_field==="associe-gerant")?"Associé gérant":
(item_field==="fait-partie-des-associes")?"Fait partie des associés":
(item_field==="numero-telephone")?"Numéro de téléphone":
(item_field==="date-naissance")?"Date de naissance":
(item_field==="lieu-naissance")?"Lieu de naissance":
(item_field==="pays-naissance")?"Pays de naissance":
(item_field==="situation-matrimoniale")?"Situation matrimoniale":
(item_field==="regime-matrimonial")?"Régime matrimonial":
(item_field==="denomination")?"Nom de la société":
(item_field==="denomination")?"Nom de la société":
  (item_field==="forme-sociale")?"Forme sociale":
  (item_field==="capital-social")?"Capital social":
  (item_field==="siege-sociale")?"Siège social":
  (item_field==="numero-sociale")?"Numéro RCCM":
  (item_field==="ville-immatriculation")?"Ville d'immatriculation":
  (item_field==="nom-representant")?"Nom du représentant":
  (item_field==="prenoms-representant")?"Prénom(s) du représentant":
  (item_field==="associe-gerant")?"Associé gérant":
  (item_field==="fait-partie-des-associes")?"Associé président":
  (item_field==="fait-partie-des-associes")?"Fait partie des associés":
  (item_field==="numero-telephone")?"Numéro de téléphone":
  (item_field==="date-naissance")?"Date de naissance":attribute
                }:</span>
              <span className="flex-item-description">
                {(typeof itemData[item_field] !== "object" ) ? 
                  itemData[item_field].toString().replace(/_/g, " ") : itemData[item_field].toString()}
              </span>
            </div>
          );
        }
      }
    });
    return rendDatas;
  }
};


               return (
                <div
                     className={`grid grid-cols-1 col-span-12 ${
                       props.step !== props.index ? "hidden" : ""
                     } `}
                   >
                <>
                    <div className="flex-content-column">
                        <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Demande</h3>
                            </div>
                            <div className="flex-content-item-body">
                            {renderData(demande)}

                            </div>
                        </div>

                        {props?.dataGlobal?.champs_questionnaireObject && sigle!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Sigle</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(sigle)}
                            </div>
                        </div>):null}

      
                        {props?.dataGlobal?.champs_questionnaireObject && president!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Président</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(president)}
                            </div>
                        </div>):null}
        
                        {props?.dataGlobal?.champs_questionnaireObject && directeur!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Directeur</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(directeur)}
                            </div>
                        </div>):null}
        ​
        ​
                        {props?.dataGlobal?.champs_questionnaireObject && associes.length>0 ?( <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Associés</h3>
                            </div>
        ​
                            {renderArray(associes).map((data,index)=>{
                                return (<div key={index} className="flex-content-item-body-fex">
                                    <div className="flex-content-item-body-wrapper">
                                        <div className="item-body-wrapper-header">
                                            <h6 className={'item-body-wrapper-header-title'}>Associé {index+1}:</h6>
                                        </div>
                                        <div className="flex-content-item-body-wrapper-mapper">
                                            {renderData(data)}
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>):null}
        ​
                        {props?.dataGlobal?.champs_questionnaireObject && gerants.length>0?( <div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Gérants</h3>
                            </div>
        ​
                            {renderArray(gerants).map((data,index)=>{
                                return (<div key={index} className="flex-content-item-body-fex">
                                    <div className="flex-content-item-body-wrapper">
                                        <div className="item-body-wrapper-header">
                                            <h6 className={'item-body-wrapper-header-title'}>Gérant {index+1}:</h6>
                                        </div>
                                        <div className="flex-content-item-body-wrapper-mapper">
                                            {renderData(data)}
                                        </div>
                                    </div>
                                </div>)
                            })}
                        </div>):null}

                        
                        {props?.dataGlobal?.champs_questionnaireObject && mandat!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Durée du Mandat</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(mandat)}
                            </div>
                        </div>):null}
        ​
                         {props?.dataGlobal?.champs_questionnaireObject && activites!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Activités</h3>
                            </div>
                            <div className="flex-content-item-body">
                            <div className="flex-item" >
                  <span className="flex-item-description">
                    {activites}
                  </span>
                </div>                            </div>
                        </div>):null}
        ​
                        {props?.dataGlobal?.champs_questionnaireObject && adresse!==null?(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Adresse</h3>
                            </div>
                            <div className="flex-content-item-body">
                                {renderData(adresse)}
                            </div>
                        </div>):(<div className="flex-content-item">
                            <div className="flex-content-item-header">
                                <h3>Adresse</h3>
                            </div>
                            <div className="flex-content-item-body">
                            <div className="flex-item" >
                              <span className="flex-item-description">
                              Domicilié à Legafrik
                              </span>
                            </div>
                            </div>
                        </div>)}
                    </div>
                    <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
                    
                </>
                </div>
            );


//   const nomEntreprise = JSON.parse(
//     props?.dataGlobal?.useDemande?.champs_demande
//   )["denomination"];

//   const associes = props?.dataGlobal?.champs_questionnaireObject?.associes
//     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.associes)
//     : [];

//     const president = props?.dataGlobal?.champs_questionnaireObject?.president ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.president)
//     : "{}";

//     const directeur = props?.dataGlobal?.champs_questionnaireObject?.directeur ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.directeur)
//     : "{}";

//     const adresse = props?.dataGlobal?.champs_questionnaireObject?.adresse
//     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse)
//     : "{}";

//     const pouvoir = props?.dataGlobal?.champs_questionnaireObject?.pouvoir
//     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.pouvoir)
//     : "{}";

//     const tribunal = props?.dataGlobal?.champs_questionnaireObject?.tribunal
//     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.tribunal)
//     : "{}";

//     const mandat = props?.dataGlobal?.champs_questionnaireObject?.mandat
//     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.mandat)
//     : "{}";

//     const signature = props?.dataGlobal?.champs_questionnaireObject?.signature
//     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.signature)
//     : "{}";

//     // const adresse2 = props?.dataGlobal?.champs_questionnaireObject?.adresse2
//     // ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse2)
//     // : "{}";

//     const gerants = props?.dataGlobal?.champs_questionnaireObject?.gerants
//     ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.gerants)
//     : [];

//     const handleStepSubmit = (e) => {
//       e.preventDefault();
//       props.handleSubmit(e, '', '');
  
//     };

//   
//   

//   return (
//     <div
//       className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 ${
//         props.step !== props.index ? "hidden" : ""
//       } `}
//     >
//       <h4 className="intro-y col-span-12 text-lg leading-none"></h4>

//       <div className="intro-y col-span-12">
//         <div className=" text-center sm:text-left">
//           <div className="px-5 py-5 sm:px-10 sm:py-5">
//             <div className="text-theme-1 dark:text-theme-10 font-semibold text-3xl">
//               Détail Questionnaire
//             </div>
//             <div className="mt-2">
//               Entreprise <span className="font-medium">{nomEntreprise}</span>{" "}
//             </div>
//             <div className="mt-2">
//               Numéro demande{" "}
//               <span className="font-medium">
//                 {props?.dataGlobal?.useDemande['numero_demande']}
//               </span>{" "}
//             </div>
//             <div className="mt-1">
//               Demande du { Moment( new Date(props?.dataGlobal?.useDemande['created_at']) ).format('DD/MM/YYYY') }
//             </div>
//           </div>
//           <div className="  px-5 sm:px-16  ">
//             <div className="border-b border-gray-200 dark:border-dark-5  text-lg font-medium text-theme-1 dark:text-theme-15 mt-2 pb-2 mb-3  text-2xl">
//               Sigle entreprise
//             </div>
//           </div>

//           <div className="text-gray-700 dark:text-gray-500 px-5 sm:px-16">
//             <div className="grid grid-cols-2 ">
//               <div>
//                 <div className="text-md font-medium">Sigle</div>
//                 <div className="text-md  text-theme-1 dark:text-theme-10 ">
//                   {props?.dataGlobal?.champs_questionnaireObject["sigle"]
//                     ? props?.dataGlobal?.champs_questionnaireObject["sigle"]
//                     : "Pas de sigle"}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div class={`  px-5 sm:px-16   ${
//                   adresse
//                     ? ""
//                     : "hidden"
//                 }`} >
//           <div className="border-b border-gray-200 dark:border-dark-5  text-lg font-medium text-theme-1 dark:text-theme-15 mt-2 pb-2 mb-3  text-2xl">
//             Adresse de la société
//           </div>
//         </div>

//         <div class={`  px-5 sm:px-16   ${
//                                     adresse

//                     ? ""
//                     : "hidden"
//                 }`}>
//             <div className="text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
//            <div  className="grid grid-cols-2">

//             <div className="intro-y ">
//                 <div className="text-md font-medium ">Adresse du siège social</div>
//                 <div className="text-md   text-theme-1 dark:text-theme-10 mt-2">
//                     {adresse?.adresse}
//                   </div>
               
               
//             </div>
//             <div className="intro-y ">
//                 <div className="text-md font-medium ">Code postal</div>
//                 <div className="text-md   text-theme-1 dark:text-theme-10">
//                     {adresse['code-postal']}
//                   </div>
               
//             </div>

           
//             </div>

//             <div  className="grid grid-cols-2">



// <div className="intro-y ">
//     <div className="text-md font-medium ">Ville</div>
//     <div className="text-md  text-theme-1 dark:text-theme-10 ">
//         {adresse?.ville}
//       </div>
   
   
// </div>

// <div className="intro-y ">
//     <div className="text-md font-medium ">Commune</div>
//     <div className="text-md  text-theme-1 dark:text-theme-10 ">
//         {adresse?.commune}
//       </div>
   
   
// </div>

// </div>
//             </div>

            
//         </div>

        

        

//         <div className="  px-5 sm:px-16  ">
//           <div className="border-b border-gray-200 dark:border-dark-5  text-lg font-medium text-theme-1 dark:text-theme-15 mt-2 pb-2 mb-3  text-2xl">
//             Associé entreprise
//           </div>
//         </div>

//         {associes?.map((associe,index)=>{

//           return (<div className=" px-5 sm:px-16">
//          <div className="text-gray-700 dark:text-gray-500 pt-2">
//                 <div className="grid grid-cols-2 ">
//                   <div className="text-red-700  mr-2 mt-2 sm:mt-0">
//                     <div className="text-md font-medium">
//                       Associé Entreprise {index + 1}
//                     </div>
//                     <div
//                       class={`text-md font-medium text-theme-1 dark:text-theme-10  ${
//                         associe?.type === "moi-meme" ? "" : "hidden"
//                       }`}
//                     >
//                       Je suis moi même l'associé
//                     </div>
//                     <div
//                       class={`text-lg font-medium text-theme-1 dark:text-theme-10  ${
//                         associe?.type === "particulier" ? "" : "hidden"
//                       }`}
//                     >
//                       L'associé est un particulier
//                     </div>
//                     <div
//                       class={`text-lg font-medium text-theme-1 dark:text-theme-10  ${
//                         associe?.type === "société" ? "" : "hidden"
//                       }`}
//                     >
//                       L'associé est une société
//                     </div>
//                   </div>
//                 </div>
//               </div>
//           <div
//             className={`
//                 ${
//                   associe?.type === "moi-meme" ||
//                   associe?.type === "particulier"
//                     ? ""
//                     : "hidden"
//                 }`}
//           >
          
//            {associe?.type === "particulier" ? (
//                   <>
//                     <div className="text-gray-700 dark:text-gray-500 pt-2">
//                       <div className="grid grid-cols-2 ">
//                         <div className="intro-y ">
//                           <div className="text-md font-medium">Nom</div>
//                           <div className="text-md  text-theme-1 dark:text-theme-10">
//                             {associe?.nom}
//                           </div>
//                         </div>
//                         <div className="intro-y ">
//                           <div className="text-md font-medium">Prénom(s)</div>
//                           <div className="text-md  text-theme-1 dark:text-theme-10">
//                             {associe?.prenoms}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </>
//                 ) : null}
//            <div className="text-gray-700 dark:text-gray-500 pt-2">
//                   <div className="grid grid-cols-2 ">
//                     <div className="intro-y col-span-12 sm:col-span-8">
//                       <div className="text-md font-medium">
//                         Numéro de la carte d’identité
//                       </div>
//                       <div className="text-md  text-theme-1 dark:text-theme-10">
//                         {associe["numero-piece-identite"]}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
           
//                 <div className="text-gray-700 dark:text-gray-500 pt-2">
//                   <div className="grid grid-cols-2 ">
//                     <div className="intro-y ">
//                       <div className="text-md font-medium">
//                         Date de naissance
//                       </div>
//                       <div className="text-md  text-theme-1 dark:text-theme-10 ">
//                         {Moment(new Date(associe["date-naissance"])).format(
//                           "DD/MM/YYYY"
//                         )}
//                       </div>
//                     </div>
//                     <div className="intro-y ">
//                       <div className="text-md font-medium">
//                         Lieu de naissance
//                       </div>
//                       <div className="text-md  text-theme-1 dark:text-theme-10 ">
//                         {associe["lieu-naissance"]}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//             <div className="intro-y col-span-12 sm:col-span-2">
//               <div className="text-md font-medium">Pays de naissance</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//                 {associe['pays-naissance']}
//               </div>
//             </div>
          
            
         
          
          
              
//               <div className="intro-y col-span-12 sm:col-span-2">
//               <div className="text-md font-medium">Adresse personnelle</div>
          
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//                 {associe?.adresse}
//               </div>
//             </div>

//             <div className="intro-y col-span-12 sm:col-span-8">
//               <div className="text-md font-medium">Nationalité</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//                 {associe?.nationalite}
//               </div>
//             </div>
            

//             {associe?.type === "particulier" ? (
              
//               <div className="intro-y col-span-12 sm:col-span-2">
//               <div className="text-md font-medium">Adresse à l’étranger</div>
          
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//                 {associe["adresse-etranger"]}
//               </div>
//             </div>
            
            
//           ) : null}




            
                      
//             <div className="intro-y col-span-12 sm:col-span-2">
//               <div className="text-md font-medium">Cet associé apporte</div>
          
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//                 {associe?.apport} MDA
//               </div>
//             </div>
                    
                    
                 
//             {/* <div className="intro-y col-span-12 sm:col-span-6">
//                         <div className="mb-2">Combien {associe === 'moi-meme' ? 
//                         "apportez-vous" : "cet associé apporte"} à la société ?</div>
//                         <input type="text" className="input w-full border flex-1 field"
//                         placeholder="Combien apportez-vous à la société en MAD?" onChange={e => handleInputChange(
//                              'apport', e.target.value)}/>
//                     </div> */}
//           </div>
//           <div
//             className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
//                 ${associe?.type === "societe" ? "" : "hidden"}`}
//           >
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Quel est le nom de la société</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//                {associe['denomination']}  
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Quelle est sa forme sociale</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['forme-sociale']} 
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Montant du capital social</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['capital-social']} 
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Adresse du siège social</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['siege-sociale']}  
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Numéro d’immatriculation RCS</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['numero-sociale']} 
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Monnaie</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['monnaie']} 
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="mb-2">Ville d’immatriculation</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['ville-immatriculation']} 
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-2">
//               <div className="text-md font-medium">Cet associé apporte</div>
          
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//                 {associe?.apport} MDA
//               </div>
//             </div>
//             <div className="col-span-12">
//               Représentant Légal de la société associé
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Nom</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['nom-representant']} 
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Prénom</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['prenoms-representant']}  
//               </div>
//             </div>
//             <div className="intro-y col-span-12 sm:col-span-6">
//               <div className="text-md font-medium">Qualité</div>
//               <div className="text-md text-theme-1 dark:text-theme-10 mt-2">
//               {associe['qualite-representant']} 
//               </div>
//             </div>
//           </div>
          
          
//           <div className="flex flex-col lg:flex-row px-5 sm:px-20  pb-5 sm:pb-5">
           
//           </div>
//           </div>)



//         })}

       
//         <div className="px-5 sm:px-16 py-5 sm:py-10">
//          <div className="text-lg font-medium text-theme-1 dark:text-theme-15 mt-2 mb-3 text-2xl">
//             Gérant
//           </div> 

//           {gerants?.map((gerant,index)=>{

// return (<div className=" border-t border-gray-200 dark:border-dark-5 overflow-x-auto">
// <div
//   className={`grid grid-cols-2 ${
//     gerant['fait-partie-des-associes']==="oui"
//       ?"" 
//       :"hidden" 
//   } `}
// >
// <div className="intro-y col-span-12 sm:col-span-6">
//                     <div className="text-red-700 mb-2 mt-2">
//                       L'associé {index + 1} est un gérant
//                     </div>

//                    <div className="intro-y col-span-12 sm:col-span-6">
//                       <div className="text-md font-medium">Nom & Prénom(s) de l'associé</div>
//                       <div className="text-md font-medium text-theme-1 dark:text-theme-10 mt-2">
//                         {gerant["associe-gerant"]}
//                       </div>
//                     </div>
//                   </div>
// </div>
// <div
//   className={`  ${
//     gerant['fait-partie-des-associes']==="non"
//       ? ""
//       : "hidden"
//   }
//         `}
// >
// <div className="intro-y col-span-12 sm:col-span-12">
//                     <div className="text-red-700 mb-2 mt-2">
//                       L'associé {index + 1} n'est pas un gérant
//                     </div>
//                   </div>

//                   <div className="text-gray-700 dark:text-gray-500 pt-2">
//                   <div className="grid grid-cols-2 ">

//                   <div className="intro-y ">
//                     <div className="text-md font-medium">Nom</div>
//                     <div className="text-md  text-theme-1 dark:text-theme-10">
//                       {gerant?.nom}
//                     </div>
//                   </div>
//                   <div className="intro-y ">
//                     <div className="text-md font-medium">Prénom(s)</div>
//                     <div className="text-md text-theme-1 dark:text-theme-10">
//                       {gerant?.prenoms}
//                     </div>
//                   </div>
//                   </div>
//                   </div>
//                   <div className="text-gray-700 dark:text-gray-500 pt-2">
//                   <div className="grid grid-cols-2 ">
//                   <div className="intro-y ">
//                     <div className="text-md font-medium">Numéro de téléphone</div>
//                     <div className="text-md text-theme-1 dark:text-theme-10">
//                       {gerant["numero-telephone"]}
//                     </div>
//                   </div>
//                   <div className="intro-y ">
//                     <div className="text-md font-medium">Date de naissance</div>
//                     <div className="text-md text-theme-1 dark:text-theme-10 ">
//                       {Moment(new Date(gerant["date-naissance"])).format(
//                         "DD/MM/YYYY"
//                       )}
//                     </div>
//                   </div>
//                   </div>
//                   </div>
//                   <div className="text-gray-700 dark:text-gray-500 pt-2">
//                   <div className="grid grid-cols-2 ">
//                   <div className="intro-y col-span-12 sm:col-span-6">
//                     <div className="text-md font-medium">Lieu de naissance</div>
//                     <div className="text-md  text-theme-1 dark:text-theme-10 ">
//                       {gerant["lieu-naissance"]}
//                     </div>
//                   </div>
//                   {/* <div className="intro-y col-span-12 sm:col-span-6">
//                     <div className="text-md font-medium">Nom & Prénom(s) du père du gérant</div>
//                     <div className="text-md  text-theme-1 dark:text-theme-10 ">
//                       {gerant["nom-pere"]}
//                     </div>
//                   </div> */}
//                   </div>
//                   </div>
//   {/* <div className="intro-y col-span-12 sm:col-span-6">
//     <div className="text-md font-medium">Nom & Prénom(s) de la mère du gérant</div>
//     <div className="text-md  text-theme-1 dark:text-theme-10">
//       {
//         gerant
//         ['nom-mere']
//       }
//     </div>
//   </div> */}
// </div>
// </div>)



// })}

//         </div>

//         <div class={`  px-5 sm:px-16   ${
//                   mandat
//                     ? ""
//                     : "hidden"
//                 }`} >
//           <div className="border-b border-gray-200 dark:border-dark-5  text-lg font-medium text-theme-1 dark:text-theme-15 mt-2 pb-2 mb-3  text-2xl">
//           La durée du mandat du gérant
//           </div>
//         </div>

//         <div class={`  px-5 sm:px-16   ${
//                                     mandat

//                     ? ""
//                     : "hidden"
//                 }`}>
//             <div className="text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
//            <div  className="grid grid-cols-2">

//             <div className="intro-y ">
//                 <div className="text-md font-medium">Mandat</div>
//                 <div className="text-md font-medium text-theme-1 dark:text-theme-10 mt-2">
//                     {mandat['type-mandat']}
//                   </div>
               
               
//             </div>

//             <div className="intro-y ">
//                 <div className="text-md font-medium">Limite du mandat</div>
//                 <div className="text-md font-medium text-theme-1 dark:text-theme-10 mt-2">
//                     {mandat['mandat-nom']}
//                   </div>
               
               
//             </div>
        
//             </div>
            

//             </div>
//         </div>  

       

      

      


       

       

       
//       </div>

//       <div
//         className="intro-y col-span-12 flex items-center justify-center
//                 sm:justify-end mt-10"
//       >
//         <button
//           className="button w-24 justify-center block bg-gray-200 text-gray-600 
//                 dark:bg-dark-1 dark:text-gray-300"
//           onClick={props.handleBackClick ?? null}
//         >
//           Revenir
//         </button>
//         <button
//           className="button w-24 justify-center block bg-theme-1 
//                 text-white ml-2"
//                 onClick={handleStepSubmit}
//                 >
//           Suivant
//         </button>
//       </div>
//     </div>
//   );
}
