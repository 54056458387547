import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Components } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { Hooks } from "../../hooks";
import { Data } from "../../data";
import { demandsSelector } from "../../store/demands/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  demandsDetailFailed,
  demandsDetailSuccess,
} from "../../store/demands/actions";
import { toast } from "react-toastify";
import ApiService from "../../api/services/api.service";
import { paysListRequest } from "../../store/pays/actions";
import { paymentMethodsListRequest } from "../../store/payment-methods/actions";
const apiUrl = new ApiService();
export function EditLeads(props) {
  const abortController = useMemo(() => new AbortController(), []);
  const dispatch = useDispatch();
  const { ChampsDemande } = useMemo(() => Data, []);
  const { id } = useParams();
  const navigate = useNavigate();
  const [pays, setPays] = useState("");
  const [typeDemande, setTypeDemande] = useState("");
  const [champsDemandeData, setChampsDemandeData] = useState([]);
  const [champsDemandeObject, setChampsDemandeObject] = useState({});
  const [mapDataObject, setMapDataObject] = useState({});
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [step, setStep] = useState(0);

  const useDemande = Hooks.useDemande();
  const usePaiement = Hooks.usePaiement();
  const [domiciliationData, setDomiciliationData] = useState({});
  const [formationData, setFormationData] = useState({});

  //for me
  const useDemands = demandsSelector(useSelector((state) => state));
  const handleNextClick = (e, name, value) => {
    e.preventDefault();
    const champsDemandeObjectCopy = { ...champsDemandeObject };
    champsDemandeObjectCopy[name] = value;
    setChampsDemandeObject(champsDemandeObjectCopy);
    setStep(step + 1);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    if (step === 0 || useDemande.isDisabled) return;
    setStep(step - 1);
  };

  const handleValidateClick = async (e) => {
    try {
      setIsLoadingEdit(true);

      if (pays.libelle === "Maroc") {
        let denomination = [];
        denomination.push(champsDemandeObject["nom-entreprise-un"]);
        denomination.push(champsDemandeObject["nom-entreprise-deux"]);
        denomination.push(champsDemandeObject["nom-entreprise-trois"]);
        champsDemandeObject["denomination"] = denomination;
        delete champsDemandeObject["nom-entreprise-un"];
        delete champsDemandeObject["nom-entreprise-deux"];
        delete champsDemandeObject["nom-entreprise-trois"];
      }

      const payload = {
        type_demande_id: useDemands?.demands_detail?.type_demande_id,
        dossier_id: useDemands?.demands_detail?.dossier_id,
        pays_id: useDemands?.demands_detail?.pays_id,
        champs_demande: JSON.stringify(champsDemandeObject),
        etat_demande_id: useDemands?.demands_detail?.etat_demande_id,
        etape_traitement_id: useDemands?.demands_detail?.etape_traitement_id,
        numero_demande: useDemands?.demands_detail?.numero_demande,
      };

      await apiUrl
        ._put(`admin/demandes/${id}`, payload)
        .then((result) => {
          toast.success("La demande a été modifiée avec succès.");
          setIsLoadingEdit(false);
          navigate(`/leads/${id}/detail`);
        })
        .catch((error) => {
          setIsLoadingEdit(false);
          toast.error("Une erreur c'est produite lors de la verification");
        });
    } catch (error) {}
  };

  const initialize = useCallback(async () => {
    try {
      await apiUrl
        ._get(`admin/demandes/${id}`)
        .then((result) => {
          setPays(result?.data?.demande?.pays);
          setMapDataObject(JSON.parse(result?.data?.demande?.champs_demande));
          setTypeDemande(result?.data.demande?.type_demande);
          dispatch(demandsDetailSuccess(result.data.demande));
        })
        .catch((error) => {
          dispatch(demandsDetailFailed(error?.response?.data));
          toast.error("Une erreur c'est produite lors de la verification");
        });
    } catch (error) {
      if ("messages" in error) return;
      //toast
    }
  }, [abortController, id]);

  useEffect(() => {
    dispatch(paysListRequest());
    dispatch(paymentMethodsListRequest());
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const localisationSiege = champsDemandeObject["localisation-siege"];
    const sectuerActivite = champsDemandeObject["secteur-activite"];

    if (
      sectuerActivite !== undefined &&
      !sectuerActivite.includes("formation")
    ) {
      champsDemandeData.forEach((data, index) => {
        if (data.name !== "cabinet-formation") return;
        setFormationData({ index, object: data });
      });
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });
      const formationIndex = champsDemandeDataCopy.findIndex(
        (data) => data.name === "cabinet-formation"
      );

      if (formationIndex < 0) return;

      champsDemandeDataCopy.splice(formationIndex, 1);
      setChampsDemandeData(champsDemandeDataCopy);
    } else {
      const formationDataIndex = formationData.index;

      if (!formationDataIndex || formationDataIndex < 0) return;
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });

      champsDemandeDataCopy.splice(formationDataIndex, 0, formationData.object);

      setChampsDemandeData(champsDemandeDataCopy);
      setFormationData({});
    }

    if (
      localisationSiege !== undefined &&
      !localisationSiege.includes("Legafrik")
    ) {
      champsDemandeData.forEach((data, index) => {
        if (data.name !== "gestion-domiciliation") return;
        setDomiciliationData({ index, object: data });
      });
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });
      const domiciliationIndex = champsDemandeDataCopy.findIndex(
        (data) => data.name === "gestion-domiciliation"
      );

      if (domiciliationIndex < 0) return;

      champsDemandeDataCopy.splice(domiciliationIndex, 1);
      setChampsDemandeData(champsDemandeDataCopy);
    } else {
      const domiciliationDataIndex = domiciliationData.index;

      if (!domiciliationDataIndex || domiciliationDataIndex < 0) return;
      const champsDemandeDataCopy = champsDemandeData.map((data) => {
        return { ...data, options: [...data.options] };
      });

      champsDemandeDataCopy.splice(
        domiciliationDataIndex,
        0,
        domiciliationData.object
      );

      setChampsDemandeData(champsDemandeDataCopy);
      setDomiciliationData({});
    }
  }, [
    champsDemandeObject["localisation-siege"],
    champsDemandeObject["secteur-activite"],
  ]);

  useEffect(() => {
    if (!pays.code) return;
    const LIBELLE = typeDemande?.libelle;
    const CODE_PAYS = pays.code.toUpperCase();

    if (LIBELLE.includes("SARL"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARL]);
    if (LIBELLE.includes("SARLU"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SUARL"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SARLU]);
    if (LIBELLE.includes("SAS"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SAS]);
    if (LIBELLE.includes("SASU"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].SASU]);
    if (LIBELLE.includes("ONG"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ONG]);
    if (LIBELLE.includes("Association"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].ASSOCIATION]);
    if (LIBELLE.includes("Fondation"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].FONDATION]);
    if (LIBELLE.includes("Domiciliation"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DOMICILIATION]);
    if (LIBELLE.includes("Marque"))
      setChampsDemandeData([...ChampsDemande[CODE_PAYS].DEPOT_MARQUE]);

    setStep(1);
  }, [typeDemande?.libelle, ChampsDemande, pays.code]);

  return (
    <div className="col-span-12 mt-8">
      <h2 className="intro-y text-lg font-medium">
        Edition commande "{typeDemande?.libelle} {pays?.libelle}"
      </h2>
      <div className="intro-y box py-10 sm:py-20 mt-">
        <div className="hidden sm:flex justify-center">
          {champsDemandeData.map((champsDemandeDataItem, index) => {
            return (
              <button
                key={index}
                className={`intro-y w-10 h-10 rounded-full 
                            button ${
                              step === index + 1
                                ? "text-white bg-theme-1 mx-2"
                                : "bg-gray-200 dark:bg-dark-1 text-gray-600 mx-2"
                            }`}
              >
                {index + 1}
              </button>
            );
          })}
        </div>

        <Components.ProgressBar value={step} max={champsDemandeData.length} />
        {champsDemandeData.map((champsDemandeDataItem, index) => {
          let champsDemandeObjectCopy = { ...mapDataObject };

          if (pays.libelle === "Maroc") {
            champsDemandeObjectCopy["nom-entreprise-un"] =
              champsDemandeObjectCopy["denomination"][0];
            champsDemandeObjectCopy["nom-entreprise-deux"] =
              champsDemandeObjectCopy["denomination"][1];
            champsDemandeObjectCopy["nom-entreprise-trois"] =
              champsDemandeObjectCopy["denomination"][2];
          }

          let mapData = champsDemandeObjectCopy[champsDemandeDataItem?.name];
          return (
            <Fragment key={index}>
              <Components.DemandeStep
                step={step}
                index={index + 1}
                length={champsDemandeData.length}
                champsDemandeData={champsDemandeDataItem}
                handleNextClick={handleNextClick}
                value={mapData}
                champsDemandeObject={champsDemandeObject}
                handleBackClick={handleBackClick}
              />
            </Fragment>
          );
        })}

        {champsDemandeData.length > 0 &&
        step - champsDemandeData.length === 1 ? (
          <Components.Cart
            productList={champsDemandeData}
            selectedProductList={Object.keys(champsDemandeObject).map((key) => {
              return { [key]: champsDemandeObject[key] };
            })}
            handleRemoveProduct={null}
            handleBackClick={handleBackClick}
            handleValidateClick={handleValidateClick}
            isDisabled={isLoadingEdit}
            setAmount={usePaiement.setMontant}
            amount={usePaiement.montant}
            currency={pays.monnaie}
          />
        ) : null}
      </div>
    </div>
  );
}
