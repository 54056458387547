import { useEffect, useState } from "react";
import { Utils } from "../../../../../utils";
import Moment from "moment";

export function RecapStepRecouvre(props) {
  let demande = JSON.parse(props?.dataGlobal?.useDemande?.champs_demande);
  // entreprise-debitrice
  demande = demande['entreprise-debitrice'];
  // adresseNew

  const formejuridique = props?.dataGlobal?.champs_questionnaireObject[
    "forme-juridique-debitrice"
  ]
    ? JSON.parse(
        props?.dataGlobal?.champs_questionnaireObject["forme-juridique-debitrice"]
      )
    : null;

  // const denomination = props?.dataGlobal?.champs_questionnaireObject["sigle"]
  //   ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject["sigle"])
  //   : null;
  // const adresse1 = props?.dataGlobal?.champs_questionnaireObject?.adresseNew
  //   ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresseNew)
  //   : "{}";

  const ville = props?.dataGlobal?.champs_questionnaireObject?.adresse
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse)
    : "{}";
  const adresse = props?.dataGlobal?.champs_questionnaireObject?.adresse
    ? JSON.parse(props?.dataGlobal?.champs_questionnaireObject?.adresse)
    : null;

  const commentaire = props?.dataGlobal?.champs_questionnaireObject?.commentaire
    ? props?.dataGlobal?.champs_questionnaireObject?.commentaire
    : null;

  const handleStepSubmit = (e) => {
    e.preventDefault();

    props.handleSubmit(e, "", "");
  };

  const renderData = (items) => {
    if (
      props?.dataGlobal?.champs_questionnaireObject != null &&
      items != null
    ) {
      if (items.ville) {
        delete items.ville;
      }

      if (items.commune) {
        delete items.commune;
      }

      let itemData = items;
      const rendDatas = Object.keys(itemData).map((item_field, index) => {
        let attribute = `${
          item_field[0].toUpperCase() + String(item_field).substring(1)
        }`;
        attribute = attribute.replace(/_/g, " ");
        attribute = attribute.replace(/-/g, " ");
        if (
          attribute !== "Type" &&
          attribute !== "Fait partie des associes" &&
          itemData[item_field] != null
        ) {
          return (
            <div className="flex-item text-lg" key={item_field.toString()}>
              <span className="flex-item-text">
                {" "}
                {item_field === "denomination"
                  ? "Dénomination "
                  : item_field === "secteur-activite"
                  ? "Forme juridique"
                  : item_field === "civilite"
                  ? "Civilité "
                  : item_field === "numero-secretaire-adjoint"
                  ? "Numéro du Sécrétaire Adjoint"
                  : item_field === "nom-tresorier-adjoint"
                  ? "Nom du Trésorier Adjoint"
                  : item_field === "nationalite-tresorier-adjoint"
                  ? "Nationalité du Trésorier Adjoint"
                  : item_field === "numero-tresorier-adjoint"
                  ? "Numéro du Trésorier Adjoint"
                  : item_field === "nom-mere"
                  ? "Nom de la mère"
                  : item_field === "nombre-associes"
                  ? "Nombre d'associés"
                  : item_field === "entreprise-anterieur"
                  ? "Avez-vous une entreprise"
                  : item_field === "date-reception-registre-de-commerce"
                  ? "Date de réception du registre de commerce"
                  : item_field === "localisation-siege"
                  ? "Siège de l'entreprise"
                  : item_field === "capital-social"
                  ? "Capital social"
                  : item_field === "code-postale"
                  ? "Numéro de boîte postale"
                  : item_field === "nom-prenoms-proprietaire"
                  ? " Président Associé"
                  : item_field === "fait-partie-des-associes"
                  ? "Fait partie des associés"
                  : item_field === "numero-telephone"
                  ? "Numéro de téléphone"
                  : item_field === "date-naissance"
                  ? "Date de naissance"
                  : item_field === "prenom-interlocuteur"
                  ? "Prénom(s) interlocuteur "
                  : item_field === "nom-interlocuteur"
                  ? "Nom interlocuteur "
                  : item_field === "email"
                  ? "Email "
                  : attribute}
                :
              </span>
              <span className="flex-item-description">
                {typeof itemData[item_field] !== "object"
                  ? itemData[item_field].toString().replace(/_/g, " ")
                  : itemData[item_field].toString()}
              </span>
            </div>
          );
        }
      });
      return rendDatas;
    }
  };

  return (
    <div
      className={`grid grid-cols-1 col-span-12  ${
        props.step !== props.index ? "hidden" : ""
      } `}
    >
      <>
        <div className="flex-content-column">
          <div className="flex-content-item">
            <div className="flex-content-item-header">
              <h3>
                <strong> Informations relatives au débiteur</strong>
              </h3>
            </div>
          </div>
          <hr />
          <div className="flex-content-item-body ">
            {props?.dataGlobal?.champs_questionnaireObject &&
            demande !== null ? (
              <div className="flex-item text-lg ">
                <span className="">
                  {" "}
                  <strong>Dénomination : </strong>
                </span>
                <span className="flex-item-description">{demande}</span>
              </div>
            ) : null}
            {props?.dataGlobal?.champs_questionnaireObject &&
            formejuridique !== null ? (
              <div className="flex-item text-lg my-5">
                <span className="">
                  {" "}
                  <strong>Forme juridique : </strong>
                </span>
                <span className="flex-item-description">
                  {formejuridique}
                </span>
              </div>
            ) : null}
            ​{" "}
          </div>
          <div className="flex-content-item my-5">
            <div className="flex-content-item-header fs-5">
              <h3>
                <strong> Informations relatives à l'adresse du débiteur</strong>
              </h3>
            </div>
            <hr />
            <div className="flex-content-item-body ">
              {props?.dataGlobal?.champs_questionnaireObject &&
              ville?.ville !== null ? (
                <span className="flex-item text-lg  ">
                  <strong>Ville : </strong>

                  <span className="flex-item-description">{ville?.ville}</span>
                </span>
              ) : null}
              {props?.dataGlobal?.champs_questionnaireObject &&
              ville?.commune !== null ? (
                <span className="flex-item text-lg my-5 ">
                  <strong>Commune : </strong>
                  <span className="flex-item-description">
                    {ville?.commune}
                  </span>
                </span>
              ) : null}
            </div>
          </div>
          {props?.dataGlobal?.champs_questionnaireObject && adresse !== null ? (
            <div className="flex-content-item my-5">
              <div className="flex-content-item-header">
                <h3>
                  <strong>Informations relatives à l'interlocuteur</strong>
                </h3>
              </div>
              <hr />
              <div className="flex-content-item-body my-5">
                {renderData(adresse)}
              </div>
            </div>
          ) : null}
          {props?.dataGlobal?.champs_questionnaireObject && adresse !== null ? (
            <div className="flex-content-item my-5">
              <div className="flex-content-item-header text-xl">
                <h3>
                  <strong> Autres Informations</strong>
                </h3>
              </div>
              <hr />
              <div className="flex-content-item-body">
                <div className="flex-item text-lg">
                  <span className="">
                    <strong>Commentaire :</strong>
                  </span>
                  <span className="flex-item-description">{commentaire}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className="intro-y col-span-12 flex items-center justify-center
    sm:justify-end mt-10"
        >
          <button
            className="button w-24 justify-center block bg-gray-200 text-gray-600 
    dark:bg-dark-1 dark:text-gray-300"
            onClick={props.handleQuestionnaireBackClick ?? null}
          >
            Revenir
          </button>
          <button
            className="button w-24 justify-center block bg-theme-1 
    text-white ml-2"
            onClick={handleStepSubmit}
          >
            Suivant
          </button>
        </div>
      </>
    </div>
  );
}
