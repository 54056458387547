import { Api } from './Api';

const  ENPOINTS = {
    TypeDemande: 'type-demandes',
};

const getAll = signal => {
    return Api.get(ENPOINTS.TypeDemande, signal)
}

const getById = (id, signal) => {
    return Api.get(`${ENPOINTS.TypeDemande}/${id}`, signal);
}

const getEtapeTraitements = (id, signal) => {
    return Api.get(`user/${ENPOINTS.TypeDemande}/${id}/etape-traitements`, signal);
}

const getTypeDocuments = (id, signal) => {
    return Api.get(`user/${ENPOINTS.TypeDemande}/${id}/type-documents`, signal);
}

const getTypePieces = (id, signal) => {
    return Api.get(`user/${ENPOINTS.TypeDemande}/${id}/type-pieces`, signal);
}

const create = (payload, signal) => {
    return Api.post(ENPOINTS.TypeDemande, payload, signal)
}

const update = (id, payload, signal) => {
    return Api.put(`${ENPOINTS.TypeDemande}/${id}`, payload, signal)
}
const destroy = (id, signal) => {
    return Api.erase(`${ENPOINTS.TypeDemande}/${id}`, signal)
}

export const TypeDemandeService = {
    getAll,
    getById,
    getEtapeTraitements,
    getTypeDocuments,
    getTypePieces,
    create,
    update,
    destroy
}