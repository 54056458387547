export const DEMANDS_LIST_REQUESTING = 'demands/DEMANDS_LIST_REQUESTING';
export const DEMANDS_LIST_SUCCESS = 'demands/DEMANDS_LIST_SUCCESS';
export const DEMANDS_LIST_FAILED = 'demands/DEMANDS_LIST_FAILED';

export const DEMANDS_PAYMENTS_LIST_REQUESTING = 'demands/DEMANDS_PAYMENTS_LIST_REQUESTING';
export const DEMANDS_PAYMENTS_LIST_SUCCESS = 'demands/DEMANDS_PAYMENTS_LIST_SUCCESS';
export const DEMANDS_PAYMENTS_LIST_FAILED = 'demands/DEMANDS_PAYMENTS_LIST_FAILED';
export const DEMANDS_PAYMENTS_RESET_LIST = 'demands/DEMANDS_PAYMENTS_RESET_LIST';

//
export const DEMANDS_DETAIL_REQUESTING = 'demands/DEMANDS_DETAIL_REQUESTING';
export const DEMANDS_DETAIL_SUCCESS = 'demands/DEMANDS_DETAIL_SUCCESS';
export const DEMANDS_DETAIL_FAILED = 'demands/DEMANDS_DETAIL_FAILED';

export const DEMANDS_PIECES_LIST_REQUESTING = 'demands/DEMANDS_PIECES_LIST_REQUESTING';
export const DEMANDS_PIECES_LIST_SUCCESS = 'demands/DEMANDS_PIECES_LIST_SUCCESS';
export const DEMANDS_PIECES_LIST_FAILED = 'demands/DEMANDS_PIECES_LIST_FAILED';
export const DEMANDS_DOCUMENTS_LIST_REQUESTING = 'demands/DEMANDS_DOCUMENTS_LIST_REQUESTING';
export const DEMANDS_DOCUMENTS_LIST_SUCCESS = 'demands/DEMANDS_DOCUMENTS_LIST_SUCCESS';
export const DEMANDS_DOCUMENTS_LIST_FAILED = 'demands/DEMANDS_DOCUMENTS_LIST_FAILED';
export const DEMANDS_OBSERVATIONS_LIST_REQUESTING = 'demands/DEMANDS_OBSERVATIONS_LIST_REQUESTING';
export const DEMANDS_OBSERVATIONS_LIST_SUCCESS = 'demands/DEMANDS_OBSERVATIONS_LIST_SUCCESS';
export const DEMANDS_OBSERVATIONS_LIST_FAILED = 'demands/DEMANDS_OBSERVATIONS_LIST_FAILED';

export const DEMANDS_DELETE_PIECE_REQUESTING = 'demands/DEMANDS_DELETE_PIECE_REQUESTING';
export const DEMANDS_DELETE_PIECE_SUCCESS = 'demands/DEMANDS_DELETE_PIECE_SUCCESS';
export const DEMANDS_DELETE_PIECE_FAILED = 'demands/DEMANDS_DELETE_PIECE_FAILED';
export const DEMANDS_ADD_DOCUMENT_REQUESTING = 'demands/DEMANDS_ADD_DOCUMENT_REQUESTING';
export const DEMANDS_ADD_DOCUMENT_SUCCESS = 'demands/DEMANDS_ADD_DOCUMENT_SUCCESS';
export const DEMANDS_ADD_DOCUMENT_FAILED = 'demands/DEMANDS_ADD_DOCUMENT_FAILED';
export const DEMANDS_EDIT_DOCUMENT_REQUESTING = 'demands/DEMANDS_EDIT_DOCUMENT_REQUESTING';
export const DEMANDS_EDIT_DOCUMENT_SUCCESS = 'demands/DEMANDS_EDIT_DOCUMENT_SUCCESS';
export const DEMANDS_EDIT_DOCUMENT_FAILED = 'demands/DEMANDS_EDIT_DOCUMENT_FAILED';
export const DEMANDS_DELETE_DOCUMENT_REQUESTING = 'demands/DEMANDS_DELETE_DOCUMENT_REQUESTING';
export const DEMANDS_DELETE_DOCUMENT_SUCCESS = 'demands/DEMANDS_DELETE_DOCUMENT_SUCCESS';
export const DEMANDS_DELETE_DOCUMENT_FAILED = 'demands/DEMANDS_DELETE_DOCUMENT_FAILED';
export const DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_REQUESTING = 'demands/DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_REQUESTING';
export const DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_SUCCESS = 'demands/DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_SUCCESS';
export const DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_FAILED = 'demands/DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_FAILED';
export const DEMANDS_STEP_CEPICI_DATA = 'demands/DEMANDS_STEP_CEPICI_DATA';
export const DEMANDS_STEP_OBTENTION_DFE_DATA = 'demands/DEMANDS_STEP_OBTENTION_DFE_DATA';

export const DEMANDS_DOCUMENT_COMPLEMENTAIRE_REQUESTING = 'demands/DEMANDS_DOCUMENT_COMPLEMENTAIRE_REQUESTING';
export const DEMANDS_DOCUMENT_COMPLEMENTAIRE_SUCCESS = 'demands/DEMANDS_DOCUMENT_COMPLEMENTAIRE_SUCCESS';
export const DEMANDS_DOCUMENT_COMPLEMENTAIRE_FAILED = 'demands/DEMANDS_DOCUMENT_COMPLEMENTAIRE_FAILED';

//demandes pays pays
export const DEMANDS_PAYS_LIST_REQUESTING = 'demands/DEMANDS_PAYS_LIST_REQUESTING';
export const DEMANDS_PAYS_LIST_SUCCESS = 'demands/DEMANDS_PAYS_LIST_SUCCESS';
export const DEMANDS_PAYS_LIST_FAILED = 'demands/DEMANDS_PAYS_LIST_FAILED';

//Edition du question
export const DEMANDS_EDIT_QUESTIONNAIRE_REQUESTING = 'demands/DEMANDS_EDIT_QUESTIONNAIRE_REQUESTING';
export const DEMANDS_EDIT_QUESTIONNAIRE_SUCCESS = 'demands/DEMANDS_EDIT_QUESTIONNAIRE_SUCCESS';
export const DEMANDS_EDIT_QUESTIONNAIRE_FAILED = 'demands/DEMANDS_EDIT_QUESTIONNAIRE_FAILED';


//Type de demandes de pays
export const DEMANDS_TYPE_LIST_REQUESTING = 'demands/DEMANDS_TYPE_LIST_REQUESTING';
export const DEMANDS_TYPE_LIST_SUCCESS = 'demands/DEMANDS_TYPE_LIST_SUCCESS';
export const DEMANDS_TYPE_LIST_FAILED = 'demands/DEMANDS_TYPE_LIST_FAILED';

//Create demandes
export const CREATE_DEMANDS_REQUESTING = 'demands/CREATE_DEMANDS_REQUESTING';
export const CREATE_DEMANDS_SUCCESS = 'demands/CREATE_DEMANDS_SUCCESS';
export const CREATE_DEMANDS_FAILED = 'demands/CREATE_DEMANDS_FAILED';

//Create hubspot demandes
export const CREATE_HUBSPOT_LEADS_REQUESTING = 'demands/CREATE_HUBSPOT_LEADS_REQUESTING';
export const CREATE_HUBSPOT_LEADS_SUCCESS = 'demands/CREATE_HUBSPOT_LEADS_SUCCESS';
export const CREATE_HUBSPOT_LEADS_FAILED = 'demands/CREATE_HUBSPOT_LEADS_FAILED';

//Create paiement of demandes
export const DEMANDS_PAYMENT_REQUESTING = 'demands/DEMANDS_PAYMENT_REQUESTING';
export const DEMANDS_PAYMENT_SUCCESS = 'demands/DEMANDS_PAYMENT_SUCCESS';
export const DEMANDS_PAYMENT_FAILED = 'demands/DEMANDS_PAYMENT_FAILED';

//Verification User
export const VERIFICATION_USER_REQUESTING = 'demands/VERIFICATION_USER_REQUESTING';
export const VERIFICATION_USER_SUCCESS = 'demands/VERIFICATION_USER_SUCCESS';
export const VERIFICATION_USER_FAILED = 'demands/VERIFICATION_USER_FAILED';