import * as constants from "./constants";

export const  paymentMethodsListRequest =(payload)=> {
    return { type: constants.PAYMENT_METHODS_LIST_REQUESTING, payload}
}

export const paymentMethodsListSuccess=(payload)=>{
    return { type:constants.PAYMENT_METHODS_LIST_SUCCESS, payload }
}

export const paymentMethodsListFailed=(error)=>{
    return { type:constants.PAYMENT_METHODS_LIST_FAILED, error }
}

export const  paymentMethodsAddRequest =(payload)=> {
    return { type: constants.PAYMENT_METHODS_ADD_REQUESTING, payload}
}

export const paymentMethodsAddSuccess=(payload)=>{
    return { type:constants.PAYMENT_METHODS_ADD_SUCCESS, payload }
}

export const paymentMethodsAddFailed=(error)=>{
    return { type:constants.PAYMENT_METHODS_ADD_FAILED, error }
}


export const  paymentMethodsEditRequest =(payload)=> {
    return { type: constants.PAYMENT_METHODS_EDIT_REQUESTING, payload}
}

export const paymentMethodsEditSuccess=(payload)=>{
    return { type:constants.PAYMENT_METHODS_EDIT_SUCCESS, payload }
}

export const paymentMethodsEditFailed=(error)=>{
    return { type:constants.PAYMENT_METHODS_EDIT_FAILED, error }
}

export const paymentMethodsSetData=(payload)=>{
    return { type:constants.PAYMENT_METHODS_SET_DATA, payload }
}
