import * as constants from "./constants";

export const initialState = {
    type_pieces_list_loading: false,
    data: [],
    error_type_pieces_lists:null,
    //By type de demands
    pieces_by_type_demands_loading: false,
    pieces_by_type_demands: [],
    error_pieces_by_type_demands:null,
}

const typePiecesReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.TYPE_PIECES_LIST_REQUESTING:
            return {
                ...state,
                type_pieces_list_loading: true,
            };

        case constants.TYPE_PIECES_LIST_SUCCESS:

            return {
                ...state,
                type_pieces_list_loading: false,
                data: action.payload
            };

        case constants.TYPE_PIECES_LIST_FAILED:

            return {
                ...state,
                type_pieces_list_loading: false,
                error_type_pieces_lists:action.error
            };

            //By type de come

        case constants.PIECES_BY_TYPE_DEMANDS_REQUESTING:
            return {
                ...state,
                pieces_by_type_demands_loading: true,
            };

        case constants.PIECES_BY_TYPE_DEMANDS_SUCCESS:

            return {
                ...state,
                pieces_by_type_demands_loading: false,
                pieces_by_type_demands: action.payload
            };

        case constants.PIECES_BY_TYPE_DEMANDS_FAILED:

            return {
                ...state,
                pieces_by_type_demands_loading: false,
                error_pieces_by_type_demands:action.error
            };

        default:
            return state;
    }
}
export default typePiecesReducer
