import * as Actions from "./constants";

export const showStripePaymentModal = (payload) => {
    return { type:Actions.SHOW_STRIPE_PAYMENT_MODAL,payload}
}
export const showConfirmedAdminsAddModal = (payload ) => {
    return { type:Actions.SHOW_CONFIRMED_ADMINS_ADD_MODAL,payload}
}

export const showConfirmedAdminsEditModal = (payload) => {
    return { type:Actions.SHOW_CONFIRMED_ADMINS_EDIT_MODAL,payload}
}

export const showAdminsDetailModal = (payload) => {
    return { type:Actions.SHOW_ADMINS_DETAIL_MODAL,payload}
}
export const showConfirmedCreatePaymentModal = (payload ) => {
    return { type:Actions.SHOW_CONFIRMED_CREATE_PAYMENT_MODAL,payload}
}
export const showDetailPaymentModal = (payload ) => {
    return { type:Actions.SHOW_DETAIL_PAYMENT_MODAL,payload}
}
export const hideModal = ()  => {
    return { type:Actions.HIDE_MODAL}
}

export const showConfirmedArticlesAddModal = (payload ) => {
    return { type:Actions.SHOW_CONFIRMED_ARTICLES_ADD_MODAL,payload}
}

export const showCategoriesAddModal = () => {
    return { type:Actions.SHOW_CATEGORIES_ADD_MODAL}
}