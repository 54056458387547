export const SASU = [
    {
        name: "denomination",
        question: "A quel premier nom  avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "denomination",
        question: "A quel deuxième nom avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "denomination",
        question: "A quel troisième nom  avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "banque",
        question: "Dans quelle banque le compte sera-t-il ouvert ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "localisation-siege",
        question: "Où se situera le siège de votre entreprise ?",
        description: "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Je domicilie à Legafrik", price: ""},
            {value: "Je loue un local commercial", price: ""},
            {value: "Je n'ai pas de siège", price: ""}
        ]
    },
    {
        name: "capital-social",
        question: "Quel est le montant de votre capital social ?",
        description: "Pas de capital minimum exigé pour une SARL ou une SAS au Maroc",
        type: "mutiple", //"mutiple",
        options : [
            {value: "10 000 MAD", price: ""},
            {value: "50 000 MAD", price: ""},
            {value: "100 000 MAD", price: ""},
            {value: "200 000 MAD", price: ""},
            {value: "Autres", price: ""},
        ]
    },
]