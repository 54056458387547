import * as constants from "./constants";
export const initialState = {
    categories_list_loading: false,
    data: [],
    error_lists:null,
    //Add articles
    categories:null,
    categories_loading: false,
    categories_error:null,
}

const categoriesReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.CATEGORIES_LIST_REQUESTING:
            return {
                ...state,
                categories_list_loading: true,
            };
        case constants.CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                categories_list_loading: false,
                data: action.payload
            };
        case constants.CATEGORIES_LIST_FAILED:
            return {
                ...state,
                categories_list_loading: false,
                categories_error:action.error
            };

        //Add categories
        case constants.CATEGORIES_REQUESTING:
            return {
                ...state,
                categories_loading: true,
            };
        case constants.CATEGORIES_SUCCESS:
            return {
                ...state,
                categories_loading: false,
                categories: action.payload
            };
        case constants.CATEGORIES_FAILED:
            return {
                ...state,
                categories_loading: false,
                categories_error:action.error
            };

        default:
            return state;
    }
}
export default categoriesReducer
