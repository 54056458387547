import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import * as Icons from "react-feather";

export function AdresseOneStepStatutaire(props) {
  const [adresse, setAddresse] = useState({});

  const handleInputChange = (name, value) => {
    const adresseCopy = { ...adresse };
    adresseCopy[name] = value;

    setAddresse(adresseCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "adresse1", JSON.stringify(adresse));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12 text-lg">
        <strong>
          Renseignez les Informations relatives à la société 
        </strong>
      </div>
      <div className="intro-y col-span-12  ">
        <Alert variant="danger">
          <Alert.Heading>
            <Icons.Info className="w-4 h-4 " />
          </Alert.Heading>
          <Alert.Link href="#">L’adresse de la  société </Alert.Link> est tout
          simplement le siège social. C’est l’adresse qui figurera sur tous les
          documents officiels de la  société. Si vous n’avez pas encore de
          bureaux, vous pouvez domicilier l’entreprise chez l’un des associés.
        </Alert>

        <small>
          Indiquez les informations relatives à l'adresse géographique de la
          société
        </small>
      </div>

      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Siège social</div>
        <input
          type="text"
          className="input w-full border flex-1"
          placeholder="Siège social"
          onChange={(e) => handleInputChange("siege-sociale", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Montant du capital social </div>
        <input
          type="number"
          className="input w-full border flex-1"
          placeholder="Montant du capital social "
          onChange={(e) => handleInputChange("capital-social", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Montant nominal d’une part sociale</div>
        <input
          type="number"
          className="input w-full border flex-1"
          placeholder="Montant nominal d’une part sociale"
          onChange={(e) => handleInputChange("montant-nominal", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Numéro d’immatriculation</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Numero d’immatriculation"
          onChange={(e) =>
            handleInputChange("numero-immatriculation", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Ville d’immatriculation</div>
        <input
          type="text"
          className="input w-full border flex-1 field"
          placeholder="Ville d’immatriculation"
          onChange={(e) =>
            handleInputChange("ville-immatriculation", e.target.value)
          }
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
        <div className="mb-2">Nombre total de parts</div>
        <input
          type="number"
          className="input w-full border flex-1"
          placeholder="Nombre total de parts"
          onChange={(e) => handleInputChange("total-part", e.target.value)}
        />
      </div>
      <div className="intro-y col-span-12 sm:col-span-6">
            <div className="mb-2">Date de création de la société</div>
            <input
              type="date"
              className="input w-full border flex-1 field"
              placeholder="Date de création de la société"
              onChange={(e) =>
                handleInputChange(
                  "date-creation-societe",
                  e.target.value
                )
              }
            />
          </div>
  
      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        {/* <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button> */}
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
