import {useState} from "react";

export function Dure(props) {
    const [duree, setDuree] =  useState(Object.keys(props.dure).length === 0?{}:JSON.parse(props.dure));

    const [stateMandat, setStateMandat] = useState(Object.keys(props.dure).length === 0?false:((JSON.parse(props.dure)['dure-president'] !== 9999) || (JSON.parse(props.dure)['dure-bureau'] !== 9999) || (JSON.parse(props.dure)['dure-commisaire'] !== 9999)));

    const handleInputChange = (name, value) => {
        const dureeCopy = { ...duree };
        dureeCopy[name] = value;
        if (!stateMandat) {
            dureeCopy["dure-president"] = 9999
            dureeCopy["dure-bureau"] = 9999;
            dureeCopy["dure-commisaire"] = 9999;
        }
        setDuree(dureeCopy);
    };
    const handleStepSubmit = (e) => {
        e.preventDefault();
        const dureeCopy = { ...duree };
        if (!stateMandat) {
            dureeCopy["dure-president"] = 9999;
            dureeCopy["dure-bureau"] = 9999;
            dureeCopy["dure-commisaire"] = 9999;
        }
        props.handleNextClick(e, "dure", JSON.stringify(dureeCopy));
    };
    const handleTypeMandatChange = (params) => {
        if (params.includes("Limite")) {
            setStateMandat(true);
        } else {
            setStateMandat(false);
        }
    };

    return (
        <div
            className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
        >
            <div className="intro-y col-span-12  ">
                <strong>Durée du mandat des organes </strong>
            </div>
            <div className="intro-y col-span-12  "> La durée du mandat président (An) ?</div>

            <div className="intro-y col-span-12 flex flex-col sm:flex-row  mb-2">

                <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                    <input
                        type="radio"
                        className="input border mr-2"
                        id={`horizontal-radio-oui-mandat-Limite`}
                        name={`gerant-mandat`}
                        value="Limite"
                        checked={stateMandat}
                        onChange={(e) => handleTypeMandatChange("Limite")}
                    />
                    <label className="cursor-pointer select-none" htmlFor={`horizontal-radio-oui-0`}>
                        Limité
                    </label>
                </div>
                <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                    <input
                        type="radio"
                        className="input border mr-2"
                        id={`horizontal-non-mandat-illimite`}
                        name={`gerant-mandat`}
                        value="illimite"
                        checked={!stateMandat}
                        onChange={(e) => handleTypeMandatChange("illimite")}
                    />
                    <label className="cursor-pointer select-none" htmlFor={`horizontal-non-0`}>
                        Illimité
                    </label>
                </div>
            </div>
            {stateMandat ? (
                <>
                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">La durée du mandat du Président ? (An)</div>
                        <input
                            type="number"
                            className="input w-full border flex-1"
                            placeholder="Mandat du Président"
                            value={duree['dure-president']}
                            onChange={(e) =>
                                handleInputChange("dure-president", e.target.value)
                            }
                        />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">La durée du mandat du bureau exécutif ? (An)</div>
                        <input
                            type="number"
                            className="input w-full border flex-1"
                            placeholder="Bureau exécutif"
                            value={duree['dure-bureau']}
                            onChange={(e) => handleInputChange("dure-bureau", e.target.value)}
                        />
                    </div>

                    <div className="intro-y col-span-12 sm:col-span-6">
                        <div className="mb-2">
                            La durée du mandat des commissaires aux comptes ? (An)
                        </div>
                        <input
                            type="number"
                            className="input w-full border flex-1"
                            placeholder="Commissaires au compte"
                            value={duree['dure-commisaire']}
                            onChange={(e) => handleInputChange("dure-commisaire", e.target.value)}
                        />
                    </div>

                </>

            ) : null}

            <div className="intro-y col-span-12 flex items-center justify-center sm:justify-end mt-10">
                <button
                    className="button w-24 justify-center block bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-300"
                    onClick={props.handleQuestionnaireBackClick ?? null}>Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 text-white ml-2"
                        onClick={handleStepSubmit}>Suivant
                </button>
            </div>
        </div>
    );
}