export const individuelle = [
  {
    location: "Abidjan",
    price: "99 000 CFA",
  },
  {
    location: "Intérieur du pays",
    price: "199 000 CFA",
  },
];

export const sarl_sarlu = [
  {
    location: "Abidjan",
    price: "139 000 CFA",
  },
  {
    location: "Intérieur du pays",
    price: "220 000 CFA",
  },
];

export const sas_sasu = [
  {
    location: "Abidjan ( capital inferieur ou egal à 1 million)",
    price: "330 000 CFA",
  },
  {
    location: "Intérieur du pays ( capital inferieur ou egal à 1 millon)",
    price: "399 000 CFA",
  },
];

export const ong_fondation_association = [
  {
    location: "Abidjan",
    price: "159 000 CFA",
  },
  {
    location: "Intérieur du pays",
    price: "199 000  CFA",
  },
];
