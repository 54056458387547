import * as constants from "./constants";

export const initialState = {
    etat_documents_list_loading: false,
    data: [],
    error_etat_documents_lists:null
}

const etatDocumentsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.ETAT_DOCUMENTS_LIST_REQUESTING:
            return {
                ...state,
                etat_documents_list_loading: true,
            };

        case constants.ETAT_DOCUMENTS_LIST_SUCCESS:

            return {
                ...state,
                etat_documents_list_loading: false,
                data: action.payload
            };

        case constants.ETAT_DOCUMENTS_LIST_FAILED:

            return {
                ...state,
                etat_documents_list_loading: false,
                error_etat_documents_lists:action.error
            };

        default:
            return state;
    }
}
export default etatDocumentsReducer
