import * as constants from "./constants";

export const  typeDemandsListRequest =()=> {
    return { type: constants.TYPE_DEMANDS_LIST_REQUESTING}
}
export const typeDemandsListSuccess=(payload)=>{
    return { type:constants.TYPE_DEMANDS_LIST_SUCCESS, payload }
}
export const typeDemandsListFailed=(error)=>{
    return { type:constants.TYPE_DEMANDS_LIST_FAILED, error }
}
export const  typeDemandsEtapeTraitementsListRequest =(payload)=> {
    return { type: constants.TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_REQUESTING,payload}
}
export const typeDemandsEtapeTraitementsListSuccess=(payload)=>{
    return { type:constants.TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_SUCCESS, payload }
}
export const typeDemandsEtapeTraitementsListFailed=(error)=>{
    return { type:constants.TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_FAILED, error }
}

export const  typeDemandsPaysListRequest =(payload)=> {
    return { type: constants.TYPE_DEMANDS_PAYS_LIST_REQUESTING,payload}
}
export const typeDemandsPaysListSuccess=(payload)=>{
    return { type:constants.TYPE_DEMANDS_PAYS_LIST_SUCCESS, payload }
}
export const typeDemandsPaysListFailed=(error)=>{
    return { type:constants.TYPE_DEMANDS_PAYS_LIST_FAILED, error }
}