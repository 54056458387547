import React, { lazy, Suspense } from "react";
import {
    Route,
    Routes
} from "react-router-dom";
import {PrivateRoute} from "./components/PrivateRoute/PrivateRoute";
import  * as navigations from "./utils/navigation";
import {EditLeads} from "./pages/EditLeads/EditLeads";
// Admin page
const Login = lazy(() => import("./pages/Login/Login"));
const ChangePassword = lazy(() => import("./pages/ChangePassword/ChangePassword"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"));
const AdminLayout = lazy(() => import("./layouts/Layout"));

// Admin page
const LeadList= lazy(() => import("./pages/LeadList/LeadList"));
const DemandsList= lazy(() => import("./pages/DemandsList/DemandsList"));
const AdminsList= lazy(() => import("./pages/AdminsList/AdminsList"));
const RecouvrementList= lazy(() => import("./pages/RecouvrementList/RecouvrementList"));
const DemandeCreate= lazy(() => import("./pages/DemandeCreate/DemandeCreate"));
const DemandeTypeByCountry= lazy(() => import("./pages/DemandeTypeByCountry/DemandeTypeByCountry"));
const AdminSettings= lazy(() => import("./pages/AdminSettings/AdminSettings"));
const AdminNotifications= lazy(() => import("./pages/AdminNotifications/AdminNotifications"));
const AdminsAdd= lazy(() => import("./pages/Admins/Add/AdminsAdd"));
const AdminsEdit= lazy(() => import("./pages/Admins/Edit/AdminsEdit"));
const DemandsPayment= lazy(() => import("./pages/DemandsPayment/DemandsPayment"));
const DemandsCreatePayment= lazy(() => import("./pages/DemandsCreatePayment/DemandsCreatePayment"));
//Demands
const DetailLeads= lazy(() => import("./pages/DetailLeads/DetailLeads"));
const DemandsDetail= lazy(() => import("./pages/DetailDemands/DetailDemands"));
const EditQuestionnaire= lazy(() => import("./pages/EditQuestionnaire/EditQuestionnaire"));

//Processing
const DocumentProcessingStep= lazy(() => import("./pages/DocumentProcessingStep/DocumentProcessingStep"));

//Roles list
const RolesList= lazy(() => import("./pages/RolesList/RolesList"));

//Admin change Password
const AdminChangePassword= lazy(() => import("./pages/AdminChangePassword/AdminChangePassword"));

//Articles
const ArticleList= lazy(() => import("./pages/ArticleList/ArticleList"));
const Articles= lazy(() => import("./pages/Articles/Add/ArticlesAdd"));
const ArticlesEdit= lazy(() => import("./pages/Articles/Edit/ArticlesEdit"));
const ArticlesDetail= lazy(() => import("./pages/Articles/Detail/ArticlesDetail"));



//Agences
const AgencesList= lazy(() => import("./pages/AgencesList/AgencesList"));
const AgenceCreate= lazy(() => import("./pages/AgenceCreate/AgenceCreate"));

//Organizations
const Organizations= lazy(() => import("./pages/Organizations/Organizations"));
const SubOrganisationsList= lazy(() => import("./pages/SubOrganisationsList/SubOrganisationsList"));
const SubOrganisationsCreate= lazy(() => import("./pages/SubOrganisationsCreate/SubOrganisationsCreate"));

//Agences
const UsersList= lazy(() => import("./pages/UsersList/UsersList"));
const UserCreate= lazy(() => import("./pages/UserCreate/UserCreate"));
const UserEdit= lazy(() => import("./pages/UserEdit/UserEdit"));

//Sales
const SalesList= lazy(() => import("./pages/SalesList/SalesList"));

//Logs
const LogsList= lazy(() => import("./pages/LogsList/LogsList"));

//Settings
const Settings= lazy(() => import("./pages/Settings/Settings"));
const Aide= lazy(() => import("./pages/Settings/Aide"));
const Password= lazy(() => import("./pages/Settings/Password/Password"));
const Profile= lazy(() => import("./pages/Settings/Profile/Profile"));


//Commissions
const CommissionsList= lazy(() => import("./pages/CommissionsList/CommissionsList"));
const CommissionCreate= lazy(() => import("./pages/CommissionCreate/CommissionCreate"));
const CommissionWithdrawal= lazy(() => import("./pages/CommissionWithdrawal/CommissionWithdrawal"));

//Client list
const CustomersList= lazy(() => import("./pages/CustomersList/CustomersList"));

const LegafrikRoutes = () => {
  return (
    <Suspense fallback={<div/>}>
      <Routes>

          <Route element={<PrivateRoute/>}>
{/*
              <Route element={<AdminLayout><Dashboard/></AdminLayout>} path={navigations.DASHBOARD} exact />
*/}
              <Route element={<AdminLayout><AdminNotifications/></AdminLayout>} path={navigations.ADMIN_NOTIFICATION} exact />
              <Route element={<AdminLayout><AdminSettings/></AdminLayout>} path={navigations.ADMIN_SETTINGS} exact />
              <Route element={<AdminLayout><RecouvrementList/></AdminLayout>} path={navigations.RECOUVREMENT_LIST} exact />
              <Route element={<AdminLayout><LeadList/></AdminLayout>} path={navigations.LEADS_LIST_TYPE} exact />
              <Route element={<AdminLayout><AdminsList/></AdminLayout>} path={navigations.ADMINS_LIST} exact />
              <Route element={<AdminLayout><AdminsAdd/></AdminLayout>} path={navigations.ADMINS_ADD} exact />
              <Route element={<AdminLayout><AdminsEdit/></AdminLayout>} path={navigations.ADMINS_EDIT} exact />
              <Route element={<AdminLayout><DetailLeads/></AdminLayout>} path={navigations.DETAIL_LEADS} exact />
              <Route element={<AdminLayout><DemandsDetail/></AdminLayout>} path={navigations.DEMANDS_DETAIL} exact />
              <Route element={<AdminLayout><EditLeads/></AdminLayout>} path={navigations.EDIT_LEADS} exact />
              <Route element={<AdminLayout><EditQuestionnaire/></AdminLayout>} path={navigations.EDIT_QUESTIONNAIRE} exact />
              <Route element={<AdminLayout><DemandsPayment/></AdminLayout>} path={navigations.DEMANDS_PAYMENT} exact />
              <Route element={<AdminLayout><DemandsCreatePayment/></AdminLayout>} path={navigations.DEMANDS_CREATE_PAYMENT} exact />
              <Route element={<AdminLayout><DocumentProcessingStep/></AdminLayout>} path={navigations.DOCUMENT_PROCESSING_STEP} exact />
              <Route element={<AdminLayout><RolesList/></AdminLayout>} path={navigations.ROLES_LIST} exact />
              <Route element={<AdminLayout><AdminChangePassword/></AdminLayout>} path={navigations.ADMIN_CHANGE_PASSWORD} exact />
              <Route element={<AdminLayout><ArticleList/></AdminLayout>} path={navigations.ARTICLE_LIST} exact />
              <Route element={<AdminLayout><Articles/></AdminLayout>} path={navigations.ARTICLE_ADD} exact />
              <Route element={<AdminLayout><ArticlesEdit/></AdminLayout>} path={navigations.ARTICLE_EDIT} exact />
              <Route element={<AdminLayout><ArticlesDetail/></AdminLayout>} path={navigations.ARTICLE_DETAIL} exact />

              {/*POS route*/}
              <Route element={<AdminLayout><AgencesList/></AdminLayout>} path={navigations.AGENCES_LIST} exact />
              <Route element={<AdminLayout><AgenceCreate/></AdminLayout>} path={navigations.AGENCE_CREATE} exact />
              <Route element={<AdminLayout><Organizations/></AdminLayout>} path={navigations.ORGANIZATIONS} exact />
              <Route element={<AdminLayout><UsersList/></AdminLayout>} path={navigations.USERS_LIST} exact />
              <Route element={<AdminLayout><UserCreate/></AdminLayout>} path={navigations.USER_CREATE} exact />
              <Route element={<AdminLayout><UserEdit/></AdminLayout>} path={navigations.USER_EDIT} exact />
              <Route element={<AdminLayout><DemandsList/></AdminLayout>} path={navigations.DASHBOARD} exact />
              <Route element={<AdminLayout><DemandeCreate/></AdminLayout>} path={navigations.DEMANDE_CREATE} exact />
              <Route element={<AdminLayout><DemandeTypeByCountry/></AdminLayout>} path={navigations.DEMANDE_TYPE} exact />
              <Route element={<AdminLayout><SalesList/></AdminLayout>} path={navigations.SALES_LIST} exact />
              <Route element={<AdminLayout><LogsList/></AdminLayout>} path={navigations.LOGS_LIST} exact />

              <Route element={<AdminLayout><SubOrganisationsList/></AdminLayout>} path={navigations.SUB_ORGANISATIONS_LIST} exact />
              <Route element={<AdminLayout><SubOrganisationsCreate/></AdminLayout>} path={navigations.SUB_ORGANISATIONS_CREATE} exact />


              <Route element={<AdminLayout><Settings/></AdminLayout>} path={navigations.SETTINGS} exact />
              <Route element={<AdminLayout><Aide/></AdminLayout>} path={navigations.AIDE} exact />
              <Route element={<AdminLayout><Profile/></AdminLayout>} path={navigations.PROFILE} exact />
              <Route element={<AdminLayout><Password/></AdminLayout>} path={navigations.PASSWORD} exact />
              <Route element={<AdminLayout><CommissionsList/></AdminLayout>} path={navigations.COMMISSIONS_LIST} exact />
              <Route element={<AdminLayout><CommissionCreate/></AdminLayout>} path={navigations.COMMISSION_CREATE} exact />
              <Route element={<AdminLayout><CommissionWithdrawal/></AdminLayout>} path={navigations.COMMISSION_WITHDRAWAL} exact />
              <Route element={<AdminLayout><CustomersList/></AdminLayout>} path={navigations.CUSTOMERS_LIST} exact />


          </Route>
          <Route exact path={navigations.LOGIN} element={<Login/>}/>
          <Route exact path={navigations.FORGOT_PASSWORD} element={<ForgotPassword/>}/>
          <Route exact path={navigations.CHANGE_PASSWORD} element={<ChangePassword/>}/>

      </Routes>
    </Suspense>
  );
};

export default LegafrikRoutes;
