import * as constants from "./constants";

export const  paymentsListRequest =(payload)=> {
    return { type: constants.PAYMENTS_LIST_REQUESTING, payload}
}

export const paymentsListSuccess=(payload)=>{
    return { type:constants.PAYMENTS_LIST_SUCCESS, payload }
}

export const paymentsListFailed=(error)=>{
    return { type:constants.PAYMENTS_LIST_FAILED, error }
}

export const  paymentsAddRequest =(payload,history)=> {
    return { type: constants.PAYMENTS_ADD_REQUESTING, payload,history}
}

export const paymentsAddSuccess=(payload)=>{
    return { type:constants.PAYMENTS_ADD_SUCCESS, payload }
}

export const paymentsAddFailed=(error)=>{
    return { type:constants.PAYMENTS_ADD_FAILED, error }
}


export const  paymentsEditRequest =(payload)=> {
    return { type: constants.PAYMENTS_EDIT_REQUESTING, payload}
}

export const paymentsEditSuccess=(payload)=>{
    return { type:constants.PAYMENTS_EDIT_SUCCESS, payload }
}

export const paymentsEditFailed=(error)=>{
    return { type:constants.PAYMENTS_EDIT_FAILED, error }
}

export const paymentsSetData=(payload)=>{
    return { type:constants.PAYMENTS_SET_DATA, payload }
}
