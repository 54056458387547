export const SARLU = [
    {
        name: "denomination",
        question: "A quel nom avez-vous pensé pour votre entreprise ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "secteur-activite",
        question: "Quel est votre secteur d’activité ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Commerce général", price: ""},
            {value: "Activités Immobilières", price: ""},
            {value: "Activités Informatiques", price: ""},
            {value: "Restauration", price: ""},
            {value: "Agence de voyage", price: ""},
            {value: "Transport VTC", price: ""},
            {value: "Agence de transfert d’argent", price: ""},
            {value: "Cabinet de conseil", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "banque",
        question: "Dans quelle banque le compte sera-t-il ouvert ?",
        description: "",
        type: "simple", //"mutiple",
        options : [
            // {value: "", price: ""}
        ]
    },
    {
        name: "entreprise-anterieur",
        question: "Avez-vous déjà créé une entreprise ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Oui", price: ""},
            {value: "Non", price: ""}
        ]
    },
    {
        name: "date-reception-registre-du-commerce",
        question: "Quand voulez-vous recevoir votre Registre du commerce ?",
        description: "",
        type: "mutiple", //"mutiple",
        options : [
            {value: "1 semaine", price: ""},
            {value: "Je ne sais pas encore", price: ""}
        ]
    },
    {
        name: "localisation-siege",
        question: "Où se situera le siège de votre entreprise ?",
        description: "Il faudra impérativement une adresse physique et postale pour votre entreprise.",
        type: "mutiple", //"mutiple",
        options : [
            {value: "A mon domicile", price: ""},
            {value: "Je domicilie à Legafrik (25 000 FCFA/mois)", price: ""},
            {value: "Je loue un local commercial", price: ""},
            {value: "Je n'ai pas de siège", price: ""}
        ]
    },
    {
        name: "capital-social",
        question: "Quel est le montant de votre capital social ?",
        description: "Pas de capital minimum exigé pour une SARL ou une SAS au Bénin",
        type: "mutiple", //"mutiple",
        options : [
            {value: "100 000 FCFA", price: ""},
            {value: "1 000 000 FCFA", price: ""},
            {value: "100 000 000 FCFA", price: ""},
            {value: "Autres", price: ""},
        ]
    },
    {
        name: "offre",
        question: "Quelle Offre choisissez-vous ?",
        type: "mutiple", //"mutiple",
        options : [
            {value: "Les associés sont au Bénin (120 000 FCFA)", price: 120_000},
            {value: "Les associés sont à l'étranger (280 000 FCFA)", price: 280_000},
        ]
    },
    {
        name: "gestion-domiciliation",
        question: "Vous avez choisi de domicilier votre entreprise chez Legafrik?",
        description: "Choisissez une formule",
        type: "mutiple", //"mutiple",
        options : [
            {value: "6 mois à 150 000 FCFA (25 000 F/MOIS) ", price: 150_000},
            {value: "12 mois à 240 000 FCFA (20 000 F/MOIS) ", price: 240_000},
            {value: "Je ne suis pas intéressé", price: ""}
        ]
    }
]