import * as constants from "./constants";

export const initialState = {
    type_demands_loading: false,
    type_demands_list_loading: false,
    type_demands_activate_loading: false,
    type_demands_update_loading: false,
    data: [],
    type_demands:null,
    error_lists:null,
    //type de demands etape de traitement
    type_demands_etape_traitements_loading: false,
    type_demands_etape_traitements:[],
    type_demands_etape_traitements_error:null,
    //type de demands par pays
    type_demands_pays_loading: false,
    type_demands_pays:[],
    type_demands_pays_error:null

}

const typeDemandsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.TYPE_DEMANDS_LIST_REQUESTING:
            return {
                ...state,
                type_demands_list_loading: true,
            };

        case constants.TYPE_DEMANDS_LIST_SUCCESS:

            return {
                ...state,
                type_demands_list_loading: false,
                data: action.payload
            };

        case constants.TYPE_DEMANDS_LIST_FAILED:

            return {
                ...state,
                type_demands_list_loading: false,
                error_lists:action.error
            };

            //Type de demandes etapes de traitement
        case constants.TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_REQUESTING:
            return {
                ...state,
                type_demands_etape_traitements_loading: true,
            };

        case constants.TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_SUCCESS:
            return {
                ...state,
                type_demands_etape_traitements_loading: false,
                type_demands_etape_traitements: action.payload
            };

        case constants.TYPE_DEMANDS_ETAPE_TRAITEMENTS_LIST_FAILED:
            return {
                ...state,
                type_demands_etape_traitements_loading: false,
                type_demands_etape_traitements_error:action.error
            };


            //type de demands par pays
        case constants.TYPE_DEMANDS_PAYS_LIST_REQUESTING:
            return {
                ...state,
                type_demands_pays_loading: true,
            };

        case constants.TYPE_DEMANDS_PAYS_LIST_SUCCESS:
            return {
                ...state,
                type_demands_pays_loading: false,
                type_demands_pays: action.payload
            };

        case constants.TYPE_DEMANDS_PAYS_LIST_FAILED:
            return {
                ...state,
                type_demands_pays_loading: false,
                type_demands_pays_error:action.error
            };

        default:
            return state;
    }
}
export default typeDemandsReducer
