export const FONDATION = [
  {
        name: "nom",
        question: "Quel est le nom du client ?",
        description: "",
        type: "simple",
        options: [
            // {value: "Veuillez saisir votre nom", price: ""},
        ],
    },
  {
        name: "prenom",
        question: "Quel est le prénom(s) du client ?",
        description: "",
        type: "simple",
        options: [
            // {value: "Veuillez saisir votre prénom(s)", price: ""},
        ],
    },
  {
        name: "numero-telephone",
        question: "Quel est son numéro téléphonique (Whatsapp) ?",
        description: "",
        type: "simple",
        options: [
            // {value: "Veuillez saisir votre numéro de téléphone (Whatsapp) ", price: ""},
        ],
    },
  {
        name: "email",
        question: "Quelle est son adresse mail ?",
        description: "",
        type: "simple",
        options: [
            // {value: "Veuillez saisir votre adresse mail", price: ""},
        ],
    },
  {
    name: "denomination",
    question: "Quel est le nom de la fondation ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
  {
    name: "localisation-ville",
    question: "Dans quelle commune se situe la fondation ?",
    description:
        "Il faudra impérativement une adresse physique et postale pour la fondation.",
    type: "mutiple", //"mutiple",
    options: [
      { value: "Abidjan", price: "" },
      { value: "Hors d'Abidjan", price: "" },
    ],
  },
  {
    name: "localisation-siege",
    question: "Quelle est la localisation de la fondation ?",
    description:
        "Il faudra impérativement une adresse physique et postale pour la fondation.",
    type: "mutiple", //"mutiple",
    options: [
        { value: "A mon domicile", price: "" },
        // { value: "Je domicilie à Legafrik (30 000 FCFA/mois)", price: "" },
        { value: "Je loue un local commercial", price: "" },
        { value: "Je n'ai pas de siège", price: "" },
    ],
  },
  {
        name: "gestion-domiciliation",
        question: "Vous avez choisi de domicilier la fondation chez Legafrik?",
        description: "Choisissez une formule",
        type: "mutiple", //"mutiple",
        options: [
            { value: "6 mois à 180 000 FCFA(30 000 F/MOIS)", price: 180_000 },
            { value: "12 mois à 300 000 FCFA (25 000 F/MOIS)", price: 300_000 },
        ],
    }
];