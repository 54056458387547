import * as constants from "./constants";

export const  adminsListRequest =(payload)=> {
    return { type: constants.ADMINS_LIST_REQUESTING,payload}
}

export const adminsListSuccess=(payload)=>{
    return { type:constants.ADMINS_LIST_SUCCESS, payload }
}

export const adminsListFailed=(error)=>{
    return { type:constants.ADMINS_LIST_FAILED, error }
}

export const  adminsAddRequest =(payload)=> {
    return { type: constants.ADMINS_ADD_REQUESTING, payload}
}

export const adminsAddSuccess=(payload)=>{
    return { type:constants.ADMINS_ADD_SUCCESS, payload }
}

export const adminsAddFailed=(error)=>{
    return { type:constants.ADMINS_ADD_FAILED, error }
}


export const  adminsEditRequest =(payload)=> {
    return { type: constants.ADMINS_EDIT_REQUESTING, payload}
}

export const adminsEditSuccess=(payload)=>{
    return { type:constants.ADMINS_EDIT_SUCCESS, payload }
}

export const adminsEditFailed=(error)=>{
    return { type:constants.ADMINS_EDIT_FAILED, error }
}

export const adminsSetData=(payload)=>{
    return { type:constants.ADMINS_SET_DATA, payload }
}

export const  courierListRequest =()=> {
    return { type: constants.COURIERS_LIST_REQUESTING}
}

export const courierListSuccess=(payload)=>{
    return { type:constants.COURIERS_LIST_SUCCESS, payload }
}

export const courierListFailed=(error)=>{
    return { type:constants.COURIERS_LIST_FAILED, error }
}

export const  adminsPaysListRequest =(payload)=> {
    return { type: constants.ADMINS_PAYS_LIST_REQUESTING, payload}
}
export const adminsPaysListSuccess=(payload)=>{
    return { type:constants.ADMINS_PAYS_LIST_SUCCESS, payload }
}
export const adminsPaysListFailed=(error)=>{
    return { type:constants.ADMINS_PAYS_LIST_FAILED, error }
}