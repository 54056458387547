import { Utils } from "../utils";
export function InfoItem(props) {
    const {stringifyBool, parseArray} = Utils.String

    return (
        <div className="relative sm:flex block items-center py-1">
            <div className="mr-auto">
                <div className="font-medium">{props.attribute ?? ""} :</div> 
            </div>
            <div className="text-gray-600 mr-5 sm:mr-5">
                {parseArray(stringifyBool(props.value ?? ""))}
            </div>
        </div>
    )
}