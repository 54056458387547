import * as constants from "./constants";

export const  typePiecesListRequest =()=> {
    return { type: constants.TYPE_PIECES_LIST_REQUESTING}
}

export const typePiecesListSuccess=(payload)=>{
    return { type:constants.TYPE_PIECES_LIST_SUCCESS, payload }
}

export const typePiecesListFailed=(error)=>{
    return { type:constants.TYPE_PIECES_LIST_FAILED, error }
}

//By type de demands
export const  piecesByTypeDemandsRequest =(payload)=> {
    return { type: constants.PIECES_BY_TYPE_DEMANDS_REQUESTING,payload}
}
export const piecesByTypeDemandsSuccess=(payload)=>{
    return { type:constants.PIECES_BY_TYPE_DEMANDS_SUCCESS, payload }
}
export const piecesByTypeDemandsFailed=(error)=>{
    return { type:constants.PIECES_BY_TYPE_DEMANDS_FAILED, error }
}
