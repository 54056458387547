/** @format */
import axios from 'axios'
import authHeader, {authHeaderPDF,authHeaderOffline} from "./token.interceptor";
const API_URL= process.env.REACT_APP_HOST+'/api/';
class ApiService {


    _get(endpoint, params, reqOpts={headers: authHeader()}) {

      if (params) {
          reqOpts.params = params
      }

      return axios.get(`${API_URL}`+endpoint, reqOpts)
}


    _get_paginate(endpoint, params, reqOpts={headers: authHeader()}) {

        if (params) {
            reqOpts.params = params
        }

        return axios.get(endpoint, reqOpts)
    }

  _post(endpoint, body, reqOpts={headers: authHeader()}) {
    return axios.post(`${API_URL}`+endpoint, body, reqOpts)
  }
  _put(endpoint, body, reqOpts={headers: authHeader()}) {
    return axios.put(`${API_URL}`+endpoint, body, reqOpts)
  }

  _patch(endpoint, body, reqOpts={headers: authHeader()}) {
   return axios.patch( `${API_URL}`+endpoint, body, reqOpts)
  }

  _delete(endpoint, reqOpts={headers: authHeader()}) {
   return axios.delete( `${API_URL}`+endpoint, reqOpts)
  }

  _postOffline(endpoint, body, reqOpts={headers: authHeaderOffline()}) {
        return axios.post(`${API_URL}`+endpoint, body, reqOpts)
  }

  _get_download(endpoint, params, reqOpts={responseType: 'arraybuffer',headers: authHeaderPDF()}) {

        if (params) {
            reqOpts.params = params
        }

        return axios.get(endpoint, reqOpts)
  }

  _uploadFileWithProgress(endpoint, formData, onProgress) {
        const token = JSON.parse(localStorage.getItem('token'));
        return new Promise((resolve, reject) => {
            axios
                .post(`${API_URL}` + endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                         authorization: token ? `Bearer ${token}` : "",
                    },
                    onUploadProgress: function (progressEvent) {
                        let percentCompleted = progressEvent.loaded / progressEvent.total;
                        onProgress(percentCompleted);
                    },
                })
                .then(responseJson => {
                    resolve(responseJson);
                })
                .catch(reject);
        });
    }
}

export default ApiService
