import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
const _adminsService = new AdminsService();

/**
 * @param loginPayload
 */
function* listPaysSaga(loginPayload) {
     const url ='pays'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.paysListSuccess(response?.data?.pays));
    } catch (error) {
        yield put(constants.paysListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

export default function* paysSaga() {
    yield takeEvery(Actions.PAYS_LIST_REQUESTING, listPaysSaga);
}
