export const DASHBOARD = "/";
export const LOGIN = "/auth/login";
export const FORGOT_PASSWORD = "/auth/forgot-Password";
export const NOTFOUND = "/notfound";
export const CHANGE_PASSWORD = "/auth/change-Password/:token/account";
export const ADMINS_LIST = "/admins/:type/list";
export const CUSTOMERS_LIST = "/customers-list";
export const ADMIN_NOTIFICATION = "/admin-notification";
export const ADMIN_SETTINGS = "/admin-settings";
export const ADMINS_ADD = "/add-admins";
export const ADMINS_EDIT = "/admins/:id/edit";
export const ADMINS_DETAIL = "/admins/:id/detail";
export const LEADS_LIST_TYPE = "/leads/:type_lead/list";
export const DEMANDS_PAYMENT = "/demands-payment/info";
export const DEMANDS_CREATE_PAYMENT = "/demands-payment/create/:id_demand";
export const DETAIL_LEADS = "/leads/:id/detail";
export const EDIT_LEADS = "/leads/:id/edit";
export const DEMANDS_DETAIL = "/demands/:id/detail";
export const DOCUMENT_PROCESSING_STEP = "/document-processing/:id/step";
export const EDIT_QUESTIONNAIRE = "/document-processing/:id/edit-quesionnaire";
export const ROLES_LIST = "/roles-list";
export const ADMIN_CHANGE_PASSWORD = "/admin/change-Password";
export const ARTICLE_LIST = "/articles-list";
export const ARTICLE_ADD = "/articles/add";
export const ARTICLE_EDIT = "/articles/:id/edit";
export const ARTICLE_DETAIL = "/articles/:id/detail";
export const RECOUVREMENT_LIST = "/recouvrements-list";


//POS route link
export const AGENCES_LIST = "/agences/list";
export const AGENCE_CREATE = "/agences/create";
export const ORGANIZATIONS = "/organizations";
export const USERS_LIST = "/users/list";
export const USER_CREATE = "/user/create";
export const USER_EDIT = "/user/:id/edit";
export const DEMANDS_LIST = "/demands/list";

export const SUB_ORGANISATIONS_LIST = "/sub-organization/list";
export const SUB_ORGANISATIONS_CREATE = "/sub-organization/create";

export const DEMANDE_CREATE = "/demandes/:type/:id";
export const DEMANDE_TYPE = "/demandes/type";
export const SALES_LIST = "/sales/list";
export const LOGS_LIST = "/logs/list";
export const SETTINGS = "/settings";
export const AIDE = "/aide";
export const PROFILE = "/settings/profile";
export const PASSWORD = "/settings/password";

export const COMMISSIONS_LIST = "/commissions/list";
export const COMMISSION_CREATE = "/commission/create";
export const COMMISSION_WITHDRAWAL = "/commission/withdrawal";