export const DOMICILIATION = [
    {
        name: "denomination",
        question: "Quel est le nom de l’entreprise que vous domicilier ?",
        description: "",
        type: "simple", //"mutiple",
        options: [
          // {value: "", price: ""}
        ],
      },
]