import * as constants from "./constants";

export const initialState = {
    admins_loading: false,
    admins_list_loading: false,
    admins_activate_loading: false,
    admins_update_loading: false,
    data: [],
    admin:null,
    admin_error:null,
    error_lists:null,
    admins_edit_loading:false,
    admins_edit:null,
    admin_edit_error:null,
    admin_set_data:null,
    //courier list
    //demands courier
    couriers:[],
    couriers_error_lists:null,
    couriers_list_loading: false,
    //admins par pays
    admins_pays_list_loading: false,
    admins_pays_list:[],
    admins_pays_list_error:null
}

const adminsReducer = (state = initialState, action)=> {
    switch (action.type) {
        // Lister les utilisateurs
        case constants.ADMINS_LIST_REQUESTING:
            return {
                ...state,
                admins_list_loading: true,
            };

        case constants.ADMINS_LIST_SUCCESS:

            return {
                ...state,
                admins_list_loading: false,
                data: action.payload
            };

        case constants.ADMINS_LIST_FAILED:
            return {
                ...state,
                admins_list_loading: false,
                error_lists:action.error
            };

        //     Ajout d'un administrateur
        case constants.ADMINS_ADD_REQUESTING:
            return {
                ...state,
                admins_loading: true,
            };

        case constants.ADMINS_ADD_SUCCESS:

            return {
                ...state,
                admins_loading: false,
                admin: action.payload
            };

        case constants.ADMINS_ADD_FAILED:

            return {
                ...state,
                admins_loading: false,
                admin_error:action.error
            };

        //     Editer un administrateur
        case constants.ADMINS_EDIT_REQUESTING:
            return {
                ...state,
                admins_edit_loading: true,
            };

        case constants.ADMINS_EDIT_SUCCESS:

            return {
                ...state,
                admins_edit_loading: false,
                admins_edit: action.payload
            };

        case constants.ADMINS_EDIT_FAILED:

            return {
                ...state,
                admins_edit_loading: false,
                admin_edit_error:action.error
            };

        case constants.ADMINS_SET_DATA:
            return {
                ...state,
                admin_set_data: action.payload,
            };


        case constants.COURIERS_LIST_REQUESTING:
            return {
                ...state,
                couriers_list_loading: true,
            };

        case constants.COURIERS_LIST_SUCCESS:
            return {
                ...state,
                couriers_list_loading: false,
                couriers: action.payload
            };

        case constants.COURIERS_LIST_FAILED:
            return {
                ...state,
                couriers_list_loading: false,
                couriers_error_lists:action.error
            };

        // Lister les utilisateurs par pays
        case constants.ADMINS_PAYS_LIST_REQUESTING:
            return {
                ...state,
                admins_pays_list_loading: true,
            };

        case constants.ADMINS_PAYS_LIST_SUCCESS:
            return {
                ...state,
                admins_pays_list_loading: false,
                admins_pays_list: action.payload
            };

        case constants.ADMINS_PAYS_LIST_FAILED:
            return {
                ...state,
                admins_pays_list_loading: false,
                admins_pays_list_error:action.error
            };

        default:
            return state;
    }
}
export default adminsReducer
