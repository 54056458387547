import { Components } from ".";

export function QuestionnaireSteps(props) {
  const CODE_PAYS = props.useDemande.pays?.code ?? "";

  if (CODE_PAYS === "CI") {
    return (
        <Components.QuestionnaireCiSteps data={props} />
    );
  } else if (CODE_PAYS === "SN") {
    return (
        <Components.QuestionnaireSnSteps data={props} />
    );
  } else if (CODE_PAYS === "BN") {
    return (
        <Components.QuestionnaireBnSteps data={props} />
    );
  } else if (CODE_PAYS === "BF") {
    return (
        <Components.QuestionnaireBfSteps data={props} />
    );
  } else if (CODE_PAYS === "MR") {
    return (
        <Components.QuestionnaireMaSteps data={props} />
    );
  } else if (CODE_PAYS === "RDC") {
    return (
        <Components.QuestionnaireRdcSteps data={props} />
    );
  }

  return <div>Pas de questionnaire</div>;
}
