import * as constants from "./constants";

export const initialState = {
    demands_loading: false,
    demands_detail_loading: false,
    demands_list_loading: false,
    demands_payments_list_loading: false,
    demands_pieces_list_loading: false,
    demands_documents_list_loading: false,
    demands_activate_loading: false,
    demands_update_loading: false,
    data: [],
    demands:null,
    demands_payments: null,
    demands_pieces: [],
    demands_documents:[],
    demands_observations:[],
    demands_detail: null,
    demands_detail_error:null,
    error_lists:null,
    payments_error_lists:null,
    documents_error_lists:null,
    observations_error_lists:null,
    pieces_error_lists:null,
    //delete pieces
    demands_delete_piece_loading: false,
    demands_delete_piece: null,
    demands_delete_piece_error:null,
    //add document
    demands_add_document_loading: false,
    demands_add_document: null,
    demands_add_document_error:null,

    //edit document
    demands_edit_document_loading: false,
    demands_edit_document: null,
    demands_edit_document_error:null,
    //delete document
    demands_delete_document_loading: false,
    demands_delete_document: null,
    demands_delete_document_error:null,
    //status etat de traiment d'une demande
    demands_status_etat_traitement_lists_loading: false,
    demands_status_etat_traitement_lists: null,
    demands_status_etat_traitement_lists_error:null,
    //Demande step Enregistrement au CEPICI
    demands_step_cepici_data:null,
    //Demande step Obtention d'une DFE
    demands_step_obtention_dfe_data:null,
    //Ajout de document complement
    demands_document_complementaire_loading: false,
    demands_document_complementaire: null,
    demands_document_complementaire_error:null,
    //Demandes par pays
    demands_pays_list_loading: false,
    demands_pays_list: [],
    demands_pays_list_error:null,

    //edit questionnaire
    demands_edit_questionnaire_loading: false,
    demands_edit_questionnaire: null,
    demands_edit_questionnaire_error:null,

    //Type de demandes
    demands_type_list_loading: false,
    demands_type_list: [],
    demands_type_list_error:null,

    //create demande lead
    demands_create_lead_loading: false,
    demands_create_lead: null,
    demands_create_lead_error:null,

    //create hubspot lead
    create_hubspot_lead_loading: false,
    create_hubspot_lead: null,
    create_hubspot_lead_error:null,

    //demands payment
    demands_payment_loading: false,
    demands_payment: null,
    demands_payment_error:null,


    //verification user
    verification_user_loading: false,
    verification_user: null,
    verification_user_error:null,

}

const demandsReducer = (state = initialState, action)=> {
    switch (action.type) {

        case constants.DEMANDS_LIST_REQUESTING:
            return {
                ...state,
                demands_list_loading: true,
            };

        case constants.DEMANDS_LIST_SUCCESS:

            return {
                ...state,
                demands_list_loading: false,
                data: action.payload,
                error_lists:null
            };

        case constants.DEMANDS_LIST_FAILED:

            return {
                ...state,
                demands_list_loading: false,
                error_lists:action.error,
                data:[]
            };

        case constants.DEMANDS_PAYMENTS_LIST_REQUESTING:
            return {
                ...state,
                demands_payments_list_loading: true,
            };
        case constants.DEMANDS_PAYMENTS_LIST_SUCCESS:

            return {
                ...state,
                demands_payments_list_loading: false,
                demands_payments: action.payload
            };

        case constants.DEMANDS_PAYMENTS_LIST_FAILED:

            return {
                ...state,
                demands_payments_list_loading: false,
                payments_error_lists:action.error
            };

        case constants.DEMANDS_PAYMENTS_RESET_LIST:
            return {
                ...state,
                demands_payments_list_loading: false,
                demands_payments: []
            };

        case constants.DEMANDS_PIECES_LIST_REQUESTING:
            return {
                ...state,
                demands_pieces_list_loading: true,
            };

        case constants.DEMANDS_PIECES_LIST_SUCCESS:
            return {
                ...state,
                demands_pieces_list_loading: false,
                demands_pieces: action.payload
            };

        case constants.DEMANDS_PIECES_LIST_FAILED:
            return {
                ...state,
                demands_pieces_list_loading: false,
                pieces_error_lists:action.error
            };

        case constants.DEMANDS_DOCUMENTS_LIST_REQUESTING:
            return {
                ...state,
                demands_documents_list_loading: true,
            };

        case constants.DEMANDS_DOCUMENTS_LIST_SUCCESS:
            return {
                ...state,
                demands_documents_list_loading: false,
                demands_documents: action.payload
            };

        case constants.DEMANDS_DOCUMENTS_LIST_FAILED:
            return {
                ...state,
                demands_documents_list_loading: false,
                documents_error_lists:action.error
            };

        case constants.DEMANDS_OBSERVATIONS_LIST_REQUESTING:
            return {
                ...state,
                demands_observations_list_loading: true,
            };

        case constants.DEMANDS_OBSERVATIONS_LIST_SUCCESS:
            return {
                ...state,
                demands_observations_list_loading: false,
                demands_observations: action.payload
            };

        case constants.DEMANDS_OBSERVATIONS_LIST_FAILED:
            return {
                ...state,
                demands_observations_list_loading: false,
                observations_error_lists:action.error
            };

        //     Voir detail user
        case constants.DEMANDS_DETAIL_REQUESTING:
            return {
                ...state,
                demands_detail_loading: true,
            };

        case constants.DEMANDS_DETAIL_SUCCESS:

            return {
                ...state,
                demands_detail_loading: false,
                demands_detail: action.payload
            };

        case constants.DEMANDS_DETAIL_FAILED:
            return {
                ...state,
                demands_detail_loading: false,
                demands_detail_error:action.error
            };

          //suppression de fichier

        case constants.DEMANDS_DELETE_PIECE_REQUESTING:
            return {
                ...state,
                demands_delete_piece_loading: true,
            };
        case constants.DEMANDS_DELETE_PIECE_SUCCESS:

            return {
                ...state,
                demands_delete_piece_loading: false,
                demands_delete_piece: action.payload
            };
        case constants.DEMANDS_DELETE_PIECE_FAILED:
            return {
                ...state,
                demands_delete_piece_loading: false,
                demands_delete_piece_error:action.error
            };

        case constants.DEMANDS_ADD_DOCUMENT_REQUESTING:
            return {
                ...state,
                demands_add_document_loading: true,
            };

        case constants.DEMANDS_ADD_DOCUMENT_SUCCESS:
            return {
                ...state,
                demands_add_document_loading: false,
                demands_add_document: action.payload
            };

        case constants.DEMANDS_ADD_DOCUMENT_FAILED:
            return {
                ...state,
                demands_add_document_loading: false,
                demands_add_document_error:action.error
            };

        case constants.DEMANDS_EDIT_DOCUMENT_REQUESTING:
            return {
                ...state,
                demands_edit_document_loading: true,
            };

        case constants.DEMANDS_EDIT_DOCUMENT_SUCCESS:
            return {
                ...state,
                demands_edit_document_loading: false,
                demands_edit_document: action.payload
            };

        case constants.DEMANDS_EDIT_DOCUMENT_FAILED:
            return {
                ...state,
                demands_edit_document_loading: false,
                demands_edit_document_error:action.error
            };

        case constants.DEMANDS_DELETE_DOCUMENT_REQUESTING:
            return {
                ...state,
                demands_delete_document_loading: true,
            };

        case constants.DEMANDS_DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                demands_delete_document_loading: false,
                demands_delete_document: action.payload
            };

        case constants.DEMANDS_DELETE_DOCUMENT_FAILED:
            return {
                ...state,
                demands_delete_document_loading: false,
                demands_delete_document_error:action.error
            };
      //Statut etat de traitement liste
        case constants.DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_REQUESTING:
            return {
                ...state,
                demands_status_etat_traitement_lists_loading: true,
            };

        case constants.DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_SUCCESS:

            return {
                ...state,
                demands_status_etat_traitement_lists_loading: false,
                demands_status_etat_traitement_lists: action.payload
            };

        case constants.DEMANDS_STATUS_ETAT_TRAITEMENT_LIST_FAILED:

            return {
                ...state,
                demands_status_etat_traitement_lists_loading: false,
                demands_status_etat_traitement_lists_error:action.error
            };

        case constants.DEMANDS_STEP_CEPICI_DATA:
            return {
                ...state,
                demands_step_cepici_data: action.payload,
            };

        case constants.DEMANDS_STEP_OBTENTION_DFE_DATA:
            return {
                ...state,
                demands_step_obtention_dfe_data: action.payload,
            };

        //Ajout de documents complement
        case constants.DEMANDS_DOCUMENT_COMPLEMENTAIRE_REQUESTING:
            return {
                ...state,
                demands_document_complementaire_loading: true,
            };

        case constants.DEMANDS_DOCUMENT_COMPLEMENTAIRE_SUCCESS:
            return {
                ...state,
                demands_document_complementaire_loading: false,
                demands_document_complementaire: action.payload
            };

        case constants.DEMANDS_DOCUMENT_COMPLEMENTAIRE_FAILED:
            return {
                ...state,
                demands_document_complementaire_loading: false,
                demands_document_complementaire_error:action.error
            };

            //Demandes par pays
        case constants.DEMANDS_PAYS_LIST_REQUESTING:
            return {
                ...state,
                demands_pays_list_loading: true,
            };

        case constants.DEMANDS_PAYS_LIST_SUCCESS:

            return {
                ...state,
                demands_pays_list_loading: false,
                demands_pays_list: action.payload,
                demands_pays_list_error:null
            };

        case constants.DEMANDS_PAYS_LIST_FAILED:

            return {
                ...state,
                demands_pays_list_loading: false,
                demands_pays_list_error:action.error,
                demands_pays_list:[]
            };

            //Demandes type list
        case constants.DEMANDS_TYPE_LIST_REQUESTING:
            return {
                ...state,
                demands_type_list_loading: true,
            };


        case constants.DEMANDS_TYPE_LIST_SUCCESS:

            return {
                ...state,
                demands_type_list_loading: false,
                demands_type_list: action.payload,
                demands_type_list_error:null
            };

        case constants.DEMANDS_TYPE_LIST_FAILED:

            return {
                ...state,
                demands_type_list_loading: false,
                demands_type_list_error:action.error,
                demands_type_list:[]
            };


        //Demande create lead
        case constants.CREATE_DEMANDS_REQUESTING:
            return {
                ...state,
                demands_create_lead_loading: true,
            };
        case constants.CREATE_DEMANDS_SUCCESS:
            return {
                ...state,
                demands_create_lead_loading: false,
                demands_create_lead: action.payload
            };
        case constants.CREATE_DEMANDS_FAILED:
            return {
                ...state,
                demands_create_lead_loading: false,
                demands_create_lead_error:action.error,
                demands_create_lead:null
            };

        //Hubspot create lead
        case constants.CREATE_HUBSPOT_LEADS_REQUESTING:
            return {
                ...state,
                create_hubspot_lead_loading: true,
            };
        case constants.CREATE_HUBSPOT_LEADS_SUCCESS:
            return {
                ...state,
                create_hubspot_lead_loading: false,
                create_hubspot_lead: action.payload
            };
        case constants.CREATE_HUBSPOT_LEADS_FAILED:
            return {
                ...state,
                create_hubspot_lead_loading: false,
                create_hubspot_lead_error:action.error,
                create_hubspot_lead:null
            };

        //Demands payment
        case constants.DEMANDS_PAYMENT_REQUESTING:
            return {
                ...state,
                demands_payment_loading: true,
            };
        case constants.DEMANDS_PAYMENT_SUCCESS:
            return {
                ...state,
                demands_payment_loading: false,
                demands_payment: action.payload
            };
        case constants.DEMANDS_PAYMENT_FAILED:
            return {
                ...state,
                demands_payment_loading: false,
                demands_payment_error:action.error,
                demands_payment:null
            };


        //Verification User
        case constants.VERIFICATION_USER_REQUESTING:
            return {
                ...state,
                verification_user_loading: true,
            };
        case constants.VERIFICATION_USER_SUCCESS:
            return {
                ...state,
                verification_user_loading: false,
                verification_user: action.payload
            };
        case constants.VERIFICATION_USER_FAILED:
            return {
                ...state,
                verification_user_loading: false,
                verification_user_error:action.error,
                verification_user:null
            };
        default:
            return state;
    }
}
export default demandsReducer
