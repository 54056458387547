export const CDD = [
  {
    name: "type-employeur",
    question: "L’employeur est-il une entreprise ou un particulier ?",
    description: "",
    type: "mutiple",
    options : [
      {value: "Entreprise", price: ""},
      {value: "Particulier", price: ""},
    ]
  },
];
