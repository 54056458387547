import {put, call, takeEvery} from 'redux-saga/effects';
import  * as constants from "./actions";
import * as Actions from "./constants";
import {toast} from "react-toastify";
import AdminsService from "../../api/services/admins.service";
import  * as ActionsModal from "../modal/actions";
import * as navigations from "../../utils/navigation";

const _adminsService = new AdminsService();
/**
 * @param loginPayload
 */
function* listPaymentsSaga(loginPayload) {
     //const {payload}=loginPayload
     const url ='admin/paiements'
    try {
        const response = yield call(_adminsService.geList,url);
        yield put(constants.paymentsListSuccess(response?.data?.admins));
    } catch (error) {
        yield put(constants.paymentsListFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* PaymentsAddSaga(loginPayload) {
    const {payload,history}=loginPayload
    const url ='admin/paiements'
    try {
        const response = yield call(_adminsService.post,url,payload);
        yield put(constants.paymentsAddSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(history,navigations.DEMANDS_PAYMENT);
        yield call(toast.success,"Le versement a été ajouté avec succès")
    } catch (error) {
        yield put(constants.paymentsAddFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

function* PaymentsEditSaga(loginPayload) {
    const {payload}=loginPayload
    const url =`admin/paiements/${payload.id}`
    const send_data ={
        username: payload.username,
        email: payload?.email,
        role_id: payload?.role_id,
        profile_url: "",
    }

    try {
        const response = yield call(_adminsService.put,url,send_data);
        yield put(constants.paymentsEditSuccess(response?.data));
        yield put(ActionsModal.hideModal());
        payload.resetForm()
        yield call(toast.success,"Le versement a été modifié avec succès")
    } catch (error) {
        yield put(constants.paymentsEditFailed(error));
        yield call(toast.error,"Un problème est survenu veuillez contacter le service client.")
    }
}

export default function* paymentsSaga() {
    yield takeEvery(Actions.PAYMENTS_LIST_REQUESTING, listPaymentsSaga);
    yield takeEvery(Actions.PAYMENTS_ADD_REQUESTING, PaymentsAddSaga);
    yield takeEvery(Actions.PAYMENTS_EDIT_REQUESTING, PaymentsEditSaga);
}
