export const ONG = [
  {
    name: "denomination",
    question: "Quel est le nom de votre ONG ?",
    description: "",
    type: "simple", //"mutiple",
    options: [
      // {value: "", price: ""}
    ],
  },
];
