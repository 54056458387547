import { useState } from "react"
import Alert from 'react-bootstrap/Alert';
import * as Icons from 'react-feather';
import PhoneInput from "react-phone-input-2";

export function AdresseOneStep(props) {
    const [adresse, setAdresse] = useState(props.adresse);
    const handleInputChange = (name, value) => {
        const adresseCopy = {...adresse}       
        adresseCopy[name] = value;
        setAdresse(adresseCopy);
    }

    const handleStepSubmit = e => {
        e.preventDefault();

        props.handleNextClick(e, 'adresse', JSON.stringify(adresse));
    }

    return (
        <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? 'hidden' : ''}`}>
            <div className="intro-y col-span-12  ">
                <strong>Quelle est l’adresse de la société ?</strong>
            </div>
            <div className="intro-y col-span-12  ">
                <Alert variant='danger'>
                    <Alert.Heading>
                        <Icons.Info className="w-4 h-4 "/>
                    </Alert.Heading>
                    <Alert.Link href="#">L’adresse de votre siège social </Alert.Link>
                    est tout simplement l'adresse géographique de vos bureaux. Si vous
                    n’avez pas encore de bureaux officiels, vous pouvez utiliser le
                    domicile du président comme siège et mentionner son adresse
                </Alert>
                <small>
                    Indiquez les informations relatives à l'adresse géographique
                    du siège social
                </small>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="my-2">Civilite du proprietaire</div>
                <select type="text" value={adresse['civilite-proprietaire']}
                        className="input w-full border flex-1 field"
                        onChange={e => handleInputChange('civilite-proprietaire', e.target.value)}>
                    <option value="monsieur">Monsieur</option>
                    <option value="madame">Madame</option>
                    <option value="mademoiselle">Mademoiselle</option>
                </select>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Nom du propriétaire du local</div>
                <input type="text" value={adresse['nom-proprietaire']} className="input w-full border flex-1"
                       placeholder="Nom proprietaire" onChange={e =>
                    handleInputChange('nom-proprietaire', e.target.value)}/>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Prénom(s) du propriétaire du local</div>
                <input type="text" value={adresse['prenoms-proprietaire']} className="input w-full border flex-1"
                       placeholder="Prenom proprietaire" onChange={e =>
                    handleInputChange('prenoms-proprietaire', e.target.value)}/>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de téléphone du propriétaire du local</div>
                <PhoneInput
                    country={"ci"}
                    // value={this.e.target}
                    value={adresse['numero-telephone-proprietaire']}
                    onChange={(e) =>
                        handleInputChange('numero-telephone-proprietaire', e)
                    }
                    autoFormat={false}
                    inputStyle={{
                        padding: "21px1 20px 21px 52px",
                        width: "100%",
                        boxShadow: "0px 3px 5px #00000007",
                        borderColor: "rgba(226, 232, 240, 1)",
                    }}
                    placeholder="Numéro de téléphone"
                />

                {/*                <input type="tel" value={adresse['numero-telephone-proprietaire']}
                       className="input w-full border flex-1"
                       placeholder="Numéro téléphone" onChange={e =>
                    handleInputChange('numero-telephone-proprietaire', e.target.value)}/>*/}

            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Ville</div>
                <input type="text" value={adresse?.ville} className="input w-full border flex-1"
                       placeholder="Ville" onChange={e =>
                    handleInputChange('ville', e.target.value)}/>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Quartier</div>
                <input type="text" value={adresse?.quartier} className="input w-full border flex-1"
                       placeholder="Quartier" onChange={e =>
                    handleInputChange('quartier', e.target.value)}/>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Commune</div>
                <input type="text" value={adresse?.commune} className="input w-full border flex-1"
                       placeholder="Commune" onChange={e =>
                    handleInputChange('commune', e.target.value)}/>
            </div>


            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de boîte postale</div>
                <input type="text" value={adresse['numero-boite-postale']} className="input w-full border flex-1"
                       placeholder="Numéro boite postale" onChange={e =>
                    handleInputChange('numero-boite-postale', e.target.value)}/>
            </div>

          

         


            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Numéro de la carte d’identité du propriétaire du local</div>
                <input type="tel" value={adresse['numero-piece-proprietaire']} className="input w-full border flex-1"
                       placeholder="Numéro de la carte d’identité" onChange={e =>
                    handleInputChange('numero-piece-proprietaire', e.target.value)}/>
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Adresse mail du propriétaire</div>
                <input type="email" value={adresse['adresse-email-proprietaire']} className="input w-full border flex-1" placeholder="Ex: vehnickgoueu@gmail.com" onChange={e => handleInputChange('adresse-email-proprietaire', e.target.value)}/>
            </div>


            <div className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10">
                <button className="button w-24 justify-center block bg-gray-200 
                text-gray-600 dark:bg-dark-1 dark:text-gray-300"
                        onClick={props.handleQuestionnaireBackClick ?? null}>
                    Revenir
                </button>
                <button className="button w-24 justify-center block bg-theme-1 
                text-white ml-2" onClick={handleStepSubmit}>
                    Suivant
                </button>
            </div>
        </div>
    )
}