import { useState } from "react";
import PhoneInput from "react-phone-input-2";

export function Commissaire(props) {
  const [commissaire, setCommissaire] = useState(
    Object.keys(props.commissaire).length === 0
      ? {}
      : JSON.parse(props.commissaire)
  );

  // useState(JSON.parse(props.commissaire??'{}'));

  const handleInputChange = (name, value) => {
    const commissaireCopy = { ...commissaire };
    commissaireCopy[name] = value;

    setCommissaire(commissaireCopy);
  };
  const handleStepSubmit = (e) => {
    e.preventDefault();
    props.handleNextClick(e, "commissaire", JSON.stringify(commissaire));
  };

  return (
    <div
      className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12 
        ${props.step !== props.index ? "hidden" : ""}`}
    >
      <div className="intro-y col-span-12  ">
        <strong>Quels sont les membres du Commissariat aux Comptes ?</strong>
      </div>
      <div className="intro-y col-span-12  "></div>
      <div className="intro-y col-span-6">
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Nom et Prénom(s) du Commissaire aux Comptes ?
          </div>
          <input
            type="text"
            value={commissaire["nom-commissaire"]}
            className="input w-full border flex-1"
            placeholder="Nom et Prénom(s)"
            onChange={(e) =>
              handleInputChange("nom-commissaire", e.target.value)
            }
          />
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">Nationalité du Commissaire aux Comptes ?</div>
          <input
            type="text"
            value={commissaire["nationalite"]}
            className="input w-full border flex-1"
            placeholder="Nationalité du Commissaire"
            onChange={(e) => handleInputChange("nationalite", e.target.value)}
          />
        </div>

        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Numéro de téléphone du Commissaire aux Comptes ?
          </div>
          <PhoneInput
            country={"ci"}
            // value={this.e.target}
            value={commissaire["numero-commissaire"]}
            onChange={(e) => handleInputChange("numero-commissaire", e)}
            autoFormat={false}
            inputStyle={{
              padding: "21px1 20px 21px 52px",
              width: "100%",
              boxShadow: "0px 3px 5px #00000007",
              borderColor: "rgba(226, 232, 240, 1)",
            }}
            placeholder="Numéro de téléphone"
          />
          {/*                <input type="text" value={commissaire['numero-commissaire']} className="input w-full border flex-1"
                placeholder="Numéro de téléphone" onChange={e => 
                handleInputChange('numero-commissaire', e.target.value)}/>*/}
        </div>
      </div>

      <div className="intro-y col-span-6">
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Nom et Prénom(s) du Commissaire aux Comptes Adjoint ?
          </div>
          <input
            type="text"
            value={commissaire["nom-commisaire-adjoint"]}
            className="input w-full border flex-1"
            placeholder="Nom prenom proprietaire"
            onChange={(e) =>
              handleInputChange("nom-commisaire-adjoint", e.target.value)
            }
          />
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Nationalité du Commissaire aux Comptes Adjoint ?
          </div>
          <input
            type="tel"
            value={commissaire["nationalite-commissaire-adjoint"]}
            className="input w-full border flex-1"
            placeholder="Numéro téléphone"
            onChange={(e) =>
              handleInputChange(
                "nationalite-commissaire-adjoint",
                e.target.value
              )
            }
          />
        </div>
        <div className="intro-y col-span-12 sm:col-span-6">
          <div className="mb-2">
            Numéro de téléphone du Commissaire aux Comptes Adjoint ?
          </div>
          <PhoneInput
            country={"ci"}
            // value={this.e.target}
            value={commissaire["numero-commissaire-adjoint"]}
            onChange={(e) => handleInputChange("numero-commissaire-adjoint", e)}
            autoFormat={false}
            inputStyle={{
              padding: "21px1 20px 21px 52px",
              width: "100%",
              boxShadow: "0px 3px 5px #00000007",
              borderColor: "rgba(226, 232, 240, 1)",
            }}
            placeholder="Numéro de téléphone"
          />

          {/*                <input type="tel" value={commissaire['numero-commissaire-adjoint']} className="input w-full border flex-1"
                placeholder="Numéro téléphone" onChange={e => 
                handleInputChange('numero-commissaire-adjoint', e.target.value)}/>*/}
        </div>
      </div>

      <div
        className="intro-y col-span-12 flex items-center justify-center
                sm:justify-end mt-10"
      >
        <button
          className="button w-24 justify-center block bg-gray-200 text-gray-600 
                dark:bg-dark-1 dark:text-gray-300"
          onClick={props.handleQuestionnaireBackClick ?? null}
        >
          Revenir
        </button>
        <button
          className="button w-24 justify-center block bg-theme-1 
                text-white ml-2"
          onClick={handleStepSubmit}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}
