export function AssociesEdit(props) {
  const handleChange = (e, name, value, index) => {
    e.preventDefault();
    const valueCopy = [...props.associes];
    valueCopy[index][name] = value;
    props.setValue(valueCopy);
  };

  return (
    <>
      {props.associes[props.index]["type"] === "particulier" ||
      props.associes[props.index]["type"] === "moi-meme" ? (
          <div className="grid grid-cols-12 gap-4 row-gap-5 col-span-12">
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">
                Numéro de votre pièce d’identité
                <small>
                  (les anciennes pièces ne sont pas prises en compte )
                </small>
              </div>
              <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder=" Numéro de votre pièce d’identité"
                  value={props.associes[props.index]["numero-piece-identite"] ?? ""}
                  onChange={(e) =>
                      handleChange(
                          e,
                          "numero-piece-identite",
                          e.target.value,
                          props.index
                      )
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="my-2">Type de la piece d'identité</div>
              <select
                  type="text"
                  className="input w-full border flex-1 field"
                  value={props.associes[props.index]["type-piece-identite"] ?? ""}
                  onChange={(e) =>
                      handleChange(
                          e,
                          "type-piece-identite",
                          e.target.value,
                          props.index
                      )
                  }
              >
                <option value="CNI">CNI</option>
                <option value="Passport">Passport</option>
                <option value="Attestation d'identité">
                  Attestation d'identité
                </option>
                <option value="Titre de séjour">Titre de séjour</option>
                <option value="Carte de résident">Carte de résident</option>
                <option value="Autres">Autres</option>
              </select>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="my-2">Pays de délivrance de la pièce</div>
              <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Pays de délivrance de la pièce"
                  value={props.associes[props.index]["pays-piece-identite"] ?? ""}
                  onChange={(e) =>
                      handleChange(
                          e,
                          "pays-piece-identite",
                          e.target.value,
                          props.index
                      )
                  }
              />
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="my-2">Numéro de téléphone</div>
              <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Numéro de téléphone"
                  value={props.associes[props.index]["numero-telephone"] ?? ""}
                  onChange={(e) =>
                      handleChange(
                          e,
                          "numero-telephone",
                          e.target.value,
                          props.index
                      )
                  }
              />
            </div>

            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="my-2">Date d’établissement de la pièce</div>
              <input
                  type="date"
                  className="input w-full border flex-1 field"
                  placeholder="Date d’établissement de la pièce"
                  value={props.associes[props.index]["date-piece-identite"] ?? ""}
                  onChange={(e) =>
                      handleChange(
                          e,
                          "date-piece-identite",
                          e.target.value,
                          props.index
                      )
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="my-2">Date de validité de la pièce</div>
              <input
                  type="date"
                  className="input w-full border flex-1 field"
                  placeholder="Date de validité de la pièce"
                  value={
                      props.associes[props.index][
                          "date-fin-validite-piece-identite"
                          ] ?? ""
                  }
                  onChange={(e) =>
                      handleChange(
                          e,
                          "date-fin-validite-piece-identite",
                          e.target.value,
                          props.index
                      )
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="my-2">Civilité</div>
              <select
                  type="text"
                  className="input w-full border flex-1 field"
                  value={props.associes[props.index]["civilite"] ?? ""}
                  onChange={(e) =>
                      handleChange(e, "civilite", e.target.value, props.index)
                  }
              >
                <option value="monsieur">Monsieur</option>
                <option value="madame">Madame</option>
                <option value="mademoiselle">Mademoiselle</option>
              </select>
            </div>
            {props.associes[props.index]["type"] === "particulier" ? (
                <>
                  <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Nom</div>
                    <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Nom"
                        value={props.associes[props.index]["nom"] ?? ""}
                        onChange={(e) =>
                            handleChange(e, "nom", e.target.value, props.index)
                        }
                    />
                  </div>
                  <div className="intro-y col-span-12 sm:col-span-6">
                    <div className="mb-2">Prénom(s)</div>
                    <input
                        type="text"
                        className="input w-full border flex-1 field"
                        placeholder="Prénom(s)"
                        value={props.associes[props.index]["prenoms"] ?? ""}
                        onChange={(e) =>
                            handleChange(e, "prenoms", e.target.value, props.index)
                        }
                    />
                  </div>
                </>
            ) : null}
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Date de naissance</div>
              <input
                  type="date"
                  className="input w-full border flex-1 field"
                  placeholder="Date de naissance"
                  value={props.associes[props.index]["date-naissance"] ?? ""}
                  onChange={(e) =>
                      handleChange(e, "date-naissance", e.target.value, props.index)
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Ville de naissance</div>
              <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Ville de naissance"
                  value={props.associes[props.index]["lieu-naissance"] ?? ""}
                  onChange={(e) =>
                      handleChange(e, "lieu-naissance", e.target.value, props.index)
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Pays de naissance</div>
              <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Pays de naissance"
                  value={props.associes[props.index]["pays-naissance"] ?? ""}
                  onChange={(e) =>
                      handleChange(e, "pays-naissance", e.target.value, props.index)
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nationalité</div>
              <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Nationalité"
                  value={props.associes[props.index]["nationalite"] ?? ""}
                  onChange={(e) =>
                      handleChange(e, "nationalite", e.target.value, props.index)
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Adresse</div>
              <input
                  type="text"
                  className="input w-full border flex-1 field"
                  placeholder="Adresse"
                  value={props.associes[props.index]["adresse"] ?? ""}
                  onChange={(e) =>
                      handleChange(e, "adresse", e.target.value, props.index)
                  }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-4">Situation matrimoniale</div>
              <div className="flex items-center  text-gray-700 dark:text-gray-500">
                <input
                    type="radio"
                    className="input border mr-2 field"
                    id={`associe-horizontal-radio-celibataire-association-edit-${props.index}`}
                    name={`associe-situation-matrimoniale-edit`}
                    checked={props.associes[props.index]["situation-matrimoniale"] === "celibataire" ?? ""}
                    value="celibataire"
                    onChange={(e) =>
                        handleChange(
                            e,
                            "situation-matrimoniale",
                            e.target.value,
                            props.index
                        )
                    }
                />
                <label
                    className="cursor-pointer select-none  mr-3"
                    htmlFor={`associe-horizontal-radio-celibataire-association-edit-${props.index}`}
                >
                  Célibataire
                </label>
                <input
                    type="radio"
                    className="input border mr-2 field"
                    id={`associe-situation-matrimoniale-edit-${props.index}`}
                    name={`associe-situation-matrimoniale`}
                    value="marie"
                    checked={props.associes[props.index]["situation-matrimoniale"] === "marie" ?? ""}
                    onChange={(e) =>
                        handleChange(
                            e,
                            "situation-matrimoniale",
                            e.target.value,
                            props.index
                        )
                    }
                />
                <label
                    className="cursor-pointer select-none"
                    htmlFor={`associe-situation-matrimoniale-edit-${props.index}`}
                >
                  Marié-e
                </label>
              </div>
            </div>
            {/* if situation matrimonial */}
            <div className={`intro-y col-span-12 ${
                props.associes[props.index]["situation-matrimoniale"] !== undefined &&
                props.associes[props.index]["situation-matrimoniale"] === "marie"
                    ? ""
                    : "hidden"
            }`}>
              <div className="col-span-12  mb-4">Régime matrimonial</div>
              <div className="flex flex-col sm:flex-row mb-2 col-span-12">
                <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2">
                  <input
                      type="radio"
                      className="input border mr-2 field"
                      id={`associe-horizontal-radio-communaute-de-bien-association-edit-${props.index}`}
                      name={`regime-matrimoniale-edit`}
                      value="Communauté de bien"
                      checked={props.associes[props.index]["regime-matrimonial"] === "Communauté de bien"}
                      onChange={(e) =>
                          handleChange(
                              e,
                              "regime-matrimonial",
                              e.target.value,
                              props.index
                          )
                      }
                  />
                  <label
                      className="cursor-pointer select-none"
                      htmlFor={`associe-horizontal-radio-communaute-de-bien-association-edit-${props.index}`}
                  >
                    Communauté de bien
                  </label>
                </div>
                <div className="flex items-center text-gray-700 dark:text-gray-500 mr-2 mt-2 sm:mt-0">
                  <input
                      type="radio"
                      className="input border mr-2 field"
                      id={`associe-horizontal-radio-separation-de-bien-association-edit-${props.index}`}
                      name={`regime-matrimoniale-edit`}
                      value="Séparation de bien"
                      checked={props.associes[props.index]["regime-matrimonial"] === "Séparation de bien"}
                      onChange={(e) =>
                          handleChange(
                              e,
                              "regime-matrimonial",
                              e.target.value,
                              props.index
                          )
                      }
                  />
                  <label
                      className="cursor-pointer select-none"
                      htmlFor={`associe-horizontal-radio-separation-de-bien-association-edit-${props.index}`}
                  >
                    Séparation de bien
                  </label>
                </div>
              </div>
            </div>


            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">
                Combien{" "}
                {props.type === "moi-meme"
                    ? "apportez-vous"
                    : "cet associé apporte"}{" "}
                à la société ? (Précisez la valeur du montant dans la monnaie
                locale du pays) ?
              </div>
              <input
                  type="number"
                  className="input w-full border flex-1 field"
                  placeholder="Combien apportez-vous à la société ?"
                  value={props.associes[props.index]["apport"] ?? ""}
                  onChange={(e) =>
                      handleChange(e, "apport", e.target.value, props.index)
                  }
              />
            </div>
          </div>
      ) : null}

      {props.associes[props.index]["type"] === "societe" ? (
          <>
            <div className={`grid grid-cols-12 gap-4 row-gap-5 col-span-12`}>
              <div className="intro-y col-span-12 sm:col-span-6">
                <div className="mb-2">Quel est le nom de la société ?</div>
                <input
                    type="text"
                    className="input w-full border flex-1 field"
                    placeholder="Quel est le nom de la société ?"
                    value={props.associes[props.index]["denomination"] ?? ""}
                    onChange={(e) =>
                        handleChange(e, "denomination", e.target.value, props.index)
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Quelle est sa forme sociale ?</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Quelle est sa forme sociale ?"
                value={props.associes[props.index]["forme-sociale"] ?? ""}
                onChange={(e) =>
                  handleChange(e, "forme-sociale", e.target.value, props.index)
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Montant du capital social</div>
              <input
                type="number"
                className="input w-full border flex-1 field"
                placeholder="Montant du capital social"
                value={props.associes[props.index]["capital-social"] ?? ""}
                onChange={(e) =>
                  handleChange(e, "capital-social", e.target.value, props.index)
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Adresse du siège social</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Adresse du siège social"
                value={props.associes[props.index]["siege-sociale"] ?? ""}
                onChange={(e) =>
                  handleChange(e, "siege-sociale", e.target.value, props.index)
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Numéro RCCM</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Numéro RCCM"
                value={props.associes[props.index]["numero-sociale"] ?? ""}
                onChange={(e) =>
                  handleChange(e, "numero-sociale", e.target.value, props.index)
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Ville d’immatriculation</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Ville d’immatriculation"
                value={
                  props.associes[props.index]["ville-immatriculation"] ?? ""
                }
                onChange={(e) =>
                  handleChange(
                    e,
                    "ville-immatriculation",
                    e.target.value,
                    props.index
                  )
                }
              />
            </div>
            <div className="col-span-12">
              Représentant Légal de la société associéé
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="my-2">Civilité</div>
              <select
                type="text"
                className="input w-full border flex-1 field"
                value={
                  props.associes[props.index]["civilite-representant"] ?? ""
                }
                onChange={(e) =>
                  handleChange(
                    e,
                    "civilite-representant",
                    e.target.value,
                    props.index
                  )
                }
              >
                <option value="monsieur">Monsieur</option>
                <option value="madame">Madame</option>
                <option value="mademoiselle">Mademoiselle</option>
              </select>
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Nom</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Nom"
                value={props.associes[props.index]["nom-representant"] ?? ""}
                onChange={(e) =>
                  handleChange(
                    e,
                    "nom-representant",
                    e.target.value,
                    props.index
                  )
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">Prénom(s)</div>
              <input
                type="text"
                className="input w-full border flex-1 field"
                placeholder="Prénom(s)"
                value={
                  props.associes[props.index]["prenoms-representant"] ?? ""
                }
                onChange={(e) =>
                  handleChange(
                    e,
                    "prenoms-representant",
                    e.target.value,
                    props.index
                  )
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6">
              <div className="mb-2">
                Combien cet associé apporte-t-il à la société (Précisez la
                valeur du montant dans la monnaie locale du pays) ?
              </div>
              <input
                type="number"
                className="input w-full border flex-1 field"
                placeholder="Combien apportez-vous à la société ?"
                value={props.associes[props.index]["apport"] ?? ""}
                onChange={(e) =>
                  handleChange(e, "apport", e.target.value, props.index)
                }
              />
            </div>
            <div className="intro-y col-span-12 sm:col-span-6"></div>
          </div>
        </>
      ) : null}
    </>
  );
}
